
// import React, { useState, Fragment } from "react";
// import {
//   Box,
//   Card,
//   Grid,
//   Drawer,
//   Button,
//   useTheme,
//   IconButton,
//   useMediaQuery
// } from "@mui/material";
// import { H5 } from "app/components/Typography";
// import { FlexBox } from "app/components/FlexBox";
// import Apps from "app/components/icons/Apps";
// import Breadcrumb from "app/components/Breadcrumb";

// // Ensure all local components are properly imported
// import BasicInformation from "./BasicInformation";
// // import DeviceDashboard from "./DeviceDashboard";
// import InstructorSetting from "./InstructorSetting";
// import Interests from "./Interests";
// import MembershipDetails from "./MembershipDetails";
// import Password from "./Password";
// import RecentDevices from "./RecentDevices";
// import DeleteAccount from "./DeleteAccount";

// // Styled button component
// import { styled } from "@mui/system";
// const StyledButton = styled(Button)(({ theme }) => ({
//   borderRadius: 0,
//   overflow: "hidden",
//   position: "relative",
//   whiteSpace: "nowrap",
//   textOverflow: "ellipsis",
//   padding: "0.6rem 1.5rem",
//   justifyContent: "flex-start",
//   color: theme.palette.text.primary
// }));

// const MemberInfo = () => {
//   const theme = useTheme();
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const [active, setActive] = useState("Basic Information");
//   const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

//   const style = {
//     color: theme.palette.primary.main,
//     backgroundColor: theme.palette.grey[100],
//     "&::before": {
//       left: 0,
//       width: 4,
//       content: '""',
//       height: "100%",
//       position: "absolute",
//       transition: "all 0.3s",
//       backgroundColor: theme.palette.primary.main
//     }
//   };

//   function TabListContent() {
//     return (

//       <FlexBox flexDirection="column">

//         {tabList.map(({ id, name, Icon }) => (
//           <StyledButton
//             key={id}
//             startIcon={Icon ? <Icon sx={{ color: "text.disabled" }} /> : null}
//             sx={active === name ? style : { "&:hover": style }}
//             onClick={() => {
//               setActive(name);
//               setOpenDrawer(false);
//             }}
//           >
//             {name}
//           </StyledButton>
//         ))}
//       </FlexBox>
//     );
//   }

//   return (
//     <Box p={4}>
//       <div className="breadcrumb">
//         <Breadcrumb routeSegments={[{ name: "Member Management" }]} />
//       </div>
//       <Grid container spacing={3}>
//         <Grid item md={3} xs={12}>
//           {downMd ? (
//             <Fragment>
//               <FlexBox alignItems="center" gap={1}>
//                 <IconButton sx={{ padding: 0 }} onClick={() => setOpenDrawer(true)}>
//                   <Apps sx={{ color: "primary.main" }} />
//                 </IconButton>
//                 <H5>Show More</H5>
//               </FlexBox>

//               <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
//                 <Box padding={1}>
//                   <TabListContent />
//                 </Box>
//               </Drawer>
//             </Fragment>
//           ) : (
//             <Card sx={{ padding: "1rem 0", marginTop: '25px' }}>
//               <TabListContent />
//             </Card>
//           )}
//         </Grid>



//         <Grid item md={9} xs={12}>
//           {active === "Basic Information" && <BasicInformation />}
//           {active === "Instructor Setting" && <InstructorSetting />}
//           {active === "Interests" && <Interests />}
//           {active === "Membership Details" && <MembershipDetails />}
//           {active === "Password" && <Password />}
//           {active === "Recent Devices" && <RecentDevices />}
//           {active === "Delete Account" && <DeleteAccount />}
//         </Grid>

//       </Grid>
//     </Box>
//   );
// };




// const tabList = [
//   { id: 1, name: "Basic Information", Icon: null },
//   { id: 3, name: "Instructor Setting", Icon: null },
//   { id: 4, name: "Interests", Icon: null },
//   { id: 5, name: "Membership Details", Icon: null },
//   { id: 6, name: "Password", Icon: null },
//   { id: 7, name: "Recent Devices", Icon: null },
//   { id: 8, name: "Delete Account", Icon: null },
// ];


// export default MemberInfo;















import React, { useState, Fragment } from "react";
import {
  Box,
  Card,
  Grid,
  Drawer,
  Button,
  useTheme,
  IconButton,
  useMediaQuery
} from "@mui/material";
import { H5 } from "app/components/Typography";
import { FlexBox } from "app/components/FlexBox";
import Apps from "app/components/icons/Apps";
import Breadcrumb from "app/components/Breadcrumb";

// Ensure all local components are properly imported
import BasicInformation from "./BasicInformation";
// import DeviceDashboard from "./DeviceDashboard";
import InstructorSetting from "./InstructorSetting";
import Interests from "./Interests";
import MembershipDetails from "./MembershipDetails";
import Password from "./Password";
import RecentDevices from "./RecentDevices";
import DeleteAccount from "./DeleteAccount";
import Volunteer from "./Volunteer";
import Campaignengagement from "./Campaignengagement";
// Styled button component
import { styled } from "@mui/system";
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  overflow: "hidden",
  position: "relative",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "0.6rem 1.5rem",
  justifyContent: "flex-start",
  color: theme.palette.text.primary
}));

const MemberInfo = () => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [active, setActive] = useState("Basic Information");
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const style = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
    "&::before": {
      left: 0,
      width: 4,
      content: '""',
      height: "100%",
      position: "absolute",
      transition: "all 0.3s",
      backgroundColor: theme.palette.primary.main
    }
  };

  function TabListContent() {
    return (

      <FlexBox flexDirection="column">

        {tabList.map(({ id, name, Icon }) => (
          <StyledButton
            key={id}
            startIcon={Icon ? <Icon sx={{ color: "text.disabled" }} /> : null}
            sx={active === name ? style : { "&:hover": style }}
            onClick={() => {
              setActive(name);
              setOpenDrawer(false);
            }}
          >
            {name}
          </StyledButton>
        ))}
      </FlexBox>
    );
  }

  return (
    <Box p={4}>
      <div className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Member Management" }]} />
      </div>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          {downMd ? (
            <Fragment>
              <FlexBox alignItems="center" gap={1}>
                <IconButton sx={{ padding: 0 }} onClick={() => setOpenDrawer(true)}>
                  <Apps sx={{ color: "primary.main" }} />
                </IconButton>
                <H5>Show More</H5>
              </FlexBox>

              <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Box padding={1}>
                  <TabListContent />
                </Box>
              </Drawer>
            </Fragment>
          ) : (
            <Card sx={{ padding: "1rem 0", marginTop: '25px' }}>
              <TabListContent />
            </Card>
          )}
        </Grid>



        <Grid item md={9} xs={12}>
          {active === "Basic Information" && <BasicInformation />}
          {active === "Instructor Setting" && <InstructorSetting />}
          {active === "Interests" && <Interests />}
          {active === "Member Details" && <MembershipDetails />}
          {active === "Password" && <Password />}
          {active === "Recent Devices" && <RecentDevices />}
          {active === "Delete Account" && <DeleteAccount />}
          {active === "Volunteer Setting" && <Volunteer />}
          {active === "Campaign Engagement" && <Campaignengagement />}

        </Grid>

      </Grid>
    </Box>
  );
};




const tabList = [
  { id: 1, name: "Basic Information", Icon: null },
  { id: 3, name: "Interests", Icon: null },
  { id: 4, name: "Member Details", Icon: null },
  { id: 5, name: "Password", Icon: null },
  { id: 6, name: "Instructor Setting", Icon: null },
  { id: 7, name: "Volunteer Setting", Icon: null },
  { id: 8, name: "Campaign Engagement", Icon: null },
  { id: 9, name: "Recent Devices", Icon: null },
  { id: 10, name: "Delete Account", Icon: null },
];


export default MemberInfo;
