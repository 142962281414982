
// import React, { useState } from "react";
// import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./membercss/MemberInfo.css";
// import axios from 'axios';
// function Password() {
//     const [inputValues, setInputValues] = useState({
//         input1: "",
//         input2: "",
//     });
//     const [error, setError] = useState(null);
//     const [success, setSuccess] = useState(null);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setInputValues({ ...inputValues, [name]: value });
//     };

//     const validatePassword = (password) => {
//         // Regex to check if password contains at least 1 lowercase, 1 uppercase, and 1 number
//         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

//         // Validate the password format
//         if (!passwordRegex.test(password)) {
//             return 'Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.';
//         }

//         return ''; // Return an empty string if validation passes
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault(); // Prevent default form submission behavior
//         setError(null);
//         setSuccess(null);

//         const { input1, input2 } = inputValues;

//         // Check if passwords match
//         if (input1 !== input2) {
//             setError("Passwords do not match");
//             return;
//         }

//         // Validate the password
//         const passwordError = validatePassword(input1);
//         if (passwordError) {
//             setError(passwordError);
//             return;
//         }

//         // Call the API to update the password
//         try {
//             const response = await axios.post("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-passwords", {
//                 newPassword: input1,
//                 confirmPassword: input2
//             });

//             // Check if the response is successful
//             if (response.status === 200) {
//                 setSuccess(response.data.message);
//                 setInputValues({ input1: "", input2: "" }); // Clear the form after successful submission
//             }
//         } catch (err) {
//             console.error("Error updating password:", err);
//             setError(err.response?.data?.error || "Failed to update password");
//         }
//     };





//     const handleCancel = () => {
//         setInputValues({ input1: "", input2: "" }); // Clear the input fields
//         setError(null); // Clear any error messages
//         setSuccess(null); // Clear any success messages
//     };

//     return (
//         <Container fluid>
//             <Row>
//                 <Col md={12}>
//                     <Card sx={{ border: '1px solid #d9d9d9', mt: 0 }}>
//                         <Card.Header style={{
//                             height: '80px',
//                             backgroundColor: 'white',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'left'
//                         }}>
//                             <h6 style={{ margin: 0 }}>Password</h6>
//                         </Card.Header>

//                         <Card.Body>
//                             <Form onSubmit={handleSubmit}>
//                                 <Row>
//                                     <Col md={6}>
//                                         <Form.Group controlId="formInput1" className="floating-label-group">
//                                             <Form.Control
//                                                 type="password"
//                                                 name="input1"
//                                                 value={inputValues.input1}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                                 required
//                                             />
//                                             <Form.Label className="floating-label">New Password</Form.Label>
//                                         </Form.Group>
//                                         <Form.Group controlId="formInput2" className="floating-label-group">
//                                             <Form.Control
//                                                 type="password"
//                                                 name="input2"
//                                                 value={inputValues.input2}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                                 required
//                                             />
//                                             <Form.Label className="floating-label">Confirm Password</Form.Label>
//                                         </Form.Group>


//                                         <Form.Group className="floating-label-group">
//                                             <Form.Check
//                                                 type="checkbox"
//                                                 label="User must create new password at next login"

//                                             />
//                                         </Form.Group>
//                                         {error && <div style={{ color: 'red' }}>{error}</div>}
//                                         {success && <div style={{ color: 'green' }}>{success}</div>}
//                                     </Col>

//                                     <Col md={6}>
//                                         <h6>Password Requirements:</h6>
//                                         <h6>Ensure that these requirements are met:</h6>
//                                         <ul style={{ listStyleType: "disc", paddingLeft: "20px", marginTop: '5px', color: 'black' }}>
//                                             <li style={{ fontSize: "16px" }}>Minimum 8 characters long</li>
//                                             <li style={{ fontSize: "16px" }}>At least one lowercase character</li>
//                                             <li style={{ fontSize: "16px" }}>At least one uppercase character</li>
//                                             <li style={{ fontSize: "16px" }}>At least one number</li>
//                                         </ul>
//                                     </Col>
//                                 </Row>

//                                 <Row className="mt-3">
//                                     <Col>
//                                         <Button variant="primary" className="me-2 instructorphoto" type="submit">
//                                             Save Changes
//                                         </Button>
//                                         <Button variant="outline-primary" className="canslebtn" onClick={handleCancel}>
//                                             Cancel
//                                         </Button>
//                                     </Col>
//                                 </Row>
//                             </Form>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }
// export default Password;






import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import axios from 'axios';

function Password() {
    const [inputValues, setInputValues] = useState({
        input1: "",
        input2: "",
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showPassword, setShowPassword] = useState({
        input1: false,
        input2: false,
    }); // State to track password visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

        if (!passwordRegex.test(password)) {
            return 'Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.';
        }

        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        const { input1, input2 } = inputValues;

        if (input1 !== input2) {
            setError("Passwords do not match");
            return;
        }

        const passwordError = validatePassword(input1);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        try {
            const response = await axios.post("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-passwords", {
                newPassword: input1,
                confirmPassword: input2
            });

            if (response.status === 200) {
                setSuccess(response.data.message);
                setInputValues({ input1: "", input2: "" });
            }
        } catch (err) {
            console.error("Error updating password:", err);
            setError(err.response?.data?.error || "Failed to update password");
        }
    };

    const handleCancel = () => {
        setInputValues({ input1: "", input2: "" });
        setError(null);
        setSuccess(null);
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card sx={{ border: '1px solid #d9d9d9', mt: 0 }}>
                        <Card.Header style={{
                            height: '80px',
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}>
                            <h6 style={{ margin: 0 }}>Password</h6>
                        </Card.Header>

                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formInput1" className="floating-label-group position-relative">
                                            <Form.Control
                                                type={showPassword.input1 ? "text" : "password"} // Toggle input type
                                                name="input1"
                                                value={inputValues.input1}
                                                onChange={handleChange}
                                                className="floating-input"
                                                required
                                            />
                                            <Form.Label className="floating-label">New Password</Form.Label>
                                            <span
                                                onClick={() => togglePasswordVisibility('input1')}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    cursor: "pointer",
                                                    color: "#6c757d",
                                                }}
                                            >
                                                {showPassword.input1 ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </Form.Group>

                                        <Form.Group controlId="formInput2" className="floating-label-group position-relative">
                                            <Form.Control
                                                type={showPassword.input2 ? "text" : "password"} // Toggle input type
                                                name="input2"
                                                value={inputValues.input2}
                                                onChange={handleChange}
                                                className="floating-input"
                                                required
                                            />
                                            <Form.Label className="floating-label">Confirm Password</Form.Label>
                                            <span
                                                onClick={() => togglePasswordVisibility('input2')}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    cursor: "pointer",
                                                    color: "#6c757d",
                                                }}
                                            >
                                                {showPassword.input2 ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </Form.Group>

                                        <Form.Group className="floating-label-group">
                                            <Form.Check
                                                type="checkbox"
                                                label="User must create new password at next login"
                                            />
                                        </Form.Group>
                                        {error && <div style={{ color: 'red' }}>{error}</div>}
                                        {success && <div style={{ color: 'green' }}>{success}</div>}
                                    </Col>

                                    <Col md={6}>
                                        <h6>Password Requirements:</h6>
                                        <h6>Ensure that these requirements are met:</h6>
                                        <ul style={{ listStyleType: "disc", paddingLeft: "20px", marginTop: '5px', color: 'black' }}>
                                            <li style={{ fontSize: "16px" }}>Minimum 8 characters long</li>
                                            <li style={{ fontSize: "16px" }}>At least one lowercase character</li>
                                            <li style={{ fontSize: "16px" }}>At least one uppercase character</li>
                                            <li style={{ fontSize: "16px" }}>At least one number</li>
                                        </ul>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <Button variant="primary" className="me-2 instructorphoto" type="submit">
                                            Save Changes
                                        </Button>
                                        <Button variant="outline-primary" className="canslebtn" onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Password;
