import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Row, Col, Alert } from "react-bootstrap";
import './categoriescss/UserCategories.css';
import { Campaign } from "@mui/icons-material";
import axios from 'axios';
import { TablePagination } from "@mui/material";
import Breadcrumb from "app/components/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const UserCategories = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [successMessage, setSuccessMessage] = useState("");
    const [venuesuccessMessage, setVenueSuccessMessage] = useState("");
    const [audsuccessMessage, setAudSuccessMessage] = useState("");
    // Pagination logic


    const [inputValues, setInputValues] = useState({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
    });

    // Event handler to update selected option
    const handleSelectionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    // Function to toggle the modal
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => {
        setShowModal(false);
        resetForm();
    };

    // Handle input change for all input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };



    // Reset form fields
    const resetForm = () => {
        setInputValues({
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            input6: "",
            input7: "",
            input8: "",
            input9: "",
            input10: "",
            input11: "",
        });
    };

    //---------------------------------------------------------------------------------------------------------\\

    // Handle form submit post apis

    const handleFormSubmit = async () => {
        let endpoint = "";
        let payload = {};

        switch (selectedOption) {
            case "campaignType":
                endpoint = "https://membershipuat.maxisense.io/webapp/memportal/addcampaigntype";
                payload = {
                    campaign_type_code: inputValues.input1,
                    campaign_type_name: {
                        en: inputValues.input3,
                        zh: inputValues.input2,
                    },
                };
                break;
            case "campaignVenue":
                endpoint = "https://membershipuat.maxisense.io/webapp/memportal/addvenue";
                payload = {
                    in_centre: inputValues.input4,
                    venue_name: {
                        en: inputValues.input6,
                        zh: inputValues.input5,
                    },
                };
                break;

            case "campaignAudience":
                endpoint = "https://membershipuat.maxisense.io/webapp/memportal/addaudience";
                payload = {
                    audience_code: inputValues.input11,
                    audience_name: {
                        en: inputValues.input10,
                        zh: inputValues.input9,
                    },

                };
                break;
            default:
                alert("Please select a valid option");
                return;
        }

        try {
            // Add Authorization token if required
            const token = localStorage.getItem("authToken");

            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Token: token, // Include the token from Postman headers
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const result = await response.json();
                if (result.code === 1) {
                    alert("Data added successfully!");
                    resetForm();
                    handleModalClose();
                } else {
                    alert(result.message_en || "Failed to add data.");
                }
            } else {
                alert("Failed to add data. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred while submitting the form.");
        }
    };




    //---------------------------------------------------------------------------------------------------------\\




    const [data, setData] = useState([]);



    const totalPagestype = Math.ceil(data.length / itemsPerPage);

    // Pagination logic
    const paginatedTypes = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Sort data alphabetically by campaign_type_code
    const sortedData = [...data].sort((a, b) => a.campaign_type_code.localeCompare(b.campaign_type_code));

    const fetchCampaignTypes = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getcampaigntypelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            console.log("Fetched campaign type:", response.data);
            if (response.data.code === 1) {
                const campaignTypes = response.data.data || [];
                // Sort campaign types alphabetically based on campaign_type_code
                campaignTypes.sort((a, b) => {
                    return a.campaign_type_code.localeCompare(b.campaign_type_code);
                });
                setData(campaignTypes);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign types:", error);
        }
    };

    // Fetch campaign types initially and refresh every 3 seconds
    useEffect(() => {
        fetchCampaignTypes();
        const intervalId = setInterval(fetchCampaignTypes, 3000);
        return () => clearInterval(intervalId);
    }, []);






    const [venuedata, setvenuedataData] = useState([]);


    const totalPages = Math.ceil(venuedata.length / itemsPerPage);
    const paginatedVenues = venuedata.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);




    const fetchCampaignVenues = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getvenuelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            // Log the raw response
            console.log("Raw response:", response.data);

            if (response.data.code === 1) {
                // Directly access response.data.data which is an array
                const venuesData = response.data.data || [];

                // Log parsed data
                console.log("Parsed venues data:", venuesData);

                setvenuedataData(venuesData);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign venues:", error);
        }
    };




    useEffect(() => {
        fetchCampaignVenues();
        const intervalId = setInterval(fetchCampaignVenues, 3000);
        return () => clearInterval(intervalId);
    }, []);



    const [audiencedata, setAudienceData] = useState([]);

    const totalPageAudience = Math.ceil(audiencedata.length / itemsPerPage);
    const paginatedAudience = audiencedata.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );




    const fetchCampaignAudiences = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/gettargetaudiencelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                }
            });

            console.log("Fetched campaign audiences:", response.data);


            if (response.data.code === 1) {
                const audienceData = response.data.data || [];
                setAudienceData(audienceData);
            } else {
                // console.error("API responded with an error:", response.data.message_en);
            }

        } catch (error) {
            console.error("Error fetching campaign audiences:", error);
        }
    };


    useEffect(() => {
        fetchCampaignAudiences();
        const intervalId = setInterval(fetchCampaignAudiences, 3000);
        return () => clearInterval(intervalId);
    }
    )




    //--------------------------------------------------------------------------------------------------------------------------------

    // Delete apis.-----------------------------------------



    const handleDeleteType = async (item) => {

        const confirmed = window.confirm(`Are you sure you want to delete Campaign Type ${item.campaign_type_code}?`);
        if (!confirmed) {
            return;
        }
        try {
            const token = localStorage.getItem("authToken");

            const response = await axios.post(
                "https://membershipuat.maxisense.io/webapp/memportal/deletecampaigntype",
                {
                    campaign_type_id: item.campaign_type_id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Token: token,
                    }
                }
            );

            if (response.data.code === 1) {
                setSuccessMessage(
                    `Campaign Type :- ${item.campaign_type_code} deleted successfully!`
                );

                setTimeout(() => setSuccessMessage(""), 2000);
                fetchCampaignTypes();
                // toast.success("Campaign Type deleted successfully!");
            } else {
                console.error("Failed to delete campaign type:", response.data.message_en);
                alert("Failed to delete campaign type. Please try again.");
            }
        } catch (error) {
            console.error("Error deleting campaign type:", error);
            toast.error("An error occurred while deleting the campaign type. Please try again.");
        }
    };

 







    const handleDeleteVenue = async (item) => {
        const confirmed = window.confirm(`Are you sure you want to delete Venue ${item.venue_name.zh}?`);
        if (!confirmed) {
            return;
        }
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.post(
                "https://membershipuat.maxisense.io/webapp/memportal/deletevenue",
                {
                    venue_id: item.venue_id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Token: token,
                    }
                }
            );
            console.log("[DELETE_VENUE]: Venue deleted successfully");
            if (response.data.code === 1) {
                setVenueSuccessMessage(
                    `Campaign Venue :- ${item.venue_name.en}  deleted successfully!`
                );

                setTimeout(() => setVenueSuccessMessage(""), 2000);
                fetchCampaignVenues();
            } else {
                alert(`Failed to delete venue: ${response.data.message_en || "Unknown error"}`);
            }
        } catch (error) {
            console.error("Error deleting venue:", error);
            alert("An error occurred while deleting the venue. Please try again.");
        }
    };


    const handleDeleteAudience = async (item) => {
        // Show confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to delete Audience ${item.audience_name.zh}?`);
        if (!confirmed) {
            return; // If the user cancels, exit the function
        }

        try {
            const token = localStorage.getItem("authToken"); // Retrieve the token from localStorage

            // Send POST request to delete the audience
            const response = await axios.post(
                "https://membershipuat.maxisense.io/webapp/memportal/deleteaudience",
                {
                    audience_id: item.audience_id, // Send audience_id from the item
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Token": token, // Add Token header for authentication
                    }
                }
            );

            console.log("[DELETE_AUDIENCE]: Audience deleted successfully");

            // Check if the response is successful
            if (response.data.code === 1) {
                setAudSuccessMessage(
                    `Member Identity :- ${item.audience_name.en}  deleted successfully!`
                );

                setTimeout(() => setAudSuccessMessage(""), 2000);
                fetchCampaignAudiences(); // Refresh the audience data
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }

        } catch (error) {
            console.error("Error deleting audience:", error);
        }
    };



    //------------------------------------------------------Edit Apis.............................................................







    // Render modal content dynamically based on the selected option
    const renderModalContent = () => {
        switch (selectedOption) {
            case "campaignType":
                return (
                    <>
                        <p className="usercategoriesformhead">Add New Campaign Type</p>
                        <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Control
                                type="text"
                                name="input1"
                                value={inputValues.input1}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Type</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput2" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input2"
                                value={inputValues.input2}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Description (Chinese)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput3" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input3"
                                value={inputValues.input3}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Description (English)</Form.Label>
                        </Form.Group>
                    </>
                );
            case "campaignVenue":
                return (
                    <>
                        <p className="usercategoriesformhead">Edit Campaign Venue</p>

                        <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Select
                                name="input4"
                                value={inputValues.input4}
                                onChange={handleChange}
                                className="floating-input"
                            >
                                <option value=""></option>
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                            </Form.Select>
                            <Form.Label className="floating-label">In-Centre (YTNEC)</Form.Label>
                        </Form.Group>

                        <Form.Group controlId="formInput2" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input5"
                                value={inputValues.input5}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Venue (Chinese)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput3" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input6"
                                value={inputValues.input6}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Venue (English)</Form.Label>
                        </Form.Group>
                    </>
                );
            case "campaignAudience":
                return (
                    <>
                        <p className="usercategoriesformhead">Add New </p>
                        <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Control
                                type="text"
                                name="input9"
                                value={inputValues.input9}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Identity (Chinese)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput2" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input10"
                                value={inputValues.input10}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Identity (English)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput3" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input11"
                                value={inputValues.input11}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Code</Form.Label>
                        </Form.Group>
                    </>
                );
            default:
                return <p>Please select a campaign management option.</p>;
        }
    };

    return (
        <div className="container-fluid mt-4 pt-3 ps-4">

            <div className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "User-Defined Categories" }]} />
            </div>
            <Row>
                <Col md={9}>
                    <div className="mb-4">
                        <p className="usercategorieshead">User-Defined Categories</p>
                        <Form.Group controlId="campaignManagementForm" className="d-flex align-items-center">
                            <Form.Label className="me-2 userlabel">Search:</Form.Label>
                            <Form.Select
                                value={selectedOption}
                                onChange={handleSelectionChange}
                                className="custom-select me-2"
                            >
                                <option value=""></option>
                                <option value="campaignType">Campaign Type Management</option>
                                <option value="campaignVenue">Campaign Venue Management</option>
                                <option value="campaignAudience">Member Identity</option>
                                {/* <option value="campaignName">Campaign Name Management</option> */}
                            </Form.Select>
                            <Button onClick={handleModalShow} className="useraddbtn ">
                                Add
                            </Button>
                        </Form.Group>
                    </div>
                </Col>
            </Row>

            {successMessage && (
                <Alert
                    variant="success"
                    onClose={() => setSuccessMessage("")}
                    dismissible
                >
                    {successMessage}
                </Alert>
            )}

            <Row className="mt-4">
                {selectedOption === "campaignType" && (
                    <Col md={9}>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th className="custom-th">Type</th>
                                        <th className="custom-th">Description(Chinese)</th>
                                        <th className="custom-th">Description(English)</th>
                                        <th className="custom-th text-end ">Function(s)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                No data available
                                            </td>
                                        </tr>
                                    ) : (
                                        paginatedTypes.map((item, index) => (
                                            <tr key={index}>
                                                <td className="custom-td">{item.campaign_type_code}</td>
                                                <td className="custom-td">{item.campaign_type_name.zh}</td>
                                                <td className="custom-td">{item.campaign_type_name.en}</td>
                                                <td className="custom-td text-end">
                                                    <Button className="me-2 useraddbtnbtn" >Edit</Button>
                                                    <Button className="userdelete" onClick={() => handleDeleteType(item)}>Delete</Button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>


                        </div>


                        <nav aria-label="Page navigation example" className="mt-5">
                            <ul className="pagination justify-content-end">
                                {/* Previous Button */}
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className={`page-link ${currentPage !== 1 ? 'text-success' : ''}`}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                </li>

                                {/* Page Numbers */}
                                {Array.from({ length: totalPagestype }, (_, i) => i + 1).map((page) => (
                                    <li
                                        key={page}
                                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                                    >
                                        <button
                                            className={`page-link ${page === currentPage ? 'bg-success text-white' : 'text-success'}`}
                                            onClick={() => handlePageChange(page)}
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${currentPage === totalPagestype ? 'disabled' : ''}`}>
                                    <button
                                        className={`page-link ${currentPage !== totalPagestype ? 'text-success' : ''}`}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPagestype}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>


                    </Col>
                )}



                {venuesuccessMessage && (
                    <Alert
                        variant="success"
                        onClose={() => setVenueSuccessMessage("")}
                        dismissible
                    >
                        {venuesuccessMessage}
                    </Alert>
                )}

                {selectedOption === "campaignVenue" && (
                    <Col md={9}>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th className="custom-th">In-Centre (YTNEC)</th>
                                        <th className="custom-th">Venue (Chinese)</th>
                                        <th className="custom-th">Venue (English)</th>
                                        <th className="custom-th text-end">Function(s)</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {venuedata.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                No venues available
                                            </td>
                                        </tr>
                                    ) : (
                                        paginatedVenues.map((item, index) => (
                                            <tr key={index}>
                                                <td className="custom-td">{item.in_centre}</td>
                                                <td className="custom-td">{item.venue_name.zh}</td>
                                                <td className="custom-td">{item.venue_name.en}</td>
                                                <td className="custom-td text-end">
                                                    <Button className="me-2 useraddbtnbtn">Edit</Button>
                                                    <Button
                                                        className="userdelete"
                                                        onClick={() => handleDeleteVenue(item)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>

                            </Table>
                        </div>


                        <nav aria-label="Page navigation example" className="mt-5">
                            <ul className="pagination justify-content-end">
                                {/* Previous Button */}
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className={`page-link ${currentPage !== 1 ? 'text-success' : ''}`}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                </li>

                                {/* Page Numbers */}
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                    <li
                                        key={page}
                                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                                    >
                                        <button
                                            className={`page-link ${page === currentPage ? 'bg-success text-white' : 'text-success'}`}
                                            onClick={() => handlePageChange(page)}
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button
                                        className={`page-link ${currentPage !== totalPages ? 'text-success' : ''}`}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>

                    </Col>
                )}



                {audsuccessMessage && (
                    <Alert
                        variant="success"
                        onClose={() => setAudSuccessMessage("")}
                        dismissible
                    >
                        {audsuccessMessage}
                    </Alert>
                )}


                {selectedOption === "campaignAudience" && (
                    <Col md={9}>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th className="custom-th">Identity (Chinese)</th>
                                        <th className="custom-th">Identity (English)</th>
                                        <th className="custom-th">Code</th>
                                        <th className="custom-th text-end">Function(s)</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {audiencedata.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                No venues available
                                            </td>
                                        </tr>
                                    ) : (
                                        paginatedAudience.map((item, index) => (
                                            <tr key={index}>
                                                <td className="custom-td">{item.audience_name.zh}</td>
                                                <td className="custom-td">{item.audience_name.en}</td>
                                                <td className="custom-td">{item.audience_code}</td>
                                                <td className="custom-tdtd text-end ">
                                                    <Button className="me-2 useraddbtnbtn">Edit</Button>
                                                    <Button className="userdelete" onClick={() => handleDeleteAudience(item)}>Delete</Button>

                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>



                            </Table>
                        </div>

                        <nav aria-label="Page navigation example" className="mt-5">
                            <ul className="pagination justify-content-end">
                                {/* Previous Button */}
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className={`page-link ${currentPage !== 1 ? 'text-success' : ''}`}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                </li>

                                {/* Page Numbers */}
                                {Array.from({ length: totalPageAudience }, (_, i) => i + 1).map((page) => (
                                    <li
                                        key={page}
                                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                                    >
                                        <button
                                            className={`page-link ${page === currentPage ? 'bg-success text-white' : 'text-success'}`}
                                            onClick={() => handlePageChange(page)}
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${currentPage === totalPageAudience ? 'disabled' : ''}`}>
                                    <button
                                        className={`page-link ${currentPage !== totalPageAudience ? 'text-success' : ''}`}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPageAudience}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>


                    </Col>
                )}
            </Row>


            <ToastContainer />

            {/* Modal for Adding New Item */}
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Body>
                    <Form>{renderModalContent()}</Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose} className="userformcansle">
                        Cancel
                    </Button>
                    <Button onClick={handleFormSubmit} className="userformsave">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserCategories;
