// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Card, Button, Form, Collapse, CardBody, Modal } from 'react-bootstrap';
// import { FaMinus } from 'react-icons/fa'; // Import Minus icon from react-icons
// import './campaigncss/Lesson.css';
// import { IoNotificationsOutline } from 'react-icons/io5';
// import { Add } from '@mui/icons-material';
// import axios from 'axios';
// function Lesson() {
//     const [showGreenBox, setShowGreenBox] = useState(false); // State to control green box visibility
//     const [showForm, setShowForm] = useState(false);         // State to control form visibility
//     const [showBasicModal, setShowBasicModal] = useState(false);
//     const handleshowBasicModal = () => setShowBasicModal(true);
//     const handlecloseBasicModal = () => setShowBasicModal(false);
//     const [venuedata, setvenuedataData] = useState([]);
//     const fetchCampaignVenues = async () => {
//         try {
//             const token = localStorage.getItem("authToken");
//             const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getvenuelist", {
//                 headers: {
//                     "Content-Type": "application/json",
//                     Token: token,
//                 },
//             });

//             // Log the raw response
//             console.log("Raw response:", response.data);

//             if (response.data.code === 1) {
//                 // Directly access response.data.data which is an array
//                 const venuesData = response.data.data || [];

//                 // Log parsed data
//                 console.log("Parsed venues data:", venuesData);

//                 setvenuedataData(venuesData);
//             } else {
//                 console.error("API responded with an error:", response.data.message_en);
//             }
//         } catch (error) {
//             console.error("Error fetching campaign venues:", error);
//         }
//     };
//     useEffect(() => {
//         fetchCampaignVenues();
//         const intervalId = setInterval(fetchCampaignVenues, 3000);
//         return () => clearInterval(intervalId);
//     }, []);


//     const [status, setStatus] = useState("Normal"); // Status state to control Reason field visibility

//     // Handle the change in the status dropdown
//     const handleStatusChange = (event) => {
//         setStatus(event.target.value);
//     };
//     return (
//         <Container fluid>
//             <Row>
//                 <Card className="mt-0 border-0" style={{ position: "relative" }}>

//                     <Row className="align-items-center mb-3">
//                         <Col md={6} className="d-flex align-items-center">
//                             <h5 className="basicheading">Lesson(s) Details</h5>
//                         </Col>
//                         <Col md={6} className="text-end">
//                             <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
//                             <Button variant="primary" className="basicsavebtn me-3">Save</Button>
//                         </Col>
//                     </Row>

//                     <Row>
//                         <Col md={12}>
//                             <Card className="basicinfocard">
//                                 <CardBody className="d-flex justify-content-between align-items-center">
//                                     <div className="d-flex align-items-center">
//                                         <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
//                                         <span className="basicstatus">Status: Draft</span>
//                                     </div>
//                                     <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>

//                     <Row className="mt-3">
//                         <Col md={1}>
//                             <Button
//                                 style={{
//                                     backgroundColor: '#1976D2',
//                                     border: 'none',
//                                     height: '24px',
//                                     width: '24px',
//                                     padding: '0'
//                                 }}
//                                 onClick={() => setShowGreenBox(true)}
//                             >
//                                 <Add style={{ color: '#fff', fontSize: '16px' }} />
//                             </Button>
//                         </Col>
//                     </Row>

//                     <Card.Body>

//                         {showGreenBox && (
//                             <div
//                                 className="p-3 border border-success rounded d-flex justify-content-between align-items-center mb-3 lessoncard"
//                                 style={{ cursor: 'pointer' }}
//                                 onClick={() => setShowForm(!showForm)} 
//                             >
//                                 <h5 className="mb-0 cardtext">Date :</h5>
//                                 <FaMinus style={{ color: 'white' }} />
//                             </div>
//                         )}

//                         <Collapse in={showForm}>
//                             <div className="p-3">
//                                 <Form>
//                                     <Row>
//                                         <Col md={6}>
//                                             <Form.Group controlId="introductionChinese" className="floating-label-group">
//                                                 <Form.Control
//                                                     type="text"
//                                                     name="introductionChinese"
//                                                     className="floating-input"
//                                                 />
//                                                 <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6}>
//                                             <Form.Group controlId="introductionChinese" className="floating-label-group">
//                                                 <Form.Control
//                                                     type="text"
//                                                     name="introductionChinese"
//                                                     className="floating-input"
//                                                 />
//                                                 <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6}>
//                                             {/* Campaign Name Dropdown (Chinese) */}
//                                             <Form.Group controlId="campaignNameChinese" className="floating-label-group">
//                                                 <Form.Select name="campaignName" className="floating-input">
//                                                     {venuedata.map((item) => (
//                                                         <option key={item.venue_name.zh}>
//                                                             {item.venue_name.zh}
//                                                         </option>
//                                                     ))}
//                                                 </Form.Select>
//                                                 <Form.Label className="floating-label">Venue(Chinese)</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6}>
//                                             {/* Campaign Name Dropdown (English) */}
//                                             <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
//                                                 <Form.Select name="campaignName" className="floating-input">
//                                                     {venuedata.map((item) => (
//                                                         <option key={item.venue_name.en}>
//                                                             {item.venue_name.en}
//                                                         </option>
//                                                     ))}
//                                                 </Form.Select>
//                                                 <Form.Label className="floating-label">Venue(English)</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         {/* Additional Form Fields */}
//                                         <Col md={2}>
//                                             <Form.Group controlId="code" className="floating-label-group">
//                                                 <Form.Control
//                                                     type="date"
//                                                     name="Date"
//                                                     className="floating-input"
//                                                 />
//                                                 <Form.Label className="floating-label">Date</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={2}>
//                                             <Form.Group controlId="code" className="floating-label-group">
//                                                 <Form.Control
//                                                     type="time"
//                                                     name="start-time"
//                                                     className="floating-input"
//                                                 />
//                                                 <Form.Label className="floating-label">Start Time</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={2}>
//                                             <Form.Group controlId="code" className="floating-label-group">
//                                                 <Form.Control
//                                                     type="time"
//                                                     name="end-time"
//                                                     className="floating-input"
//                                                 />
//                                                 <Form.Label className="floating-label">End Time</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={3}>
//                                             <Form.Group controlId="status" className="floating-label-group">
//                                                 <Form.Select
//                                                     name="status"
//                                                     className="floating-input"
//                                                     value={status}
//                                                     onChange={handleStatusChange} // Update status when it changes
//                                                 >
//                                                     <option value="Normal">Normal</option>
//                                                     <option value="Delayed">Delayed</option>
//                                                     <option value="Cancelled">Cancelled</option>
//                                                 </Form.Select>
//                                                 <Form.Label className="floating-label">Status</Form.Label>
//                                             </Form.Group>
//                                         </Col>

//                                         {/* Reason Field: Only show when status is "Delayed" or "Cancelled" */}
//                                         {(status === "Delayed" || status === "Cancelled") && (
//                                             <Col md={3}>
//                                                 <Form.Group controlId="introductionChinese" className="floating-label-group">
//                                                     <Form.Select name="Reason" className="floating-input">
//                                                         <option value="Bad Weather">Bad Weather</option>
//                                                         <option value="Instructor Sick">Instructor Sick</option>
//                                                     </Form.Select>
//                                                     <Form.Label className="floating-label">Reason</Form.Label>
//                                                 </Form.Group>
//                                             </Col>
//                                         )}
//                                     </Row>
//                                 </Form>
//                             </div>
//                         </Collapse>

//                     </Card.Body>
//                 </Card>
//             </Row>

//             <Modal
//                 show={showBasicModal}
//                 onHide={handlecloseBasicModal}
//                 centered
//                 className="custom-modal-width"
//             >
//                 <Modal.Body>
//                     <Form>
//                         <h4 className="assigntohead ms-2">
//                             Assign to
//                         </h4>

//                         <Form.Group controlId="formStatus" className="mt-3 ms-3">

//                             <Form.Check
//                                 type="checkbox"
//                                 label="Ling (Manager)"
//                                 name="status"
//                                 value="pre-approve"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Rainbow Lee (Campaign Organizer)"
//                                 name="status"
//                                 value="approve"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Alex Law (Manager)"
//                                 name="status"
//                                 value="waitlisted"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Chan Siu Ming (Manager)"
//                                 name="status"
//                                 value="waitlisted"
//                                 className="assigntocheck"
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     <Button
//                         variant=""
//                         onClick={handlecloseBasicModal}
//                         className="me-2 groupclear"
//                     >
//                         Cancel
//                     </Button>
//                     <Button
//                         variant=""
//                         className="groupsearch"
//                         onClick={handlecloseBasicModal}
//                     >
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </Container>
//     );
// }

// export default Lesson;


//--------------------------------------------------------------------------------------------------------------


import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Collapse, CardBody, Modal } from 'react-bootstrap';
import { FaMinus } from 'react-icons/fa'; // Import Minus icon from react-icons
import './campaigncss/Lesson.css';
import { IoNotificationsOutline } from 'react-icons/io5';
import { Add } from '@mui/icons-material';
import axios from 'axios';

function Lesson() {
    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);
    const [venuedata, setvenuedataData] = useState([]);
    const fetchCampaignVenues = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getvenuelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            if (response.data.code === 1) {
                setvenuedataData(response.data.data || []);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign venues:", error);
        }
    };
    useEffect(() => {
        fetchCampaignVenues();
        const intervalId = setInterval(fetchCampaignVenues, 3000);
        return () => clearInterval(intervalId);
    }, []);

    const [status, setStatus] = useState("Normal");
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    // State to hold an array of collapses, each corresponds to a form
    const [collapses, setCollapses] = useState([]);

    const handleAddCollapse = () => {
        setCollapses([...collapses, false]); // Add a new collapse entry (default to closed)
    };

    const toggleCollapse = (index) => {
        const newCollapses = [...collapses];
        newCollapses[index] = !newCollapses[index]; // Toggle the clicked collapse
        setCollapses(newCollapses);
    };

    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Row className="align-items-center mb-3">
                        <Col md={6} className="d-flex align-items-center">
                            <h5 className="basicheading">Lesson(s) Details</h5>
                        </Col>
                        <Col md={6} className="text-end">
                            <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
                            <Button variant="primary" className="basicsavebtn me-3">Save</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card className="basicinfocard">
                                <CardBody className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                        <span className="basicstatus">Status: Draft</span>
                                    </div>
                                    <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md={1}>
                            <Button
                                style={{
                                    backgroundColor: '#1976D2',
                                    border: 'none',
                                    height: '24px',
                                    width: '24px',
                                    padding: '0'
                                }}
                                onClick={handleAddCollapse} // Add new collapse when clicked
                            >
                                <Add style={{ color: '#fff', fontSize: '16px' }} />
                            </Button>
                        </Col>
                    </Row>

                    <Card.Body>
                        {/* Map over collapses to generate multiple collapses */}
                        {collapses.map((isOpen, index) => (
                            <div key={index}>
                                <div
                                    className="p-3 border border-success rounded d-flex justify-content-between align-items-center mb-3 lessoncard"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => toggleCollapse(index)} // Toggle collapse on click
                                >
                                    <h5 className="mb-0 cardtext">Date :</h5>
                                    <FaMinus style={{ color: 'white' }} />
                                </div>

                                <Collapse in={isOpen}>
                                    <div className="p-3">
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="introductionChinese" className="floating-label-group">
                                                        <Form.Control
                                                            type="text"
                                                            name="introductionChinese"
                                                            className="floating-input"
                                                        />
                                                        <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="introductionChinese" className="floating-label-group">
                                                        <Form.Control
                                                            type="text"
                                                            name="introductionChinese"
                                                            className="floating-input"
                                                        />
                                                        <Form.Label className="floating-label">Introduction (English)</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="campaignNameChinese" className="floating-label-group">
                                                        <Form.Select name="campaignName" className="floating-input">
                                                            {venuedata.map((item) => (
                                                                <option key={item.venue_name.zh}>
                                                                    {item.venue_name.zh}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Label className="floating-label">Venue(Chinese)</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
                                                        <Form.Select name="campaignName" className="floating-input">
                                                            {venuedata.map((item) => (
                                                                <option key={item.venue_name.en}>
                                                                    {item.venue_name.en}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Label className="floating-label">Venue(English)</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                {/* Additional Form Fields */}
                                                <Col md={2}>
                                                    <Form.Group controlId="code" className="floating-label-group">
                                                        <Form.Control
                                                            type="date"
                                                            name="Date"
                                                            className="floating-input"
                                                        />
                                                        <Form.Label className="floating-label">Date</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={2}>
                                                    <Form.Group controlId="code" className="floating-label-group">
                                                        <Form.Control
                                                            type="time"
                                                            name="start-time"
                                                            className="floating-input"
                                                        />
                                                        <Form.Label className="floating-label">Start Time</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={2}>
                                                    <Form.Group controlId="code" className="floating-label-group">
                                                        <Form.Control
                                                            type="time"
                                                            name="end-time"
                                                            className="floating-input"
                                                        />
                                                        <Form.Label className="floating-label">End Time</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Group controlId="status" className="floating-label-group">
                                                        <Form.Select
                                                            name="status"
                                                            className="floating-input"
                                                            value={status}
                                                            onChange={handleStatusChange}
                                                        >
                                                            <option value="Normal">Normal</option>
                                                            <option value="Delayed">Delayed</option>
                                                            <option value="Cancelled">Cancelled</option>
                                                        </Form.Select>
                                                        <Form.Label className="floating-label">Status</Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                {(status === "Delayed" || status === "Cancelled") && (
                                                    <Col md={3}>
                                                        <Form.Group controlId="introductionChinese" className="floating-label-group">
                                                            <Form.Select name="Reason" className="floating-input">
                                                                <option value="Bad Weather">Bad Weather</option>
                                                                <option value="Instructor Sick">Instructor Sick</option>
                                                            </Form.Select>
                                                            <Form.Label className="floating-label">Reason</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Form>
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    </Card.Body>
                </Card>
            </Row>

            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">Assign to</h4>
                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Lesson;
