import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Row, Col,Alert } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CampaignType = () => {
    const [data, setData] = useState([]);
    const [selectedOption, setSelectedOption] = useState("campaignType");
    const [inputValues, setInputValues] = useState({
        input1: "",
        input2: "",
        input3: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [successMessage, setSuccessMessage] = useState("");
    const fetchCampaignTypes = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getcampaigntypelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });
            if (response.data.code === 1) {
                setData(response.data.data || []);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign types:", error);
        }
    };

    useEffect(() => {
        fetchCampaignTypes();
        const intervalId = setInterval(fetchCampaignTypes, 3000);
        return () => clearInterval(intervalId);
    }, []);

    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => {
        setShowModal(false);
        resetForm();
    };

    const resetForm = () => {
        setInputValues({
            input1: "",
            input2: "",
            input3: "",
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const handleFormSubmit = async () => {
        const endpoint = "https://membershipuat.maxisense.io/webapp/memportal/addcampaigntype";
        const payload = {
            campaign_type_code: inputValues.input1,
            campaign_type_name: {
                en: inputValues.input3,
                zh: inputValues.input2,
            },
        };

        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.post(endpoint, payload, {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            if (response.data.code === 1) {
                toast.success("Data added successfully!");
                fetchCampaignTypes();
                handleModalClose();
            } else {
                toast.error(response.data.message_en || "Failed to add data.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("An error occurred while submitting the form.");
        }
    };

    const handleDeleteType = async (item) => {
        const confirmed = window.confirm(`Are you sure you want to delete Campaign Type ${item.campaign_type_code}?`);
        if (!confirmed) return;

        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.post(
                "https://membershipuat.maxisense.io/webapp/memportal/deletecampaigntype",
                { campaign_type_id: item.campaign_type_id },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Token: token,
                    }
                }
            );

            if (response.data.code === 1) {
                // alert("Campaign Type deleted successfully!");
                fetchCampaignTypes();
                setSuccessMessage(
                    "Campaign Type deleted successfully!"
                );

                setTimeout(() => setSuccessMessage(""), 2000);
            } else {
                toast.error("Failed to delete campaign type. Please try again.");
            }
        } catch (error) {
            console.error("Error deleting campaign type:", error);
            toast.error("An error occurred while deleting the campaign type.");
        }
    };

    const totalPagestype = Math.ceil(data.length / itemsPerPage);
    const paginatedTypes = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div>
            <h3>Campaign Types</h3>
            <Button onClick={handleModalShow} className="mb-3">
                Add New Campaign Type
            </Button>


            {successMessage && (
                <Alert
                    variant="success"
                    onClose={() => setSuccessMessage("")}
                    dismissible
                >
                    {successMessage}
                </Alert>
            )}
            <div className="table-responsive">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Description (Chinese)</th>
                            <th>Description (English)</th>
                            <th className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No data available
                                </td>
                            </tr>
                        ) : (
                            paginatedTypes.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.campaign_type_code}</td>
                                    <td>{item.campaign_type_name.zh}</td>
                                    <td>{item.campaign_type_name.en}</td>
                                    <td className="text-end">
                                        <Button variant="warning" className="me-2">Edit</Button>
                                        <Button variant="danger" onClick={() => handleDeleteType(item)}>Delete</Button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
            <nav aria-label="Page navigation example" className="mt-3">
                <ul className="pagination justify-content-end">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                    </li>
                    {Array.from({ length: totalPagestype }, (_, i) => i + 1).map((page) => (
                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPagestype ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                    </li>
                </ul>
            </nav>
            <ToastContainer />
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formInput1">
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                                type="text"
                                name="input1"
                                value={inputValues.input1}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formInput2" className="mt-3">
                            <Form.Label>Description (Chinese)</Form.Label>
                            <Form.Control
                                type="text"
                                name="input2"
                                value={inputValues.input2}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formInput3" className="mt-3">
                            <Form.Label>Description (English)</Form.Label>
                            <Form.Control
                                type="text"
                                name="input3"
                                value={inputValues.input3}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
                    <Button variant="primary" onClick={handleFormSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CampaignType;
