import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Card, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import './campaigncss/BasicInfo.css'
import { CardBody } from "reactstrap";
import { IoNotificationsOutline } from 'react-icons/io5';
import { Add } from '@mui/icons-material';
import axios from 'axios';
import { useLanguage } from 'app/contexts/LanguageContext';
function BasicInfo({ campaignDetails }) {

    const [inputValues, setInputValues] = useState({
        campaignNameeng: "",
        campaignNamezh: "",
        venueeng: "",
        venuezh: "",
        introductionChinese: "",
        introductionEnglish: "",
        code: "",
        quota: "",
        district: "",
        inquiry: "",
        enrolmentMethod: "",
        campaignStatus: "",
        fee: "",
        targetaudience: "",
        campaignTypeId: "",
        prices: "",
        venueId: "",
    });


    const [prices, setPrices] = useState([
        { price: "", label: "", audience_id: "" },
    ]);

    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;
    //     // Handling for checkbox or switch type fields
    //     const newValue = type === "checkbox" ? checked : value;
    //     setInputValues((prevValues) => ({
    //         ...prevValues,
    //         [name]: value,
    //     }));
    // };
    useEffect(() => {
        if (campaignDetails) {
            setInputValues({
                campaignNamezh: campaignDetails.campaign_name?.zh || "",
                campaignNameeng: campaignDetails.campaign_name?.en || "",
                introductionChinese: campaignDetails.campaign_intr?.zh || "",
                introductionEnglish: campaignDetails.campaign_intr?.en || "",
                code: campaignDetails.campaign_code || "",
                quota: campaignDetails.campaign_quota || "",
                campaignStatus: campaignDetails.campaign_status || "",
                enrolmentMethod: campaignDetails.campaign_enrolment_method || "",
                fee: campaignDetails.campaign_prices?.price || "",
                inquiry: campaignDetails.campaign_inquiry || "",
                campaignTypeId: campaignDetails.campaign_type_id || "",
                venueeng: campaignDetails.venue_id || "",
            });
        }
    }, [campaignDetails]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };




    const handleSave = async () => {
        const token = localStorage.getItem("authToken");

        const payload = {
            business_id: "YMMSS001",
            campaign_name: {
                zh: inputValues.campaignNamezh,
                en: inputValues.campaignNameeng,
            },
            campaign_sub_name: {
                zh: "",
                en: "",
            },
            campaign_inquiry: { admin_id: inputValues.inquiry },
            campaign_type_id: inputValues.campaignTypeId,
            campaign_intr: {
                zh: inputValues.introductionChinese,
                en: inputValues.introductionEnglish,
            },
            campaign_venue: inputValues.venueId,
            campaign_prices: {
                price: inputValues.prices,
                label: inputValues.prices
            },
            campaign_quota: inputValues.quota,
            campaign_enrolment_method: inputValues.enrolmentMethod,
            campaign_target_audience: "2",
            campaign_code: inputValues.code,
            campaign_status: inputValues.campaignStatus,
            is_publish: inputValues.is_publish ? 1 : 0,

        };

        try {
            const response = await axios.post(
                "https://membershipuat.maxisense.io/webapp/memportal/createcampaign",
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Token: token,
                    },
                }
            );
            if (response.data.code === 1) {
                alert("Campaign created successfully!");
                console.log("Saved campaign data:", response.data);
            } else {
                alert(`Error: ${response.data.message_en}`);
                console.log(response.data)
            }
        } catch (error) {
            console.error("Error saving campaign:", error);
        }
    };



    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);


    const [data, setData] = useState([]);
    const sortedData = [...data].sort((a, b) => a.campaign_type_code.localeCompare(b.campaign_type_code));

    const fetchCampaignTypes = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getcampaigntypelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });
            // console.log(response.data)
            if (response.data.code === 1) {
                const campaignTypes = response.data.data || [];
                // Sort campaign types alphabetically based on campaign_type_code
                campaignTypes.sort((a, b) => {
                    return a.campaign_type_code.localeCompare(b.campaign_type_code);
                });
                setData(campaignTypes);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign types:", error);
        }
    };

    // Fetch campaign types initially and refresh every 3 seconds
    useEffect(() => {
        fetchCampaignTypes();
        const intervalId = setInterval(fetchCampaignTypes, 3000);
        return () => clearInterval(intervalId);
    }, []);



    const [venuedata, setvenuedataData] = useState([]);
    const fetchCampaignVenues = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getvenuelist", {
                headers: {
                    "Content-Type": "application/json",
                    Token: token,
                },
            });

            // Log the raw response
            // console.log("Raw response:", response.data);

            if (response.data.code === 1) {
                // Directly access response.data.data which is an array
                const venuesData = response.data.data || [];

                // Log parsed data
                console.log("Parsed venues data:", venuesData);

                setvenuedataData(venuesData);
            } else {
                console.error("API responded with an error:", response.data.message_en);
            }
        } catch (error) {
            console.error("Error fetching campaign venues:", error);
        }
    };
    useEffect(() => {
        fetchCampaignVenues();
        const intervalId = setInterval(fetchCampaignVenues, 3000);
        return () => clearInterval(intervalId);
    }, []);


    const { selectedLanguage } = useLanguage();


    const getRoleName = (item) => {
        return selectedLanguage === 'zh'
            ? item.campaign_type_name.zh
            : item.campaign_type_name.en;
    };

    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            console.error("No token found in localStorage");
            return;
        }

        try {
            const response = await axios.get(
                "https://membershipuat.maxisense.io/webapp/memportal/getadminlist",
                {
                    headers: {
                        Token: token,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.code === 1 && Array.isArray(response.data.data)) {
                setUsers(response.data.data);
            } else {
                console.warn("Unexpected API response format:", response.data);
                setUsers([]);
            }
        } catch (error) {
            console.error("Error fetching admin list:", error);
            setUsers([]);
        }

    };

    useEffect(() => {
        fetchUsers();
    }, []);



    const [audience, setAudience] = useState([]);
    const fetchaudience = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            console.error("No token found in localStorage");
            return;
        }

        try {
            const response = await axios.get(
                "https://membershipuat.maxisense.io/webapp/memportal/gettargetaudiencelist",
                {
                    headers: {
                        Token: token,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("audience", response.data)
            if (response.data.code === 1 && Array.isArray(response.data.data)) {
                setAudience(response.data.data);
            } else {
                console.warn("Unexpected API response format:", response.data);
                setAudience([]);
            }
        } catch (error) {
            console.error("Error fetching admin list:", error);
            setAudience([]);
        }

    };

    useEffect(() => {
        fetchaudience();
    }, []);


    const getAudienceName = (audien) => {
        return selectedLanguage === 'zh'
            ? audien.audience_name.zh
            : audien.audience_name.en;
    };

    const [rows, setRows] = useState([
        { targetaudience: '', prices: '' }
    ]);

    const handleChangerow = (index, field, value) => {
        setRows((prevRows) =>
            prevRows.map((row, i) =>
                i === index ? { ...row, [field]: value } : row
            )
        );
    };

    const handleAddRow = () => {
        setRows((prevRows) => [...prevRows, { targetaudience: '', prices: '' }]);
    };

    const handleRemoveRow = (index) => {
        setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    };




    return (
        <Container fluid>
            <Row>

                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>

                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">Basic Information</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
                                    <Button variant="primary" className="basicsavebtn me-3" onClick={handleSave}>Save</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <CardBody className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">Status: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    {/* Campaign Name (Chinese) */}
                                    <Form.Group controlId="subsidiaryNameChinese" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="campaignNamezh"
                                            value={inputValues.campaignNamezh}
                                            onChange={handleChange}
                                            className="floating-input"

                                        />
                                        <Form.Label className="floating-label">Campaign Name (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Campaign Name (English) */}
                                    <Form.Group controlId="subsidiaryNameEnglish" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="campaignNameeng"
                                            value={inputValues.campaignNameeng}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Campaign Name (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col md={6}>

                                    <Form.Group controlId="campaignNameChinese" className="floating-label-group">
                                        <Form.Select
                                            name="campaignName"
                                            value={inputValues.venuezh}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            {venuedata.map((item) => (
                                                <option key={item.venue_name.zh} >
                                                    {item.venue_name.zh}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Venue(Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    
                                    <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
                                        <Form.Select
                                            name="campaignName"
                                            value={inputValues.venueeng}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            {venuedata.map((item) => (
                                                <option key={item.venue_name.en} >
                                                    {item.venue_name.en}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Venue(English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col md={6}>
                                    {/* Venue Dropdown (Chinese) */}
                                    <Form.Group controlId="venueChinese" className="floating-label-group">
                                        <Form.Select
                                            name="venueId" // Name corresponds to the key where venue ID will be stored
                                            value={inputValues.venueId} // Bind to venueId in the state
                                            onChange={handleChange} // Handle value changes
                                            className="floating-input"
                                        >
                                            <option value="">Select Venue (Chinese)</option>
                                            {venuedata.map((item) => (
                                                <option key={item.venue_id} value={item.venue_id}>
                                                    {item.venue_name.zh} {/* Display Chinese name */}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Venue (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Venue Dropdown (English) */}
                                    <Form.Group controlId="venueEnglish" className="floating-label-group">
                                        <Form.Select
                                            name="venueId" // Name corresponds to the key where venue ID will be stored
                                            value={inputValues.venueId} // Bind to venueId in the state
                                            onChange={handleChange} // Handle value changes
                                            className="floating-input"
                                        >
                                            <option value="">Select Venue (English)</option>
                                            {venuedata.map((item) => (
                                                <option key={item.venue_id} value={item.venue_id}>
                                                    {item.venue_name.en} {/* Display English name */}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Venue (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>



                            <Row>



                                <Col md={2}>
                                    <Form.Group controlId="type" className="floating-label-group">
                                        <Form.Select
                                            name="campaignTypeId"
                                            className="floating-input"
                                            value={inputValues.campaignTypeId}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Campaign Type</option>
                                            {data.map((item) => (
                                                <option key={item.campaign_type_id} value={item.campaign_type_id}>
                                                    {item.campaign_type_code} ({getRoleName(item)})
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Type</Form.Label>
                                    </Form.Group>
                                </Col>



                                {/* Code Input */}
                                <Col md={2}>
                                    {/* Code Input holding 240703 */}
                                    <Form.Group controlId="code" className="floating-label-group">
                                        <Form.Control
                                            type="number"
                                            name="code"
                                            value={inputValues.code}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Code</Form.Label>
                                    </Form.Group>
                                </Col>

                                {/* District Dropdown */}
                                <Col md={2}>
                                    <Form.Group controlId="status" className="floating-label-group">
                                        <Form.Select
                                            name="campaignStatus"
                                            value={inputValues.campaignStatus}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            <option value="1">Normal</option>
                                            <option value="2">Delayed</option>
                                            <option value="3">Cancelled</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Status</Form.Label>
                                    </Form.Group>
                                </Col>




                                {/* Quota Input */}
                                <Col md={1}>
                                    {/* Quota Input holding value 40 */}
                                    <Form.Group controlId="quota" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="quota"
                                            value={inputValues.quota}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Quota</Form.Label>
                                    </Form.Group>
                                </Col>
                                {/* Inquiry Dropdown */}
                                <Col md={2}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">

                                            {users.map((user, index) => (
                                                <option key={user.admin_id || index}>
                                                    {user.admin_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Inquiry</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={3}>
                                    <Form.Group controlId="enrolmentMethod" className="floating-label-group">
                                        <Form.Select
                                            name="enrolmentMethod"
                                            value={inputValues.enrolmentMethod}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            <option value="1">First-come-first-served</option>
                                            <option value="2">Lottery Draw</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Enrolment Method</Form.Label>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    {/* Introduction (Chinese) */}
                                    <Form.Group controlId="introductionChinese" className="floating-label-group">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="introductionChinese"
                                            value={inputValues.introductionChinese}
                                            onChange={handleChange}
                                            className="floating-input"
                                            style={{ height: '200px' }}
                                        />
                                        <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Introduction (English) */}
                                    <Form.Group controlId="introductionEnglish" className="floating-label-group">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="introductionEnglish"
                                            value={inputValues.introductionEnglish}
                                            onChange={handleChange}
                                            className="floating-input"
                                            style={{ height: '200px' }}

                                        />
                                        <Form.Label className="floating-label">Introduction (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <br></br>

                            {/* <Row>
                                <h5 className="targetheading">
                                    Target Audience & Course Fee

                                </h5>
                                <br></br>
                                <Col md={2} className="mt-2">
                                    <Form.Group controlId="target" className="floating-label-group">
                                        <Form.Select
                                            name="label" 
                                            className="floating-input"
                                            value={inputValues.targetaudience} 
                                            onChange={handleChange} 
                                        >
                                            {audience.map((audien, index) => (
                                                <option key={audien.audience_id || index}>
                                                    
                                                    {getAudienceName(audien)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="floating-label">Target</Form.Label>
                                    </Form.Group>
                                </Col>



                                <Col md={2} className="mt-2">
                                    <Form.Group controlId="fee" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="fee"
                                            className="floating-input"
                                            value={inputValues.prices} 
                                            onChange={handleChange} 
                                        />
                                        <Form.Label className="floating-label">Fee</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={1} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#FF4694',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0',
                                        }}
                                    >
                                        --
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={1} >
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}

                                    >
                                        <Add style={{ color: '#fff', fontSize: '16px' }} />
                                    </Button>
                                </Col>
                            </Row> */}


                            <Row>
                                <h5 className="targetheading">Target Audience & Course Fee</h5>

                                {rows.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <Col md={2} className="mt-2">
                                            <Form.Group controlId={`target-${index}`} className="floating-label-group">
                                                <Form.Select
                                                    name="targetaudience"
                                                    className="floating-input"
                                                    value={row.targetaudience}
                                                    onChange={(e) => handleChange(index, 'targetaudience', e.target.value)}
                                                >
                                                    {audience.map((audien, index) => (
                                                        <option key={audien.audience_id || index}>

                                                            {getAudienceName(audien)}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Label className="floating-label">Target</Form.Label>
                                            </Form.Group>
                                        </Col>

                                        <Col md={2} className="mt-2">
                                            <Form.Group controlId={`fee-${index}`} className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="prices"
                                                    className="floating-input"
                                                    value={row.prices}
                                                    onChange={(e) => handleChange(index, 'prices', e.target.value)}
                                                />
                                                <Form.Label className="floating-label">Fee</Form.Label>
                                            </Form.Group>
                                        </Col>

                                        <Col md={1} className="d-flex justify-content-center align-items-center">
                                            <Button
                                                style={{
                                                    backgroundColor: '#FF4694',
                                                    border: 'none',
                                                    height: '24px',
                                                    width: '24px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '0',
                                                }}
                                                onClick={() => handleRemoveRow(index)}
                                                disabled={rows.length === 1} // Disable if only one row remains
                                            >
                                                --
                                            </Button>
                                        </Col>
                                    </React.Fragment>
                                ))}
                            </Row>

                            {/* Add button to generate new row */}
                            <Row className="mt-3">
                                <Col md={1}>
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0',
                                        }}
                                        onClick={handleAddRow}
                                    >
                                        <Add style={{ color: '#fff', fontSize: '16px' }} />
                                    </Button>
                                </Col>
                            </Row>

                            <br></br><br></br>
                            <Row>
                                <h5 className="targetheading">Publish  <FormControlLabel
                                    control={
                                        <Switch
                                            checked={inputValues.is_publish === 1} // Check state value properly
                                            onChange={(e) => setInputValues({ ...inputValues, is_publish: e.target.checked ? 1 : 0 })}
                                        />
                                    }
                                    label=""
                                />
                                </h5>
                                <p className="publictext">Enable “Publish” in the Portal, and members can view and join the campaign on the mobile app.</p>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>

            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            Assign to
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default BasicInfo;





