

// import React, { useState } from "react";
// import { Container, Row, Col, Card, Button, Form, Image, Modal } from "react-bootstrap";
// import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./membercss/MemberInfo.css";
// import "./membercss/InstructorSetting.css";
// import axios from 'axios';
// import Avatars from './Avatars';

// function InstructorSetting() {
//     const [inputValues, setInputValues] = useState({
//         input1: "", // Qualification (Chinese)
//         input2: "", // BIO (Chinese)
//         input3: "", // Qualification (English)
//         input4: ""  // BIO (English)
//     });
//     const [showModal, setShowModal] = useState(false);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [previewImage, setPreviewImage] = useState(null);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setInputValues({ ...inputValues, [name]: value });
//     };

//     const handleFileChange = (e) => {
//         const file = e.target.files[0];
//         setSelectedFile(file);
//         setPreviewImage(URL.createObjectURL(file));
//     };

//     const handleOpen = () => setShowModal(true);
//     const handleClose = () => setShowModal(false);

//     const handleModalSave = () => {
//         if (selectedFile) {
//             setShowModal(false);
//         } else {
//             alert("Please select an image.");
//         }
//     };

//     const handleSave = async () => {
//         if (!inputValues.input1 || !inputValues.input2 || !inputValues.input3 || !inputValues.input4) {
//             alert("All fields are required");
//             return;
//         }

//         try {
//             const formData = new FormData();
//             formData.append("input1", inputValues.input1);
//             formData.append("input2", inputValues.input2);
//             formData.append("input3", inputValues.input3);
//             formData.append("input4", inputValues.input4);
//             if (selectedFile) {
//                 formData.append("instructorPhoto", selectedFile);
//             }

//             const response = await axios.post(
//                 "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-instructor-infos",
//                 formData,
//                 { headers: { "Content-Type": "multipart/form-data" } }
//             );

//             if (response.status === 200) {
//                 alert("Instructor information saved successfully!");
//                 setInputValues({
//                     input1: "",
//                     input2: "",
//                     input3: "",
//                     input4: ""
//                 });
//                 setSelectedFile(null);
//                 setPreviewImage(null);
//             }
//         } catch (error) {
//             console.error("Error saving instructor information:", error);
//             alert(error.response?.data?.error || "Failed to save instructor information.");
//         }
//     };

//     const handleSelectFile = () => {
//         document.getElementById("file-input").click();
//     };

//     return (
//         <Container fluid>
//             <Row>
//                 <Col md={12}>
//                     <Card className="mt-0 border-0" style={{ position: "relative" }}>
//                         <Card.Body>
//                             <Avatars />
//                             <br />
//                             <Form>
//                                 <Row>
//                                     <h5 className="basicinfo mt-3">Instructor Setting</h5>
//                                     <Col md={6} className="mt-4">
//                                         <Form.Group controlId="formInput1" className="floating-label-group">
//                                             <Form.Control
//                                                 type="text"
//                                                 name="input1"
//                                                 value={inputValues.input1}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                             />
//                                             <Form.Label className="floating-label">
//                                                 Instructor Qualification (Chinese)
//                                             </Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formInput2" className="floating-label-group">
//                                             <Form.Control
//                                                 type="text"
//                                                 name="input2"
//                                                 value={inputValues.input2}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                                 style={{ height: "90px" }}
//                                             />
//                                             <Form.Label className="floating-label">BIO (Chinese)</Form.Label>
//                                         </Form.Group>
//                                     </Col>

//                                     <Col md={6} className="mt-4">
//                                         <Form.Group controlId="formInput3" className="floating-label-group">
//                                             <Form.Control
//                                                 type="text"
//                                                 name="input3"
//                                                 value={inputValues.input3}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                             />
//                                             <Form.Label className="floating-label">
//                                                 Instructor Qualification (English)
//                                             </Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formInput4" className="floating-label-group">
//                                             <Form.Control
//                                                 type="text"
//                                                 name="input4"
//                                                 value={inputValues.input4}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                                 style={{ height: "90px" }}
//                                             />
//                                             <Form.Label className="floating-label">BIO (English)</Form.Label>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//                                 <Row className="mt-3">
//                                     <Col className="mt-4">
//                                         <Button variant="primary" className="me-2 instructorphotoss" onClick={handleOpen}>
//                                             Add Instructor Photo
//                                         </Button>
//                                     </Col>
//                                 </Row>
//                                 <Row className="mt-3">
//                                     <Col className="mt-4">
//                                         <Button variant="primary" className="me-2 instructorphotoss" onClick={handleSave}>
//                                             Save Changes
//                                         </Button>
//                                         <Button variant="outline-primary" className="canslebtn">Cancel</Button>
//                                     </Col>
//                                 </Row>
//                             </Form>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//             <Modal show={showModal} onHide={handleClose} centered size="lg" className="custom-modal">
//                 <Modal.Body style={{ height: "360px" }}>
//                     <h4 className="maintitle">Add Instructor Photo</h4>
//                     <div style={{ textAlign: "center", height: "156px", padding: '40px', margin: '20px', border: ' 1px solid #D9D9D9' }}>
//                         <p>Ratio limit 79:82, format PNG/JPG, e.g. 395(W) * 410(H)</p>
//                         <Form.Group controlId="formFile">
//                             <input
//                                 type="file"
//                                 id="file-input"
//                                 accept="image/*"
//                                 onChange={handleFileChange}
//                                 style={{ display: "none" }}
//                             />
//                             <Button onClick={handleSelectFile}>Select</Button>
//                         </Form.Group>
//                         {previewImage && (
//                             <Image
//                                 src={previewImage}
//                                 alt="Selected Instructor"
//                                 style={{ width: "90px", height: "100px", marginTop: "55px", marginLeft: '0' }}
//                             />
//                         )}
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                         Cancel
//                     </Button>
//                     <Button variant="primary" onClick={handleModalSave}>
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </Container>
//     );
// }

// export default InstructorSetting;



//------------------------------------------------------------------------------------------------------------------------------


import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image, Modal } from "react-bootstrap";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import "./membercss/InstructorSetting.css";
import axios from 'axios';
import Avatars from './Avatars';

function InstructorSetting() {
    const [inputValues, setInputValues] = useState({
        input1: "",
        input2: "",
        input3: "",
        input4: ""
    });
    const [showModal, setShowModal] = useState(false);
    const [showForm, setShowForm] = useState(false); // State to toggle form visibility
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setPreviewImage(URL.createObjectURL(file));
    };

    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleModalSave = () => {
        if (selectedFile) {
            setShowModal(false);
        } else {
            alert("Please select an image.");
        }
    };

    const handleSave = async () => {
        if (!inputValues.input1 || !inputValues.input2 || !inputValues.input3 || !inputValues.input4) {
            alert("All fields are required");
            return;
        }

        try {
            const formData = new FormData();
            formData.append("input1", inputValues.input1);
            formData.append("input2", inputValues.input2);
            formData.append("input3", inputValues.input3);
            formData.append("input4", inputValues.input4);
            if (selectedFile) {
                formData.append("instructorPhoto", selectedFile);
            }

            const response = await axios.post(
                "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-instructor-infos",
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            if (response.status === 200) {
                alert("Instructor information saved successfully!");
                setInputValues({
                    input1: "",
                    input2: "",
                    input3: "",
                    input4: ""
                });
                setSelectedFile(null);
                setPreviewImage(null);
            }
        } catch (error) {
            console.error("Error saving instructor information:", error);
            alert(error.response?.data?.error || "Failed to save instructor information.");
        }
    };

    const handleSelectFile = () => {
        document.getElementById("file-input").click();
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                            <Avatars />
                            <br />
                            <Row className="align-items-center">
                                <Col>
                                    <h5 className="basicinfo mt-3">
                                        Instructor Setting
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showForm}
                                                    onChange={() => setShowForm(!showForm)}
                                                    color="primary"
                                                    className="ms-3"
                                                />
                                            }
                                            label=""
                                        />
                                    </h5>
                                </Col>
                            </Row>
                            {showForm && (
                                <Form>
                                    <Row>
                                        <Col md={6} className="mt-4">
                                            <Form.Group controlId="formInput1" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input1"
                                                    value={inputValues.input1}
                                                    onChange={handleChange}
                                                    className="floating-input"
                                                />
                                                <Form.Label className="floating-label">
                                                    Instructor Qualification (Chinese)
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group controlId="formInput2" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input2"
                                                    value={inputValues.input2}
                                                    onChange={handleChange}
                                                    className="floating-input"
                                                    style={{ height: "90px" }}
                                                />
                                                <Form.Label className="floating-label">BIO (Chinese)</Form.Label>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mt-4">
                                            <Form.Group controlId="formInput3" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input3"
                                                    value={inputValues.input3}
                                                    onChange={handleChange}
                                                    className="floating-input"
                                                />
                                                <Form.Label className="floating-label">
                                                    Instructor Qualification (English)
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group controlId="formInput4" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input4"
                                                    value={inputValues.input4}
                                                    onChange={handleChange}
                                                    className="floating-input"
                                                    style={{ height: "90px" }}
                                                />
                                                <Form.Label className="floating-label">BIO (English)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="mt-4">
                                            <Button variant="primary" className="me-2 instructorphotoss" onClick={handleOpen}>
                                                Add Instructor Photo
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="mt-4">
                                            <Button variant="primary" className="me-2 instructorphotoss" onClick={handleSave}>
                                                Save Changes
                                            </Button>
                                            <Button variant="outline-primary" className="canslebtn">Cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleClose} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: "360px" }}>
                    <h4 className="maintitle">Add Instructor Photo</h4>
                    <div style={{ textAlign: "center", height: "156px", padding: '40px', margin: '20px', border: ' 1px solid #D9D9D9' }}>
                        <p>Ratio limit 79:82, format PNG/JPG, e.g. 395(W) * 410(H)</p>
                        <Form.Group controlId="formFile">
                            <input
                                type="file"
                                id="file-input"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                            />
                            <Button onClick={handleSelectFile}>Select</Button>
                        </Form.Group>
                        {previewImage && (
                            <Image
                                src={previewImage}
                                alt="Selected Instructor"
                                style={{ width: "90px", height: "100px", marginTop: "55px", marginLeft: '0' }}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleModalSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default InstructorSetting;

