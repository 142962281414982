
// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form, Alert } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import axios from "axios";
// import "./rolemanagemntcss/RoleManagementNew.css";

// const RoleManagementNew = ({ show, handleClose, modalTitle, roleData, fetchRoles }) => {
//   const [roleName, setRoleName] = useState("");
//   const [roleNameeng, setRoleNameeng] = useState("");
//   const [permissions, setPermissions] = useState({
//     "Role Management": { read: false, add: false, edit: false, delete: false },
//     "User Management": { read: false, add: false, edit: false, delete: false },
//     "Member Management": { read: false, add: false, edit: false, delete: false },
//     "User Defined Categories": { read: false, add: false, edit: false, delete: false },
//     "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
//     "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
//     "Reports": { read: false, download: false },
//   });

//   const [errorMessage, setErrorMessage] = useState("");
//   const [successMessage, setSuccessMessage] = useState("");

//   const MODULE_PERMISSION_MAP = {
//     "Role Management": "role",
//     "User Management": "admin",
//     "Member Management": "account",
//     "User Defined Categories": "sef_category",
//     "Push Notification": "push",
//     "Campaign Management": "campaign",
//     "Reports": "report",
//   };

//   // Effect to populate fields when editing a role
//   useEffect(() => {
//     if (roleData) {
//       console.log("Editing Role Data:", roleData); // Debugging role data
//       setRoleName(roleData.role_name || "");
//       setRoleNameeng(roleData.role_name_en || "");

//       setPermissions(convertPermissionsToState(roleData.permissions || []));
//     } else {
//       resetFields();
//     }
//   }, [roleData]);

//   const resetFields = () => {
//     setRoleName("");
//     setRoleNameeng("");
//     setPermissions({
//       "Role Management": { read: false, add: false, edit: false, delete: false },
//       "User Management": { read: false, add: false, edit: false, delete: false },
//       "Member Management": { read: false, add: false, edit: false, delete: false },
//       "User Defined Categories": { read: false, add: false, edit: false, delete: false },
//       "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
//       "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
//       "Reports": { read: false, download: false },
//     });
//   };

//   const convertPermissionsToState = (permissionsArray) => {
//     console.log("Converting Permissions:", permissionsArray); // Debugging permissions
//     const updatedPermissions = {
//       "Role Management": { read: false, add: false, edit: false, delete: false },
//       "User Management": { read: false, add: false, edit: false, delete: false },
//       "Member Management": { read: false, add: false, edit: false, delete: false },
//       "User Defined Categories": { read: false, add: false, edit: false, delete: false },
//       "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
//       "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
//       "Reports": { read: false, download: false },
//     };

//     if (!Array.isArray(permissionsArray)) return updatedPermissions;

//     permissionsArray.forEach((permission) => {
//       Object.keys(MODULE_PERMISSION_MAP).forEach((module) => {
//         if (permission.startsWith(MODULE_PERMISSION_MAP[module])) {
//           const action = permission.split("_")[1] || "read"; // Default action to "read"
//           if (updatedPermissions[module][action] !== undefined) {
//             updatedPermissions[module][action] = true;
//           }
//         }
//       });
//     });

//     console.log("Converted Permissions State:", updatedPermissions); // Debugging state
//     return updatedPermissions;
//   };



//   const handleChange = (category, e) => {
//     const { name, checked } = e.target;
//     // Handle "delete" or "manage" permissions here (already present in your logic)
//     if (name === "delete") {
//       if (checked) {
//         setPermissions((prevPermissions) => ({
//           ...prevPermissions,
//           [category]: {
//             read: true,
//             add: true,
//             edit: true,
//             delete: true,
//           },
//         }));
//       } else {
//         setPermissions((prevPermissions) => ({
//           ...prevPermissions,
//           [category]: {
//             ...prevPermissions[category],
//             delete: false,
//           },
//         }));
//       }
//     } else if (name === "manage" && ["Push Notification", "Campaign Management"].includes(category)) {
//       if (checked) {
//         setPermissions((prevPermissions) => ({
//           ...prevPermissions,
//           [category]: {
//             read: true,
//             add: true,
//             edit: true,
//             delete: true,
//             manage: true,
//           },
//         }));
//       } else {
//         setPermissions((prevPermissions) => ({
//           ...prevPermissions,
//           [category]: {
//             ...prevPermissions[category],
//             manage: false,
//           },
//         }));
//       }
//     } else {
//       setPermissions((prevPermissions) => ({
//         ...prevPermissions,
//         [category]: {
//           ...prevPermissions[category],
//           [name]: checked,
//         },
//       }));
//     }
//   };

//   const buildPermissionList = () => {
//     const permissionList = [];

//     Object.entries(permissions).forEach(([module, actions]) => {
//       const basePermission = MODULE_PERMISSION_MAP[module];

//       Object.entries(actions).forEach(([action, isEnabled]) => {
//         if (isEnabled) {
//           let formattedPermission = "";

//           switch (action) {
//             case "read":
//               formattedPermission = `${basePermission}_r`; // Add `_r` for read
//               break;
//             case "add":
//               formattedPermission = `${basePermission}`; // No suffix for add
//               break;
//             case "edit":
//               formattedPermission = `${basePermission}_u`; // Add `_u` for edit
//               break;
//             case "delete":
//               formattedPermission = `${basePermission}_d`; // Add `_d` for delete
//               break;
//             case "manage":
//               formattedPermission = `${basePermission}_manage`; // Add `_manage` for manage
//               break;
//             case "download":
//               formattedPermission = `${basePermission}_download`; // Add `_download` for download
//               break;
//             default:
//               break;
//           }

//           if (formattedPermission) {
//             permissionList.push(formattedPermission);
//           }
//         }
//       });
//     });

//     console.log("Generated Permission List:", permissionList); // Debugging the final list
//     return permissionList;
//   };







//   const handleSave = async () => {
//     setErrorMessage("");
//     setSuccessMessage("");

//     const permissionList = buildPermissionList();

//     const payload = {
//       role_name: roleName,
//       role_name_en: roleNameeng,
//       available: true,
//       permission_list: permissionList,
//     };

//     try {
//       if (roleData) {
//         const updatePayload = { ...payload, role_id: roleData.role_id };

//         await axios.post(
//           "https://membershipuat.maxisense.io/webapp/memportal/updaterole",
//           updatePayload,
//           {
//             headers: {
//               Token: localStorage.getItem("authToken"),
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         setSuccessMessage("Role updated successfully!");
//       } else {
//         await axios.post(
//           "https://membershipuat.maxisense.io/webapp/memportal/addrole",
//           payload,
//           {
//             headers: {
//               Token: localStorage.getItem("authToken"),
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         setSuccessMessage("Role added successfully!");
//       }

//       fetchRoles && fetchRoles();
//       setTimeout(() => setSuccessMessage(""), 2000);
//       resetFields();
//       handleClose();
//     } catch (error) {
//       if (error.response && error.response.status === 409) {
//         setErrorMessage("Role already exists.");
//       } else {
//         setErrorMessage("An error occurred while saving the role.");
//       }
//     }
//   };

//   const handleModalClose = () => {
//     resetFields();
//     handleClose();
//   };

//   return (
//     <Modal show={show} onHide={handleModalClose} centered size="md" className="custom-modal">
//       <Modal.Body>
//         <Form>
//           <h4 className="maintitle">{modalTitle}</h4>
//           {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

//           <Form.Group controlId="formRoleName" className="floating-label-group">
//             <Form.Control
//               type="text"
//               value={roleNameeng}
//               onChange={(e) => setRoleNameeng(e.target.value)}
//               className="floating-input"
//               required
//             />
//             <Form.Label className="floating-label">Role Name (English)</Form.Label>
//           </Form.Group>

//           <Form.Group controlId="formRoleName" className="floating-label-group">
//             <Form.Control
//               type="text"
//               value={roleName}
//               onChange={(e) => setRoleName(e.target.value)}
//               className="floating-input"
//               required
//             />
//             <Form.Label className="floating-label">Role Name (Chinese)</Form.Label>
//           </Form.Group>



//           {Object.keys(permissions).map((category) => (
//             <div key={category} className="mt-4">
//               <Form.Label className="lablecheck">{category}</Form.Label>
//               <div className="checkbox-group">
//                 {Object.keys(permissions[category]).map((permission) => (
//                   <Form.Check
//                     key={permission}
//                     type="checkbox"
//                     label={permission.charAt(0).toUpperCase() + permission.slice(1)}
//                     name={permission}
//                     className="checkbtn"
//                     checked={permissions[category][permission]}
//                     onChange={(e) => handleChange(category, e)}
//                   />
//                 ))}
//               </div>
//             </div>
//           ))}
//         </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleModalClose}>
//           Cancel
//         </Button>
//         <Button variant="primary" onClick={handleSave}>
//           Save
//         </Button>
//       </Modal.Footer>
//       {successMessage && (
//         <div className="success-popup">
//           <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
//             {successMessage}
//           </Alert>
//         </div>
//       )}
//     </Modal>
//   );
// };

// export default RoleManagementNew;


import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "./rolemanagemntcss/RoleManagementNew.css";

const RoleManagementNew = ({ show, handleClose, modalTitle, roleData, fetchRoles }) => {
  const [roleName, setRoleName] = useState("");
  const [roleNameeng, setRoleNameeng] = useState("");
  const [permissions, setPermissions] = useState({
    "Role Management": { read: false, add: false, edit: false, delete: false },
    "User Management": { read: false, add: false, edit: false, delete: false },
    "Member Management": { read: false, add: false, edit: false, delete: false },
    "User Defined Categories": { read: false, add: false, edit: false, delete: false },
    "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
    "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
    "Reports": { read: false, download: false },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const MODULE_PERMISSION_MAP = {
    "Role Management": "role",
    "User Management": "admin",
    "Member Management": "account",
    "User Defined Categories": "sef_category",
    "Push Notification": "push",
    "Campaign Management": "campaign",
    "Reports": "report",
  };

  // Effect to populate fields when editing a role
  useEffect(() => {
    if (roleData) {
      console.log("Editing Role Data:", roleData); // Debugging role data
      setRoleName(roleData.role_name || "");
      setRoleNameeng(roleData.role_name_en || "");
      setPermissions(convertPermissionsToState(roleData.permission_list || []));
    } else {
      resetFields();
    }
  }, [roleData]);

  const resetFields = () => {
    setRoleName("");
    setRoleNameeng("");
    setPermissions({
      "Role Management": { read: false, add: false, edit: false, delete: false },
      "User Management": { read: false, add: false, edit: false, delete: false },
      "Member Management": { read: false, add: false, edit: false, delete: false },
      "User Defined Categories": { read: false, add: false, edit: false, delete: false },
      "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
      "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
      "Reports": { read: false, download: false },
    });
  };

  const convertPermissionsToState = (permissionsList) => {
    const updatedPermissions = {
      "Role Management": { read: false, add: false, edit: false, delete: false },
      "User Management": { read: false, add: false, edit: false, delete: false },
      "Member Management": { read: false, add: false, edit: false, delete: false },
      "User Defined Categories": { read: false, add: false, edit: false, delete: false },
      "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
      "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
      "Reports": { read: false, download: false },
    };

    permissionsList.forEach((permission) => {
      Object.keys(MODULE_PERMISSION_MAP).forEach((module) => {
        const basePermission = MODULE_PERMISSION_MAP[module];

        if (permission === `${basePermission}_r`) {
          updatedPermissions[module].read = true;
        } else if (permission === basePermission) {
          updatedPermissions[module].add = true;
        } else if (permission === `${basePermission}_u`) {
          updatedPermissions[module].edit = true;
        } else if (permission === `${basePermission}_d`) {
          updatedPermissions[module].delete = true;
        } else if (permission === `${basePermission}_manage`) {
          updatedPermissions[module].manage = true;
        } else if (permission === `${basePermission}_download`) {
          updatedPermissions[module].download = true;
        }
      });
    });

    console.log("Converted Permissions State:", updatedPermissions); // Debugging state
    return updatedPermissions;
  };

  const handleChange = (category, e) => {
    const { name, checked } = e.target;

    if (name === "delete") {
      if (checked) {
        setPermissions((prevPermissions) => ({
          ...prevPermissions,
          [category]: {
            read: true,
            add: true,
            edit: true,
            delete: true,
          },
        }));
      } else {
        setPermissions((prevPermissions) => ({
          ...prevPermissions,
          [category]: {
            ...prevPermissions[category],
            delete: false,
          },
        }));
      }
    } else if (name === "manage" && ["Push Notification", "Campaign Management"].includes(category)) {
      if (checked) {
        setPermissions((prevPermissions) => ({
          ...prevPermissions,
          [category]: {
            read: true,
            add: true,
            edit: true,
            delete: true,
            manage: true,
          },
        }));
      } else {
        setPermissions((prevPermissions) => ({
          ...prevPermissions,
          [category]: {
            ...prevPermissions[category],
            manage: false,
          },
        }));
      }
    } else {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          [name]: checked,
        },
      }));
    }
  };

  const buildPermissionList = () => {
    const permissionList = [];

    Object.entries(permissions).forEach(([module, actions]) => {
      const basePermission = MODULE_PERMISSION_MAP[module];

      Object.entries(actions).forEach(([action, isEnabled]) => {
        if (isEnabled) {
          let formattedPermission = "";

          switch (action) {
            case "read":
              formattedPermission = `${basePermission}_r`; // Add `_r` for read
              break;
            case "add":
              formattedPermission = `${basePermission}`; // No suffix for add
              break;
            case "edit":
              formattedPermission = `${basePermission}_u`; // Add `_u` for edit
              break;
            case "delete":
              formattedPermission = `${basePermission}_d`; // Add `_d` for delete
              break;
            case "manage":
              formattedPermission = `${basePermission}_manage`; // Add `_manage` for manage
              break;
            case "download":
              formattedPermission = `${basePermission}_download`; // Add `_download` for download
              break;
            default:
              break;
          }

          if (formattedPermission) {
            permissionList.push(formattedPermission);
          }
        }
      });
    });

    console.log("Generated Permission List:", permissionList); // Debugging the final list
    return permissionList;
  };

  const handleSave = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    const permissionList = buildPermissionList();

    const payload = {
      role_name: roleName,
      role_name_en: roleNameeng,
      available: true,
      permission_list: permissionList,
    };

    try {
      if (roleData) {
        const updatePayload = { ...payload, role_id: roleData.role_id };

        await axios.post(
          "https://membershipuat.maxisense.io/webapp/memportal/updaterole",
          updatePayload,
          {
            headers: {
              Token: localStorage.getItem("authToken"),
              "Content-Type": "application/json",
            },
          }
        );
        setSuccessMessage("Role updated successfully!");
      } else {
        await axios.post(
          "https://membershipuat.maxisense.io/webapp/memportal/addrole",
          payload,
          {
            headers: {
              Token: localStorage.getItem("authToken"),
              "Content-Type": "application/json",
            },
          }
        );
        setSuccessMessage("Role created successfully!");
      }

      fetchRoles && fetchRoles();
      setTimeout(() => setSuccessMessage(""), 2000);
      setTimeout(() => handleClose(), 2000)
        ;
    } catch (error) {
      console.error("Error saving role:", error);
      setErrorMessage("There was an error saving the role.");
    }
  };

  const handleModalClose = () => {
    resetFields();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title className="maintitle">{modalTitle}</Modal.Title>
      </Modal.Header>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <Modal.Body>
        <Form>
          <Form.Group controlId="formRoleName" className="floating-label-group">
            <Form.Control
              type="text"
              value={roleNameeng}
              onChange={(e) => setRoleNameeng(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">Role Name (English)</Form.Label>
          </Form.Group>

          <Form.Group controlId="formRoleNameArabic" className="floating-label-group">
            <Form.Control
              type="text"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">Role Name (Chinese)</Form.Label>
          </Form.Group>

          {Object.keys(permissions).map((category) => (
            <div key={category} className="mt-4">
              <Form.Label className="lablecheck">{category}</Form.Label>
              <div className="checkbox-group">
                {Object.keys(permissions[category]).map((permission) => (
                  <Form.Check
                    key={permission}
                    type="checkbox"
                    label={permission.charAt(0).toUpperCase() + permission.slice(1)}
                    name={permission}
                    className="checkbtn"
                    checked={permissions[category][permission]} // Bind checkbox state
                    onChange={(e) => handleChange(category, e)}
                  />
                ))}
              </div>
            </div>
          ))}


          <div className="modal-footer">
            <Button variant="secondary" onClick={handleModalClose} className="rolecancelbtn">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave} className="rolesavebtn">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RoleManagementNew;


