

import React, { useState, useEffect } from "react";
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./membercss/Member.css";
import "./membercss/Advance.css"
import { IoIosSearch } from "react-icons/io";
const Advance = () => {
    const navigate = useNavigate();
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        delete: false,
    });

    // Fetch permissions
    useEffect(() => {
        const fetchPermissions = async () => {
            const token = localStorage.getItem("authToken");
            if (!token) {
                console.error("No token found in localStorage");
                return;
            }

            try {
                const response = await axios.get(
                    "https://membershipuat.maxisense.io/webapp/memportal/getadmininfo",
                    {
                        headers: {
                            Token: token,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.data.code === 1) {
                    const permissionsList = response.data.data.permission_list || [];
                    setPermissions({
                        add: permissionsList.includes("account"),
                        edit: permissionsList.includes("account_u"),
                        delete: permissionsList.includes("account_d"),
                    });
                }
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };

        fetchPermissions();
    }, []);

    const handleAddMember = () => {
        navigate("/member/memberinfo");
    };

    const handleEditMember = (member) => {
        navigate("/member/memberinfo", { state: { member } });
    };

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await axios.get(
                    "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/fetch-basic-info"
                );
                const data = JSON.parse(response.data.body);
                setMembers(data.basicInfo);
                setFilteredMembers(data.basicInfo);
            } catch (error) {
                console.error("Error fetching members:", error);
            }
        };
        fetchMembers();
    }, []);

    const handlePaginationClick = (index) => {
        setPage(index);
    };

    const currentMembers = filteredMembers.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage
    );

    const totalPages = Math.ceil(filteredMembers.length / rowsPerPage);

    const [rows, setRows] = useState([{ id: 1, attribute: "", condition: "", value: "" }]);

    const handleAddFilter = () => {
        setRows([...rows, { id: rows.length + 1, attribute: "", condition: "", value: "" }]);
    };

    const handleRemoveFilter = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);

        // Ensure at least one row remains
        if (updatedRows.length === 0) {
            setRows([{ id: 1, attribute: "", condition: "", value: "" }]);
        }
    };

    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map((row) =>
            row.id === id ? { ...row, [field]: value } : row
        );
        setRows(updatedRows);
    };

    const handleReset = () => {
        setRows([{ id: 1, attribute: "", condition: "", value: "" }]);
    };


    return (
        <Container fluid style={{ padding: "0" }}>
            {rows.map((row, index) => (
                <Row className="align-items-center mb-3 ps-4 mt-4" key={row.id}>



                    {/* Attribute Name Input */}
                    <Col xs={12} md={2}>
                        {/* Show label only for the first row */}
                        {index === 0 && (
                            <Form.Label className="advanceinputlabel">Attribute Name</Form.Label>
                        )}
                        <div style={{ position: "relative" }}>
                            <IoIosSearch
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    transform: "translateY(-50%)",
                                    color: "#6c757d",
                                }}
                                size={25}
                            />
                            <Form.Control
                                type="text"
                                className="advanceinput"
                                style={{ paddingLeft: "35px" }}
                                value={row.attribute}
                                onChange={(e) =>
                                    handleInputChange(row.id, "attribute", e.target.value)
                                }
                            />
                        </div>
                    </Col>

                    {/* Condition Dropdown */}
                    <Col xs={12} md={2}>
                        {index === 0 && (
                            <Form.Label className="advanceinputlabel">Condition</Form.Label>
                        )}
                        <Form.Select
                            className="advanceinput"
                            style={{ height: "29px", padding: "0 8px" }}
                            value={row.condition}
                            onChange={(e) =>
                                handleInputChange(row.id, "condition", e.target.value)
                            }
                        >
                            <option value=""></option>
                            <option value="equals">Equals</option>
                            <option value="notEquals">Not Equals</option>
                            <option value="contains">Contains</option>
                            <option value="startsWith">Starts With</option>
                            <option value="endsWith">Ends With</option>
                        </Form.Select>
                    </Col>

                    {/* Value Input */}
                    <Col xs={12} md={2}>
                        {index === 0 && (
                            <Form.Label className="advanceinputlabel">Value</Form.Label>
                        )}
                        <Form.Control
                            type="text"
                            className="advanceinput"
                            value={row.value}
                            onChange={(e) => handleInputChange(row.id, "value", e.target.value)}
                        />
                    </Col>

                    <Col xs={12} md={1} className="d-flex justify-content-end mt-4">
                        <Button
                            className="advancefilter me-2"
                            onClick={() => handleRemoveFilter(row.id)}
                            style={{ height: "30px" }}
                        >
                            Remove
                        </Button>
                    </Col>
                </Row>
            ))}




            {/* Add Filter and Search Buttons */}
            <Row className="ps-4">
                <Col xs={12} md={3} className="mb-3 mt-2">
                    <Button
                        className="advancefilter me-2"
                        onClick={handleAddFilter}
                    >
                        Add Filter
                    </Button>
                </Col>

            </Row>

            <Row className="ps-4 mt-3">
                <Col xs={12} md={3} >
                    <Button className="custom-success-btn me-2">Search</Button>
                    <Button className="identityreset" onClick={handleReset}>
                        Reset
                    </Button>
                </Col>
            </Row>




            <Row className="ps-4 mt-5">
                <Col xs={12}>
                    <h4 className="member-result">Result</h4>
                </Col>
            </Row>
            <hr />
            <Row className="ps-4 me-3">
                <Col xs={12} md={{ span: 12 }}>
                    <Table
                        striped
                        hover
                        responsive
                        className="centered-table"
                        style={{ margin: "0", padding: "0", width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th className="custom-th">Member Name</th>
                                <th className="custom-th">Joined Date</th>
                                <th className="custom-th">Mobile</th>
                                <th className="custom-th">Member ID</th>
                                <th className="custom-th">Card Ref.</th>
                                <th className="custom-th">Email</th>
                                <th className="custom-th">App 1st Login</th>
                                <th className="custom-th">Member Identity</th>
                                <th className="custom-th">Function(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentMembers.length > 0 ? (
                                currentMembers.map((member, index) => (
                                    <tr key={index}>
                                        <td className="custom-td">{member.name}</td>
                                        <td className="custom-td">{member.joinedDate}</td>
                                        <td className="custom-td">{member.phone}</td>
                                        <td className="custom-td">{member.memberId}</td>
                                        <td className="custom-td">{member.cardRef}</td>
                                        <td className="custom-td">{member.email}</td>
                                        <td className="custom-td">{member.firstLogin}</td>
                                        <td className="custom-td">Category</td>
                                        <td className="custom-td">
                                            {permissions.edit && (
                                                <Button
                                                    variant="success"
                                                    className="custom-success-btn me-2"
                                                    onClick={() => handleEditMember(member)}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9" className="text-center">
                                        No members found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    {/* <nav aria-label="Page navigation example" className="mt-5">
                        <ul className="pagination justify-content-end">
                            <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => handlePaginationClick(page - 1)}
                                    aria-disabled={page === 0}
                                >
                                    Previous
                                </a>
                            </li>
                            {Array.from({ length: totalPages }, (_, idx) => (
                                <li
                                    key={idx}
                                    className={`page-item ${idx === page ? "active" : ""}`}
                                >
                                    <a
                                        className="page-link"
                                        href="#"
                                        onClick={() => handlePaginationClick(idx)}
                                    >
                                        {idx + 1}
                                    </a>
                                </li>
                            ))}
                            <li
                                className={`page-item ${page === totalPages - 1 ? "disabled" : ""
                                    }`}
                            >
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => handlePaginationClick(page + 1)}
                                    aria-disabled={page === totalPages - 1}
                                >
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav> */}
                    <nav aria-label="Page navigation example" className="mt-5">
                        <ul className="pagination justify-content-end">
                            {/* Previous Button */}
                            <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
                                <a
                                    className={`page-link ${page !== 0 ? "text-success" : ""}`}
                                    href="#"
                                    onClick={() => handlePaginationClick(page - 1)}
                                    aria-disabled={page === 0}
                                >
                                    Previous
                                </a>
                            </li>

                            {/* Page Numbers */}
                            {Array.from({ length: totalPages }, (_, idx) => (
                                <li
                                    key={idx}
                                    className={`page-item ${idx === page ? "active" : ""}`}
                                >
                                    <a
                                        className={`page-link ${idx === page ? "bg-success text-white" : "text-success"}`}
                                        href="#"
                                        onClick={() => handlePaginationClick(idx)}
                                    >
                                        {idx + 1}
                                    </a>
                                </li>
                            ))}

                            {/* Next Button */}
                            <li className={`page-item ${page === totalPages - 1 ? "disabled" : ""}`}>
                                <a
                                    className={`page-link ${page !== totalPages - 1 ? "text-success" : ""}`}
                                    href="#"
                                    onClick={() => handlePaginationClick(page + 1)}
                                    aria-disabled={page === totalPages - 1}
                                >
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>

                </Col>
            </Row>
        </Container>
    );
};

export default Advance;
