// import { Provider } from "react-redux";
// import { useRoutes } from "react-router-dom";
// // MUI DATE PICKER
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// // ALL CONTEXT FILES
// // import { AuthProvider } from "app/contexts/FirebaseAuthContext";
// import { AuthProvider } from "app/contexts/JWTAuthContext";
// import { SettingsProvider } from "app/contexts/SettingsContext";
// // THEME PROVIDER
// import { MatxTheme } from "./components/MatxTheme";
// // REDUX STORE
// import { store, persistor } from "./redux/store";
// import { PersistGate } from "redux-persist/integration/react";
// // ALL ROUTES
// import routes from "./routes";
// // FAKE DATABASE
// import "../fake-db";
// import 'bootstrap/dist/css/bootstrap.min.css';

// export default function App() {
//   const content = useRoutes(routes);

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <SettingsProvider>
//             <MatxTheme>
//               <AuthProvider>{content}</AuthProvider>
//             </MatxTheme>
//           </SettingsProvider>
//         </PersistGate>
//       </Provider>
//     </LocalizationProvider>
//   );
// }





// src/app/App.js
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

// Import context providers
import { LanguageProvider } from "./contexts/LanguageContext"; // Import the language context provider
import { AuthProvider } from "app/contexts/JWTAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";

// Import other dependencies like MatxTheme, Redux store, etc.
import { MatxTheme } from "./components/MatxTheme";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

// Import Routes
import routes from "./routes";

export default function App() {
  const content = useRoutes(routes);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LanguageProvider> {/* Wrap with LanguageProvider */}
            <SettingsProvider>
              <MatxTheme>
                <AuthProvider>{content}</AuthProvider>
              </MatxTheme>
            </SettingsProvider>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
}
