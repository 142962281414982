

import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image } from "react-bootstrap";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/Volunteer.css";
import axios from 'axios';
import Avatars from './Avatars'
function Volunteer() {
    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>

                            <Avatars />
                            <br />

                            <Card className="m-3 p-3">
                                <Card.Body>
                                    {/* First Row */}
                                    <Row className="mb-4 volunteertext">
                                        <p className="mb-2 volunteertext">Join Voluntary</p>
                                        <Form.Check type="checkbox" label="Yes, The member willing to join voluntary." className="ms-2" />
                                    </Row>

                                    {/* Second Row */}
                                    <Row className="mb-4 volunteertext">
                                        <p className="mb-2 volunteertext">Interest Area in Voluntary</p>
                                        <Col xs="auto">
                                            <Form.Check inline type="checkbox" label="Education" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Community" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Environmental" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Health" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Animal" />
                                        </Col>
                                    </Row>

                                    {/* Third Row */}
                                    <Row className="mb-4 volunteertext">
                                        <p className="mb-2">Period Most Likely to Join Voluntary Activity: Time of Day</p>
                                        <Col xs="auto">
                                            <Form.Check inline type="checkbox" label="Morning" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Afternoon" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Evening" />
                                        </Col>
                                    </Row>

                                    {/* Fourth Row */}
                                    <Row className="mb-4 volunteertext">
                                        <p className="mb-2">Period Most Likely to Join Voluntary Activity: Days of the Week</p>
                                        <Col xs="auto">
                                            <Form.Check inline type="checkbox" label="Monday" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Tuesday" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Wednesday" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Thursday" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Friday" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Saturday" />
                                            <Form.Check inline type="checkbox" label="Sunday" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Holidays" />

                                        </Col>
                                    </Row>

                                    {/* Fifth Row */}
                                    <Row className="mb-4 volunteertext">
                                        <p className="mb-2">Receive Promotion</p>
                                        <Col xs="auto">
                                            <Form.Check inline type="checkbox" label="Post" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Email" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="Mobile App" />
                                            <Form.Check inline type="checkbox" className="ms-4" label="No" />
                                        </Col>
                                    </Row>

                                    {/* Last Row: Buttons */}
                                    <Row className="mt-4">
                                        <Col className="d-flex justify-content-start">
                                            <Button variant="primary" className="me-2 volunteersave">
                                                Save Changes
                                            </Button>
                                            <Button variant="secondary" className="volunteercansle">Cancel</Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Volunteer;
