

import React, { useState, useEffect } from "react";
import { Button, Table, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "app/components/Breadcrumb";
import './notificationcss/Notification.css';
import axios from 'axios';

const Notification = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [filters, setFilters] = useState({
    title: "",
    status: "",
    startDate: "",
    endDate: "",
    approvedBy: ""
  });

  const fetchNotifications = async () => {
    try {
      const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-notifications");
      console.log("[GET_NOTIFICATIONS]: Notifications Fetched Successfully", response.data);

      // Parse the body string into JSON
      const notifications = JSON.parse(response.data.body);

      setNotifications(notifications);
      setFilteredNotifications(notifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleCreateNotification = () => {
    navigate("/pushnotification/createnotification");
  };

  const handleSearch = () => {
    const filtered = notifications.filter(notification => {
      const isTitleMatch = filters.title ? notification.titleChinese.includes(filters.title) : true;
      const isStatusMatch = filters.status ? notification.status === filters.status : true;
      const isDateMatch = filters.startDate || filters.endDate
        ? new Date(notification.sendDate) >= new Date(filters.startDate) && new Date(notification.sendDate) <= new Date(filters.endDate)
        : true;
      const isApprovedByMatch = filters.approvedBy ? notification.approvedBy === filters.approvedBy : true;

      return isTitleMatch && isStatusMatch && isDateMatch && isApprovedByMatch;
    });
    setFilteredNotifications(filtered);
    setPage(0); // Reset to first page after search
  };

  const handleReset = () => {
    setFilteredNotifications(notifications);
    setFilters({
      title: "",
      status: "",
      startDate: "",
      endDate: "",
      approvedBy: ""
    });
    setPage(0);
  };

  const handlePageClick = (pageIndex) => {
    setPage(pageIndex);
  };

  const currentNotifications = filteredNotifications.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const totalPages = Math.ceil(filteredNotifications.length / rowsPerPage);

  return (
    <div className="container-fluid mt-4">
      <div className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Push Notifications" }]} />
      </div>
      <Form className="p-3 m-3 border rounded shadow-custom">
        <Row>
          <Col>
            <div className="d-flex flex-wrap">
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Title :</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className="notificationinput"
                  value={filters.title}
                  onChange={(e) => setFilters({ ...filters, title: e.target.value })}
                />
              </div>
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Status:</Form.Label>
                <Form.Group controlId="status" className="d-flex align-items-center w-100 notificationinput">
                  <Form.Select
                    style={{ width: '100%' }}
                    value={filters.status}
                    onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                  >
                    <option value=""></option>
                    <option>Draft</option>
                    <option>Delivered</option>
                    <option>Cancelled</option>
                    <option>Scheduled</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}> Date :</Form.Label>
                <Form.Control
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                />
              </div>
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}> to :</Form.Label>
                <Form.Control
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                />
              </div>
              <div className="d-flex flex-grow-1 align-items-center">
                <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Approved by:</Form.Label>
                <Form.Group controlId="approvedBy" className="d-flex align-items-center w-100">
                  <Form.Select
                    style={{ width: '100%' }}
                    value={filters.approvedBy}
                    onChange={(e) => setFilters({ ...filters, approvedBy: e.target.value })}
                  >
                    <option value=""></option>
                    <option>Amy Lee</option>
                    <option>Ling</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="success" className="custom-success-btn me-2" onClick={handleSearch}>Search</Button>
            <Button variant="success" className="custom-success-btn me-2" onClick={handleReset}>Reset</Button>
          </Col>
          <Col className='text-end'>
            <Button onClick={handleCreateNotification} variant="success" className="custom-success-btn me-2" style={{ width: '178px' }}>Create New Message</Button>
          </Col>
        </Row>
      </Form>

      <div className="table-responsive">
        <Table striped hover className="ms-3 mt-5 me-5">
          <thead>
            <tr>
              <th className="custom-th">Create Date</th>
              <th className="custom-th">Title</th>
              <th className="custom-th">Status</th>
              <th className="custom-th">Push Date</th>
              <th className="custom-th">Edit By</th>
              <th className="custom-th">Approved by</th>
              <th className="custom-th">Last Update/Approve</th>
              <th className="custom-th">Function(s)</th>
            </tr>
          </thead>
          <tbody>
            {currentNotifications.length > 0 ? (
              currentNotifications.map((notification, index) => (
                <tr key={index}>
                  <td className="custom-td">{notification.sendDate}</td>
                  <td className="custom-td">{notification.titleChinese}</td>
                  <td className="custom-td">{notification.status}</td>
                  <td className="custom-td">{notification.sendDate}</td>
                  <td className="custom-td">{notification.editBy}</td>
                  <td className="custom-td">{notification.approvedBy}</td>
                  <td className="custom-td">{notification.lastUpdateApprove}</td>
                  <td>
                    <Button variant="success" className="me-2 customnotificationbtn">Copy and create new message</Button>
                    <Button variant="success" className="me-2 customnotificationbtn">Details</Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">No notifications available</td>
              </tr>
            )}
          </tbody>
        </Table>

      </div>
      {/* <nav aria-label="Page navigation example" className="mt-5">
        <ul className="pagination justify-content-end">
          <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => handlePageClick(page - 1)}
              disabled={page === 0}
            >
              Previous
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li className={`page-item ${index === page ? 'active' : ''}`} key={index}>
              <button className="page-link" onClick={() => handlePageClick(index)}>{index + 1}</button>
            </li>
          ))}
          <li className={`page-item ${page >= totalPages - 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => handlePageClick(page + 1)}
              disabled={page >= totalPages - 1}
            >
              Next
            </button>
          </li>
        </ul>
      </nav> */}

      <nav aria-label="Page navigation example" className="mt-5">
        <ul className="pagination justify-content-end">
          {/* Previous Button */}
          <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
            <button
              className={`page-link ${page !== 0 ? 'text-success' : ''}`}
              onClick={() => handlePageClick(page - 1)}
              disabled={page === 0}
            >
              Previous
            </button>
          </li>

          {/* Page Numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <li
              className={`page-item ${index === page ? 'active' : ''}`}
              key={index}
            >
              <button
                className={`page-link ${index === page ? 'bg-success text-white' : 'text-success'}`}
                onClick={() => handlePageClick(index)}
              >
                {index + 1}
              </button>
            </li>
          ))}

          {/* Next Button */}
          <li className={`page-item ${page >= totalPages - 1 ? 'disabled' : ''}`}>
            <button
              className={`page-link ${page < totalPages - 1 ? 'text-success' : ''}`}
              onClick={() => handlePageClick(page + 1)}
              disabled={page >= totalPages - 1}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>

    </div>
  );
};

export default Notification;
