
import React, { useState } from 'react';
import { Container, Row, Col, Form, Card, Button, CardBody, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import './campaigncss/Datepage.css';
import { IoNotificationsOutline } from 'react-icons/io5';
function Datepage() {
    const [formData, setFormData] = useState({
        promotionStartDate: "",
        promotionEndDate: "",
        preRegStartDate: "",
        preRegEndDate: "",
        regStartDate: "",
        regEndDate: "",
        campaignStartDate: "",
        campaignEndDate: "",
    });

    const [errors, setErrors] = useState({});
    const [rowVisibility, setRowVisibility] = useState({
        showPreRegRow: false,
        showRegRow: false,
        showCampaignRow: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validate field immediately
        validateField(name, value);
    };

    const validateField = (fieldName, value) => {
        const newErrors = { ...errors };

        const toDate = (date) => (date ? new Date(date) : null);

        // Extract dates
        const pStart = toDate(formData.promotionStartDate);
        const pEnd = fieldName === "promotionEndDate" ? toDate(value) : toDate(formData.promotionEndDate);
        const preStart = fieldName === "preRegStartDate" ? toDate(value) : toDate(formData.preRegStartDate);
        const preEnd = fieldName === "preRegEndDate" ? toDate(value) : toDate(formData.preRegEndDate);
        const regStart = fieldName === "regStartDate" ? toDate(value) : toDate(formData.regStartDate);
        const regEnd = fieldName === "regEndDate" ? toDate(value) : toDate(formData.regEndDate);
        const cStart = fieldName === "campaignStartDate" ? toDate(value) : toDate(formData.campaignStartDate);
        const cEnd = fieldName === "campaignEndDate" ? toDate(value) : toDate(formData.campaignEndDate);

        // Validation rules
        if (fieldName === "promotionEndDate" && pStart && pEnd && pEnd < pStart) {
            newErrors.promotionEndDate = "Invalid End Date.";
        } else if (fieldName === "promotionEndDate") {
            delete newErrors.promotionEndDate;
            setRowVisibility({ ...rowVisibility, showPreRegRow: true });
        }

        if (fieldName === "preRegStartDate" && pStart && preStart && preStart < pStart) {
            newErrors.preRegStartDate = "Invalid Pre-registration Start Date.";
        } else if (fieldName === "preRegStartDate") {
            delete newErrors.preRegStartDate;
        }

        if (fieldName === "preRegEndDate" && preStart && preEnd && preEnd <= preStart) {
            newErrors.preRegEndDate = "Invalid Pre-registration End Date.";
        } else if (fieldName === "preRegEndDate" && pEnd && preEnd && preEnd > pEnd) {
            newErrors.preRegEndDate = "Invalid Pre-registration End Date.";
        } else if (fieldName === "preRegEndDate") {
            delete newErrors.preRegEndDate;
            setRowVisibility({ ...rowVisibility, showRegRow: true });
        }

        if (fieldName === "regStartDate" && preEnd && regStart && regStart <= preEnd) {
            newErrors.regStartDate = "Invalid Registration Start Date.";
        } else if (fieldName === "regStartDate") {
            delete newErrors.regStartDate;
        }

        if (fieldName === "regEndDate" && regStart && regEnd && regEnd <= regStart) {
            newErrors.regEndDate = "Invalid Registration End Date.";
        } else if (fieldName === "regEndDate") {
            delete newErrors.regEndDate;
            setRowVisibility({ ...rowVisibility, showCampaignRow: true });
        }

        if (fieldName === "campaignStartDate" && regEnd && cStart && cStart <= regEnd) {
            newErrors.campaignStartDate = "Invalid Campaign Start Date.";
        } else if (fieldName === "campaignStartDate") {
            delete newErrors.campaignStartDate;
        }

        if (fieldName === "campaignEndDate" && cStart && cEnd && cEnd <= cStart) {
            newErrors.campaignEndDate = "Invalid Campaign End Date.";
        } else if (fieldName === "campaignEndDate") {
            delete newErrors.campaignEndDate;
        }

        setErrors(newErrors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(errors).length === 0) {
            alert("Form submitted successfully!");
        }
    };

    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);

    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>

                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">Date</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
                                    <Button variant="primary" className="basicsavebtn me-3">Save</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <CardBody className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">Status: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Promotion Start and End */}
                            <Row>
                                <Col md={5}>
                                    <Form.Group controlId="promotionStartDate" className="floating-label-group">
                                        <Form.Control
                                            className="floating-input"
                                            type="date"
                                            name="promotionStartDate"
                                            value={formData.promotionStartDate}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <Form.Label className="floating-label">Promotion Start Date</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="promotionEndDate" className="floating-label-group">
                                        <Form.Control
                                            className={`floating-input ${errors.promotionEndDate ? "is-invalid" : ""}`}
                                            type="date"
                                            name="promotionEndDate"
                                            value={formData.promotionEndDate}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <Form.Label className="floating-label">Promotion End Date</Form.Label>
                                        {errors.promotionEndDate && (
                                            <div className="invalid-feedback">{errors.promotionEndDate}</div>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Pre-registration Start and End */}
                            {rowVisibility.showPreRegRow && (
                                <Row>
                                    <Col md={5}>
                                        <Form.Group controlId="preRegStartDate" className="floating-label-group">
                                            <Form.Control
                                                className={`floating-input ${errors.preRegStartDate ? "is-invalid" : ""}`}
                                                type="date"
                                                name="preRegStartDate"
                                                value={formData.preRegStartDate}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label className="floating-label">Pre-registration Start Date</Form.Label>
                                            {errors.preRegStartDate && (
                                                <div className="invalid-feedback">{errors.preRegStartDate}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group controlId="preRegEndDate" className="floating-label-group">
                                            <Form.Control
                                                className={`floating-input ${errors.preRegEndDate ? "is-invalid" : ""}`}
                                                type="date"
                                                name="preRegEndDate"
                                                value={formData.preRegEndDate}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label className="floating-label">Pre-registration End Date</Form.Label>
                                            {errors.preRegEndDate && (
                                                <div className="invalid-feedback">{errors.preRegEndDate}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                            {/* Registration Start and End */}
                            {rowVisibility.showRegRow && (
                                <Row>
                                    <Col md={5}>
                                        <Form.Group controlId="regStartDate" className="floating-label-group">
                                            <Form.Control
                                                className={`floating-input ${errors.regStartDate ? "is-invalid" : ""}`}
                                                type="date"
                                                name="regStartDate"
                                                value={formData.regStartDate}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label className="floating-label">Registration Start Date</Form.Label>
                                            {errors.regStartDate && (
                                                <div className="invalid-feedback">{errors.regStartDate}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group controlId="regEndDate" className="floating-label-group">
                                            <Form.Control
                                                className={`floating-input ${errors.regEndDate ? "is-invalid" : ""}`}
                                                type="date"
                                                name="regEndDate"
                                                value={formData.regEndDate}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label className="floating-label">Registration End Date</Form.Label>
                                            {errors.regEndDate && (
                                                <div className="invalid-feedback">{errors.regEndDate}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                            {/* Campaign Start and End */}
                            {rowVisibility.showCampaignRow && (
                                <Row>
                                    <Col md={5}>
                                        <Form.Group controlId="campaignStartDate" className="floating-label-group">
                                            <Form.Control
                                                className={`floating-input ${errors.campaignStartDate ? "is-invalid" : ""}`}
                                                type="date"
                                                name="campaignStartDate"
                                                value={formData.campaignStartDate}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label className="floating-label">Campaign Start Date</Form.Label>
                                            {errors.campaignStartDate && (
                                                <div className="invalid-feedback">{errors.campaignStartDate}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group controlId="campaignEndDate" className="floating-label-group">
                                            <Form.Control
                                                className={`floating-input ${errors.campaignEndDate ? "is-invalid" : ""}`}
                                                type="date"
                                                name="campaignEndDate"
                                                value={formData.campaignEndDate}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label className="floating-label">Campaign End Date</Form.Label>
                                            {errors.campaignEndDate && (
                                                <div className="invalid-feedback">{errors.campaignEndDate}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                            {rowVisibility.showCampaignRow && (
                                <Row>
                                    <Col md={5}>
                                        <Form.Group controlId="code" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                name="code"
                                                className="floating-input"
                                                value="逢星期五" // Holding value for code
                                            />
                                            <Form.Label className="floating-label">Recurrence (Chinese)</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group controlId="code" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                name="code"
                                                className="floating-input"
                                                value="every Friday" // Holding value for code
                                            />
                                            <Form.Label className="floating-label">Recurrence (English)</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                            {/* <Row className="mt-4">
                                <Button
                                    type="submit"
                                    style={{ backgroundColor: "#1976D2", color: "white", border: "none" }}
                                    className="custom-success-btn"
                                >
                                    Submit
                                </Button>
                            </Row> */}

                        </Form>
                    </Card.Body>
                </Card>
            </Row>

            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            Assign to
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default Datepage;
