


import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';
import { Box } from "@mui/material";
import Breadcrumb from "app/components/Breadcrumb";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './notificationcss/NotificationForm.css'
import { XCircle } from 'react-bootstrap-icons';
import axios from 'axios';  // Import axios for API calls

const NotificationForm = () => {
  const [schedule, setSchedule] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    titleChinese: '',
    titleEnglish: '',
    subtitleChinese: '',
    subtitleEnglish: '',
    messageChinese: '',
    messageEnglish: '',
    sendDate: null,
    audienceType: '',
    membershipLevel: '',
    membershipRole: '',
    ageGroup: '',
    membershipType: ''
  });

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleShowDate = () => setShow(true);
  const handleCloseDate = () => setShow(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const response = await axios.post('  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-notification', {
        ...formData,
        sendDate: startDate
      });

      // Check if the response is successful
      if (response.status === 200) {
        alert("Notification details added successfully.");
        // Clear form or redirect if necessary
        setFormData({
          titleChinese: '',
          titleEnglish: '',
          subtitleChinese: '',
          subtitleEnglish: '',
          messageChinese: '',
          messageEnglish: '',
          sendDate: null,
          audienceType: '',
          membershipLevel: '',
          membershipRole: '',
          ageGroup: '',
          membershipType: ''
        }); // Clear form data if you need to reset the form
        // Optionally, you can redirect or perform other actions here
      }
    } catch (err) {
      console.error("Error submitting form data", err);
      alert("Failed to add notification details.");
    }
  };



  const initialTags = ['Senior', 'Pre-Senior', 'Volunteer', 'Caregiver', 'Instructor'];

  // State for visible tags
  const [visibleTags, setVisibleTags] = useState(initialTags);

  // Load tags from localStorage on component mount
  useEffect(() => {
    const storedTags = JSON.parse(localStorage.getItem('visibleTags'));
    if (storedTags) {
      setVisibleTags(storedTags);
    }
  }, []);

  // Handle removing a tag
  const removeTag = (tag) => {
    const updatedTags = visibleTags.filter((t) => t !== tag);
    setVisibleTags(updatedTags);
    localStorage.setItem('visibleTags', JSON.stringify(updatedTags)); // Update localStorage
  };

  // Automatically restore all tags on page refresh
  useEffect(() => {
    localStorage.setItem('visibleTags', JSON.stringify(initialTags));
  }, []);










  return (
    <div className="container mt-4">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Search" }, { name: "Push Notifications" }]} />
      </Box>

      <Form onSubmit={handleSubmit}>

        <Row className="mb-4">
          <Col className="d-flex justify-content-center">
            <Button variant="primary" onClick={handleShow} className='addphotobtn'>
              Add Photo/Video
            </Button>
          </Col>
        </Row>
        {/* Titles and Subtitles Section */}
        <Row className="mb-3 ms-4">
          <Col md={6}>
            <Form.Group controlId="titleChinese" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="titleChinese"
                value={formData.titleChinese}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Title (Chinese)</Form.Label>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="titleEnglish" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="titleEnglish"
                value={formData.titleEnglish}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Title (English)</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3 ms-4">
          <Col md={6}>
            <Form.Group controlId="subtitleChinese" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="subtitleChinese"
                value={formData.subtitleChinese}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Subtitle (Chinese)</Form.Label>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="subtitleEnglish" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="subtitleEnglish"
                value={formData.subtitleEnglish}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Subtitle (English)</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        {/* Message Content Section */}
        <Row className="mb-4 ms-4">
          <Col md={6}>
            <Form.Group controlId="messageChinese" className="floating-label-group">
              <Form.Control
                as="textarea"
                rows={3}
                className="floating-input"
                name="messageChinese"
                value={formData.messageChinese}
                onChange={handleInputChange}
                style={{ height: '100px' }}
                required  // Required field
              />
              <Form.Label className="floating-label">Message Content (Chinese)</Form.Label>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="messageEnglish" className="floating-label-group">
              <Form.Control
                as="textarea"
                rows={3}
                className="floating-input"
                name="messageEnglish"
                value={formData.messageEnglish}
                onChange={handleInputChange}
                style={{ height: '100px' }}
                required  // Required field
              />
              <Form.Label className="floating-label">Message Content (English)</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        {/* Send Date & Time Section */}
        <Row className="mb-5 ms-4">
          <Col md={5}>
            <h5 className='notificationdatetime'>Send Date & Time</h5>
            <Row className="mt-4 align-items-center">
              <Col md="auto">
                <Form.Check
                  type="radio"
                  label="Preview"
                  name="sendTimeOptions"
                  className="mb-0 previewcheck"
                  onClick={handleShowDate}
                  required  // Required field
                />
              </Col>
              <Col md={4} className='ms-4'>
                <Form.Control
                  type="text"
                  placeholder="Login Name"
                  onClick={handleShowDate}
                  className='datetimelogin'
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}  // Update startDate
                  required  // Required field
                />
              </Col>
              <Col md="auto">
                <Button variant="" className="ms-5 testbtn" >
                  Test
                </Button>
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md="auto">
                <Form.Check
                  type="radio"
                  label="Schedule at"
                  name="sendTimeOptions"
                  className="mb-0 previewcheck"
                  onClick={handleShowDate}
                  required  // Required field
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="date"
                  placeholder="Select Date"
                  onClick={handleShowDate}
                  className='datetimelogin'
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}  // Update startDate
                  required  // Required field
                />
              </Col>
              <Col md="auto" className='ms-5'>
                <Form.Check
                  type="radio"
                  label="Now"
                  name="sendTimeOptions"
                  className="mb-0 ms-5 previewcheck"
                  onClick={() => setStartDate(new Date())}
                  required  // Required field
                />
              </Col>
            </Row>
          </Col>
        </Row>

        


        <Row className="mb-3 ms-4">
          <Col md={5}>
            <h5 className="targethead">Target Audience</h5>
            <Form.Check
              type="radio"
              label="All App Users"
              name="audienceOptions"
              className="mb-2 mt-4 previewcheck"
              value="All"
              onChange={handleInputChange}
              name="audienceType"
              required
            />
            <Form.Check
              type="radio"
              label="Paid User Only"
              name="audienceOptions"
              className="mb-2 previewcheck"
              value="Specific"
              onChange={handleInputChange}
              name="audienceType"
              required
            />
            <Form.Check
              type="radio"
              label="Guest Only"
              name="audienceOptions"
              className="mb-2 previewcheck"
              value="Specific"
              onChange={handleInputChange}
              name="audienceType"
              required
            />

            {/* Tags Display */}
            <div className="tags-container d-flex flex-wrap gap-3 mt-3 ms-2">
              {visibleTags.map((tag) => (
                <span key={tag} className="tag px-3 py-1 rounded-pill border border-primary">
                  {tag}{' '}
                  <button
                    className="remove-btn ms-2 btn btn-sm btn-link text-primary p-0"
                    onClick={() => removeTag(tag)}
                  >
                    x
                  </button>
                </span>
              ))}
            </div>
            <style jsx>{`
  .tag {
    background-color: #e0f7ff;
    color: #007bff;
    display: inline-flex;
    align-items: center;
  }
  .remove-btn {
    background: none;
    border: none;
    color: #007bff; /* Blue color for the cross button */
    cursor: pointer;
    font-size: 14px;
  }
  .remove-btn:hover {
    color: #0056b3; /* Darker blue on hover for better feedback */
  }
`}</style>

           

          </Col>
        </Row>



        <Row className="mb-3 ms-4 mt-4">
          <Col md={4} className="d-flex ">
            <Button variant="" type="submit" className='m submitbutton notificationsave'>Save</Button>
            <Button variant="" className='ms-3 notificationdelete'>Delete</Button>
            <Button variant="" className='ms-3 notificationconf'>Confirm</Button>
            <Button variant="" className='ms-3 ps-2 notificationdelete'>Withdraw</Button>
          </Col>
        </Row>



      </Form>




      <Modal show={showModal} onHide={handleClose} centered size="lg" className="custom-modal">
        <Modal.Body style={{ height: '360px' }}>
          <h4 className="maintitle">Add Instructor Photo</h4>
          <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
            <p>Ratio limit 79:82,format PNG/JPG, e.g. 395(W) * 410(H)</p>
            <Form.Group controlId="formFile">
              <input
                type="file"
                id="file-input"
                accept="image/*,video/*"

                style={{ display: 'none' }}
              />
              <Button onClick={() => document.getElementById('file-input').click()}>Select</Button>
            </Form.Group>
          </div>

          {/* Preview Image or Video */}
          {previewUrl && (
            <div style={{ textAlign: 'left', marginTop: '20px' }}>
              {selectedFile.type.startsWith('image/') ? (
                <img src={previewUrl} alt="Preview" style={{ width: '375px', height: '221px' }} />
              ) : (
                <video width="375" height="221" controls>
                  <source src={previewUrl} />
                </video>
              )}
              <XCircle
                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                size={24}

              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose} disabled={!selectedFile}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default NotificationForm;

