

// import React, { useState } from "react";
// import { Container, Row, Col, Card, Button, Form, Image, Table } from "react-bootstrap";
// import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./membercss/MemberInfo.css";
// import axios from 'axios';
// import Avatars from './Avatars'
// function Campaignengagement() {

//     const [activeTab, setActiveTab] = useState("In-Progress");

//     const handleTabClick = (tab) => {
//         setActiveTab(tab);
//     };
//     return (
//         <Container fluid>
//             <Row>
//                 <Col md={12}>
//                     <Card className="mt-0 border-0" style={{ position: "relative" }}>
//                         <Card.Body>

//                             <Avatars />
//                             <br />

//                             <Card className="m-3 ">
//                                 <Card.Body>
//                                     <Row className="">
//                                         <h5 className="basicinfo ">Campaign Details</h5>
//                                     </Row>
//                                     <Row className="mt-3">
//                                         <Col xs={12} md={7} className="d-flex justify-content-between">
//                                             {["In-Progress", "Completed", "Application Status"].map((tab, index) => (
//                                                 <div
//                                                     key={index}
//                                                     className={`tab-link ${activeTab === tab ? "active" : ""}`}
//                                                     onClick={() => handleTabClick(tab)}
//                                                     style={{
//                                                         cursor: "pointer",
//                                                         fontWeight: activeTab === tab ? "bold" : "normal",
//                                                         color: activeTab === tab ? "blue" : "black",
//                                                         paddingBottom: "5px",
//                                                         borderBottom: activeTab === tab ? "2px solid blue" : "none",
//                                                     }}
//                                                 >
//                                                     {tab}
//                                                 </div>
//                                             ))}
//                                         </Col>
//                                     </Row>

//                                     <Row className="in-proogress">
//                                         <div className="table-responsive">
//                                             <Table striped hover className="mt-3">
//                                                 <thead>
//                                                     <tr>
//                                                         <th className="custom-th">Campaign Name</th>
//                                                         <th className="custom-th">Type</th>
//                                                         <th className="custom-th">Code</th>
//                                                         <th className="custom-th">Start Date</th>
//                                                         <th className="custom-th">End Date</th>
//                                                         <th className="custom-th">Every</th>
//                                                         <th className="custom-th">Total Classes</th>
//                                                         <th className="custom-th">Attendance</th>
//                                                         <th className="custom-th">Absence</th>
//                                                         <th className="custom-th">Reason(s)</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>

//                                                     <tr >
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>

//                                                     </tr>


//                                                 </tbody>
//                                             </Table>

//                                         </div>
//                                     </Row>

//                                     <Row className="complete">
//                                         <div className="table-responsive">
//                                             <Table striped hover className="mt-3">
//                                                 <thead>
//                                                     <tr>
//                                                         <th className="custom-th">Campaign Name</th>
//                                                         <th className="custom-th">Type</th>
//                                                         <th className="custom-th">Code</th>
//                                                         <th className="custom-th">Start Date</th>
//                                                         <th className="custom-th">End Date</th>
//                                                         <th className="custom-th">Every</th>
//                                                         <th className="custom-th">Function(s)</th>

//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>

//                                                     <tr >
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>

//                                                     </tr>


//                                                 </tbody>
//                                             </Table>

//                                         </div>
//                                     </Row>


//                                     <Row className="application-status">
//                                         <div className="table-responsive">
//                                             <Table striped hover className="mt-3">
//                                                 <thead>
//                                                     <tr>
//                                                         <th className="custom-th">Campaign Name</th>
//                                                         <th className="custom-th">Method</th>
//                                                         <th className="custom-th">Type</th>
//                                                         <th className="custom-th">Code</th>
//                                                         <th className="custom-th">Start Date</th>
//                                                         <th className="custom-th">End Date</th>
//                                                         <th className="custom-th">Every</th>
//                                                         <th className="custom-th">Status</th>

//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>

//                                                     <tr >
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>
//                                                         <td className="custom-td"></td>


//                                                     </tr>


//                                                 </tbody>
//                                             </Table>

//                                         </div>
//                                     </Row>
//                                 </Card.Body>
//                             </Card>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default Campaignengagement;


//---------------------------------------------------------------------------------------------------------------------------------



import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/Campaignments.css";
import Avatars from './Avatars';

function Campaignengagement() {
    const [activeTab, setActiveTab] = useState("In-Progress");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const counts = {
        "In-Progress": 1,
        Completed: 3,
        "Application Status": 2,
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                            <Avatars />
                            <br />
                            <Card className="m-3">
                                <Card.Body>
                                    <Row>
                                        <h5 className="basicinfo">Campaign Details</h5>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} md={7} className="d-flex justify-content-between">
                                            {["In-Progress", "Completed", "Application Status"].map((tab, index) => (
                                                <div
                                                    key={index}
                                                    className={`tab-link ${activeTab === tab ? "active" : ""}`}
                                                    onClick={() => handleTabClick(tab)}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: activeTab === tab ? "bold" : "normal",
                                                        color: activeTab === tab ? "blue" : "black",
                                                        paddingBottom: "5px",
                                                        borderBottom: activeTab === tab ? "2px solid blue" : "none",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span>{tab}</span>
                                                    <br />
                                                    <small style={{ color: "#6c757d", fontSize: "12px" }}>
                                                        {counts[tab]} {counts[tab] === 1 ? "" : ""}
                                                    </small>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>

                                    {/* Conditional Rendering for Tables */}
                                    {activeTab === "In-Progress" && (
                                        <Row className="in-proogress">
                                            <div className="table-responsive">
                                                <Table striped hover className="mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th className="custom-th">Campaign Name</th>
                                                            <th className="custom-th">Type</th>
                                                            <th className="custom-th">Code</th>
                                                            <th className="custom-th">Start Date</th>
                                                            <th className="custom-th">End Date</th>
                                                            <th className="custom-th">Every</th>
                                                            <th className="custom-th">Total Classes</th>
                                                            <th className="custom-th">Attendance</th>
                                                            <th className="custom-th">Absence</th>
                                                            <th className="custom-th">Reason(s)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="custom-td">Painting Class</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">643250</td>
                                                            <td className="custom-td"> 02/12/2024</td>
                                                            <td className="custom-td">03/04/2024</td>
                                                            <td className="custom-td">Tue</td>
                                                            <td className="custom-td">2</td>
                                                            <td className="custom-td">50%</td>
                                                            <td className="custom-td">1</td>
                                                            <td className="custom-td">Sick</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                    )}

                                    {activeTab === "Completed" && (
                                        <Row className="complete">
                                            <div className="table-responsive">
                                                <Table striped hover className="mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th className="custom-th">Campaign Name</th>
                                                            <th className="custom-th">Type</th>
                                                            <th className="custom-th">Code</th>
                                                            <th className="custom-th">Start Date</th>
                                                            <th className="custom-th">End Date</th>
                                                            <th className="custom-th">Every</th>
                                                            <th className="custom-th">Function(s)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="custom-td">Painting Class</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">643250</td>
                                                            <td className="custom-td">2/12/2024</td>
                                                            <td className="custom-td">03/04/2024</td>
                                                            <td className="custom-td">Tue</td>
                                                            <td className="custom-td">  <Button variant="primary" className="me-2 feedback">
                                                                View Feedback
                                                            </Button></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="custom-td">Painting Class</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">643250</td>
                                                            <td className="custom-td">12/02/2024</td>
                                                            <td className="custom-td">04/03/2024</td>
                                                            <td className="custom-td">Tue</td>
                                                            <td className="custom-td">  <Button variant="primary" className="me-2 feedback">
                                                                View Feedback
                                                            </Button></td>
                                                        </tr>


                                                        <tr>
                                                            <td className="custom-td">Painting Class</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">643250</td>
                                                            <td className="custom-td">12/02/2024</td>
                                                            <td className="custom-td">04/03/2024</td>
                                                            <td className="custom-td">Tue</td>
                                                            <td className="custom-td">  <Button variant="primary" className="me-2 feedback">
                                                                View Feedback
                                                            </Button></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                    )}

                                    {activeTab === "Application Status" && (
                                        <Row className="application-status">
                                            <div className="table-responsive">
                                                <Table striped hover className="mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th className="custom-th">Campaign Name</th>
                                                            <th className="custom-th">Method</th>
                                                            <th className="custom-th">Type</th>
                                                            <th className="custom-th">Code</th>
                                                            <th className="custom-th">Start Date</th>
                                                            <th className="custom-th">End Date</th>
                                                            <th className="custom-th">Every</th>
                                                            <th className="custom-th">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="custom-td">Singing Class</td>
                                                            <td className="custom-td">First Come First Serve</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">786574</td>
                                                            <td className="custom-td">06/19/2024</td>
                                                            <td className="custom-td">09/18/2024</td>
                                                            <td className="custom-td">Tue, Thur</td>
                                                            <td className="custom-td">Success</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="custom-td">Singing Class</td>
                                                            <td className="custom-td">First Come First Serve</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">786574</td>
                                                            <td className="custom-td">06/19/2024</td>
                                                            <td className="custom-td">09/18/2024</td>
                                                            <td className="custom-td">Tue, Thur</td>
                                                            <td className="custom-td">Pending Approval
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Campaignengagement;
