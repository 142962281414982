
import React, { useState, useEffect } from "react";
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { IoIosSearch } from "react-icons/io";
import "./membercss/Identity.css"
const Identity = () => {
    const navigate = useNavigate();
    const [members, setMembers] = useState([]);
    const [searchOption, setSearchOption] = useState("Name");
    const [searchInput, setSearchInput] = useState("");
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10);

    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        delete: false,
    });

    // Fetch permissions
    useEffect(() => {
        const fetchPermissions = async () => {
            const token = localStorage.getItem("authToken");
            if (!token) {
                console.error("No token found in localStorage");
                return;
            }

            try {
                const response = await axios.get(
                    "https://membershipuat.maxisense.io/webapp/memportal/getadmininfo",
                    {
                        headers: {
                            Token: token,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.data.code === 1) {
                    const permissionsList = response.data.data.permission_list || [];
                    setPermissions({
                        add: permissionsList.includes("account"),
                        edit: permissionsList.includes("account_u"),
                        delete: permissionsList.includes("account_d"),
                    });
                }
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };

        fetchPermissions();
    }, []);



    const handleEditMember = (member) => {
        navigate("/member/memberinfo", { state: { member } });
    };

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await axios.get(
                    "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/fetch-basic-info"
                );
                const data = JSON.parse(response.data.body);
                setMembers(data.basicInfo);
                setFilteredMembers(data.basicInfo);
            } catch (error) {
                console.error("Error fetching members:", error);
            }
        };
        fetchMembers();
    }, []);


    const handlePaginationClick = (index) => {
        setPage(index);
    };

    const currentMembers = filteredMembers.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage
    );

    const totalPages = Math.ceil(filteredMembers.length / rowsPerPage);



    const [showReset, setShowReset] = useState(false); // State to control Reset button visibility

    const handleSearch = () => {
        if (searchInput.trim()) {
            setShowReset(true); // Show Reset button if input is not empty
        }
    };

    const handleReset = () => {
        setSearchInput(""); // Clear the input
        setShowReset(false); // Hide Reset button
    };



    return (
        <Container fluid style={{ padding: "0" }}>


            <Row className="align-items-center mt-4 ps-4">
                {/* Search Box */}
                <Col xs={12} md={4}>
                    <div style={{ position: "relative" }}>
                        <IoIosSearch
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "10px",
                                transform: "translateY(-50%)",
                                color: "#6c757d", // Optional: Icon color
                            }}
                            size={26}
                        />
                        <Form.Control
                            type="text"
                            className="identitysearch"
                            style={{
                                paddingLeft: "40px",
                            }}
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)} // Update input state
                            required
                        />
                    </div>
                </Col>

                {/* Checkbox */}
                <Col xs={12} md={2} className="d-flex align-items-center">
                    <Form.Check
                        type="checkbox"
                        id="exampleCheck"
                        label="Exact Match"
                        className="ms-md-2 exactsearch"
                    />
                </Col>

                {/* Buttons */}
                <Col xs={12} md={3}>
                    <Button
                        className="custom-success-btn me-2"
                        onClick={handleSearch} // Handle Search button click
                    >
                        Search
                    </Button>
                    {showReset && (
                        <Button
                            className="identityreset"
                            onClick={handleReset} // Handle Reset button click
                        >
                            Reset
                        </Button>
                    )}
                </Col>
            </Row>


            <Row className="ps-4 mt-5">
                <Col xs={12}>
                    <h4 className="member-result">Result</h4>
                </Col>
            </Row>
            <hr />

            <Row className="ps-4 me-3">
                <Col xs={12} md={{ span: 12 }}>
                    <Table
                        striped
                        hover
                        responsive
                        className="centered-table"
                        style={{ margin: "0", padding: "0", width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th className="custom-th">Member Name</th>
                                <th className="custom-th">Joined Date</th>
                                <th className="custom-th">Mobile</th>
                                <th className="custom-th">Member ID</th>
                                <th className="custom-th">Card Ref.</th>
                                <th className="custom-th">Email</th>
                                <th className="custom-th">App 1st Login</th>
                                <th className="custom-th">Member Identity</th>
                                <th className="custom-th">Function(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentMembers.length > 0 ? (
                                currentMembers.map((member, index) => (
                                    <tr key={index}>
                                        <td className="custom-td">{member.name}</td>
                                        <td className="custom-td">{member.joinedDate}</td>
                                        <td className="custom-td">{member.phone}</td>
                                        <td className="custom-td">{member.memberId}</td>
                                        <td className="custom-td">{member.cardRef}</td>
                                        <td className="custom-td">{member.email}</td>
                                        <td className="custom-td">{member.firstLogin}</td>
                                        <td className="custom-td">Category</td>
                                        <td className="custom-td">
                                            {permissions.edit && (
                                                <Button
                                                    variant="success"
                                                    className="custom-success-btn me-2"
                                                    onClick={() => handleEditMember(member)}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9" className="text-center">
                                        No members found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {/* <nav aria-label="Page navigation example" className="mt-5">
                        <ul className="pagination justify-content-end">
                            <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => handlePaginationClick(page - 1)}
                                    aria-disabled={page === 0}
                                >
                                    Previous
                                </a>
                            </li>
                            {Array.from({ length: totalPages }, (_, idx) => (
                                <li
                                    key={idx}
                                    className={`page-item ${idx === page ? "active" : ""}`}
                                >
                                    <a
                                        className="page-link"
                                        href="#"
                                        onClick={() => handlePaginationClick(idx)}
                                    >
                                        {idx + 1}
                                    </a>
                                </li>
                            ))}
                            <li
                                className={`page-item ${page === totalPages - 1 ? "disabled" : ""
                                    }`}
                            >
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => handlePaginationClick(page + 1)}
                                    aria-disabled={page === totalPages - 1}
                                >
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav> */}

                    <nav aria-label="Page navigation example" className="mt-5">
                        <ul className="pagination justify-content-end">
                            {/* Previous Button */}
                            <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
                                <a
                                    className={`page-link ${page !== 0 ? "text-success" : ""}`}
                                    href="#"
                                    onClick={() => handlePaginationClick(page - 1)}
                                    aria-disabled={page === 0}
                                >
                                    Previous
                                </a>
                            </li>

                            {/* Page Numbers */}
                            {Array.from({ length: totalPages }, (_, idx) => (
                                <li key={idx} className={`page-item ${idx === page ? "active" : ""}`}>
                                    <a
                                        className={`page-link ${idx === page ? "bg-success text-white" : "text-success"}`}
                                        href="#"
                                        onClick={() => handlePaginationClick(idx)}
                                    >
                                        {idx + 1}
                                    </a>
                                </li>
                            ))}

                            {/* Next Button */}
                            <li className={`page-item ${page === totalPages - 1 ? "disabled" : ""}`}>
                                <a
                                    className={`page-link ${page !== totalPages - 1 ? "text-success" : ""}`}
                                    href="#"
                                    onClick={() => handlePaginationClick(page + 1)}
                                    aria-disabled={page === totalPages - 1}
                                >
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>

                </Col>
            </Row>
        </Container>
    );
};

export default Identity;
