


import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal, Form, Table, InputGroup, Card } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import './campaigncss/Enrollement.css'

function Enrollment() {
    const [showMemberModal, setShowMemberModal] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    // Initial member state
    const [newMember, setNewMember] = useState({
        memberId: '',
        memberName: '',
        mobile: '',
        status: 'approve',
        lastUpdated: ''
    });

    // Show modals for adding or editing
    const handleShowMemberModal = () => setShowMemberModal(true);
    const handleCloseMemberModal = () => {
        setShowMemberModal(false);
        setIsEditing(false);
        setNewMember({
            memberId: '',
            memberName: '',
            mobile: '',
            status: 'approve',
            lastUpdated: ''
        });
    };

    const handleShowGroupModal = () => setShowGroupModal(true);
    const handleCloseGroupModal = () => setShowGroupModal(false);

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMember({ ...newMember, [name]: value });
    };

    // Save new or updated member
    const handleSaveMember = () => {
        if (isEditing && editIndex !== null) {
            // Update existing member
            const updatedMembers = members.map((member, index) =>
                index === editIndex ? { ...newMember, lastUpdated: new Date().toLocaleString() } : member
            );
            setMembers(updatedMembers);
        } else {
            // Add new member
            setMembers([...members, { ...newMember, lastUpdated: new Date().toLocaleString() }]);
        }
        handleCloseMemberModal();
    };

    // Handle edit button click
    const handleEditMember = (index) => {
        const memberToEdit = members[index];
        setNewMember(memberToEdit);
        setIsEditing(true);
        setEditIndex(index);
        handleShowMemberModal();
    };

    return (
        <Container>




            <Row>
                <Card className="mt-0 border-0" style={{ position: 'relative' }}>
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className='enrollementhead'>Enrollment Status</h5>
                            <div className="d-flex">
                                <Button
                                    variant="success"
                                    className="custom-success-btn me-2"
                                    style={{ width: '160px' }}
                                    onClick={handleShowMemberModal}
                                >
                                    Add Member
                                </Button>
                                <Button
                                    variant="success"
                                    className="custom-success-btn"
                                    style={{ width: '160px' }}
                                    onClick={handleShowGroupModal}
                                >
                                    Add Group
                                </Button>
                            </div>
                        </div>


                        <Row>
                            <Col md={6} className="d-flex justify-content-between mt-5">
                                <p className='grouplabel'>Quota: 10</p>
                                <p className='grouplabel'>No. of applicants: 8</p>
                                <p className='grouplabel'>Quota usage: 80%</p>
                            </Col>
                        </Row>


                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th className='tableheadgrp'>Member ID</th>
                                    <th className='tableheadgrp'>Member Name</th>
                                    <th className='tableheadgrp'>Mobile</th>
                                    <th className='tableheadgrp'>Approver</th>
                                    <th className='tableheadgrp'>Status</th>
                                    <th className='tableheadgrp'>Last Updated</th>
                                    <th className='tableheadgrp'>Function(s)</th>
                                </tr>
                            </thead>
                            <tbody className='tableheadgrp'>
                                <tr>
                                    <td>Q000006</td>
                                    <td>Lily Kong</td>
                                    <td>98370232</td>
                                    <td>leo</td>
                                    <td>Approved</td>
                                    <td>01/20/2024 13:55</td>
                                    <td>
                                        <Button variant="primary" className='useraddbtn' >Edit</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Q000006</td>
                                    <td>Lily Kong</td>
                                    <td>98370232</td>
                                    <td>leo</td>
                                    <td>Approved</td>
                                    <td>01/18/2024 13:55</td>
                                    <td>
                                        <Button variant="primary" className='useraddbtn'>Edit</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                    </Card.Body>
                </Card>
            </Row>



            {/* Add/Edit Member Modal */}
            <Modal show={showMemberModal} onHide={handleCloseMemberModal} centered className="custom-modal-width">
                <Modal.Body>
                    <Form>
                        <h4 className='memberheads ms-2'>{isEditing ? 'Edit Member' : 'Add Member'}</h4>


                        <Row className="mb-3 ms-2">
                            <Col md={6}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'> Member ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name=""
                                        className="floating-input memberslabels"
                                    // Holding value for quota
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row className="mb-3 ms-2">
                            <Col md={9}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'> Member Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name=""
                                        className="floating-input memberslabels"
                                    // Holding value for quota
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row className="mb-3 ms-2">
                            <Col md={6}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'>Mobile</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name=""
                                        className="floating-input memberslabels"
                                    // Holding value for quota
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            <Form.Label className="grouplabel">Status</Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    type="radio"
                                    label="Pre-approve"
                                    name="status"
                                    value="pre-approve"
                                    checked={newMember.status === 'pre-approve'}
                                    onChange={handleInputChange}
                                    className="grouplabel me-3"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Approve"
                                    name="status"
                                    value="approve"
                                    checked={newMember.status === 'approve'}
                                    onChange={handleInputChange}
                                    className="grouplabel me-3"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Waitlisted"
                                    name="status"
                                    value="waitlisted"
                                    checked={newMember.status === 'waitlisted'}
                                    onChange={handleInputChange}
                                    className="grouplabel"
                                />

                            </div>
                            <div className="d-flex"> <Form.Check
                                type="radio"
                                label="Withdrawn"
                                name="status"
                                value="Withdrawn"
                                checked={newMember.status === 'Withdrawn'}
                                onChange={handleInputChange}
                                className="grouplabel me-3"
                            /> <Form.Check
                                    type="radio"
                                    label="Reject"
                                    name="status"
                                    value="Reject"
                                    checked={newMember.status === 'Reject'}
                                    onChange={handleInputChange}
                                    className="grouplabel me-3"
                                /></div>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={handleCloseMemberModal} className="me-2 groupclear">
                        Cancel
                    </Button>
                    <Button variant="" onClick={handleSaveMember} className='groupsearch'>
                        {isEditing ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>






            <Modal
                show={showGroupModal}
                onHide={handleCloseGroupModal}
                centered
                className="custom-modal-group"
            >
                <Modal.Body>
                    <h4 className='groupheading ps-3'>Add a Group</h4>

                    {/* Campaign Name Section */}

                    {/* Filter Section */}
                    <Card className="p-3 mb-3 border-0">
                        <Row className="mb-3">
                            <Col md={8}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'>Campaign Name</Form.Label>
                                    <Form.Select className='grouplabel'>
                                        <option className='grouplabel'>Cantonese Opera Class (Beginner)</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col md={4} className="d-flex align-items-center">
                                <Form.Group controlId="filter" className="w-100">
                                    <Form.Label className='grouplabel'>Filter</Form.Label>
                                    <Form.Select className='grouplabel'>
                                        <option className='grouplabel'>None</option>
                                        {Array.from({ length: 21 }, (_, i) => (
                                            <option key={i} value={i * 5}>{i * 5}%</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <span className="ms-2 mt-4 align-self-center grouplabel">above</span>
                            </Col>
                        </Row>

                        <Row className="justify-content-end">
                            <Col md="auto">
                                <Button variant="" className="me-2 groupclear">Clear</Button>
                                <Button variant="" className='groupsearch'>Search</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className="d-flex justify-content-between mt-5">
                                <p className='grouplabel'>Quota: 10</p>
                                <p className='grouplabel'>No. of applicants: 8</p>
                                <p className='grouplabel'>Quota usage: 80%</p>
                            </Col>
                        </Row>
                    </Card>

                    {/* Table */}
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th className='tableheadgrp'>Member ID</th>
                                <th className='tableheadgrp'>Member Name</th>
                                <th className='tableheadgrp'>Mobile</th>
                                <th className='tableheadgrp'>Class 1</th>
                                <th className='tableheadgrp'>Class 2</th>
                                <th className='tableheadgrp'>Class 3</th>
                                <th className='tableheadgrp'>Cla</th>
                                <th className='tableheadgrp'>Reason of Absence</th>
                                <th className='tableheadgrp'>Overall Attendance</th>
                                <th className='tableheadgrp'>Status</th>
                                <th className='tableheadgrp'>Edit</th>
                            </tr>
                        </thead>
                        <tbody className='tableheadgrp'>
                            <tr>
                                <td>Q000006</td>
                                <td>Lily Kong</td>
                                <td>98370232</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>Out Of Town</td>
                                <td>100%</td>
                                <td>Approved</td>
                                <td>
                                    <Button variant="primary" size="sm">✎</Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Q000006</td>
                                <td>Lily Kong</td>
                                <td>98370232</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>Sick</td>
                                <td>100%</td>
                                <td>Approved</td>
                                <td>
                                    <Button variant="primary" size="sm">✎</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="" onClick={handleCloseGroupModal} className='groupclear'>
                        Cancel
                    </Button>
                    <Button variant="" className='groupsearch'>Import</Button>
                </Modal.Footer>
            </Modal>



        </Container>
    );
}

export default Enrollment;
