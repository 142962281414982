// import { Icon } from '@mui/material';
// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button, Card, CardBody, Modal } from "react-bootstrap";
// import { Switch } from "@mui/material";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import './campaigncss/Survey.css';
// import { IoNotificationsOutline } from 'react-icons/io5';
// import { Add } from '@mui/icons-material';
// function Survy() {



//     const [showBasicModal, setShowBasicModal] = useState(false);
//     const handleshowBasicModal = () => setShowBasicModal(true);
//     const handlecloseBasicModal = () => setShowBasicModal(false);


//     const [showQuestionRow, setShowQuestionRow] = useState(false); // State to toggle visibility of question row

//     const handleAddClick = () => {
//         setShowQuestionRow(true); // Show the question row when Add button is clicked
//     };

//     return (
//         <Container>
//             <Row>
//                 <Card className="mt-0 border-0" style={{ position: "relative" }}>
//                     <Card.Body>
//                         <Form>



//                             <Row className="align-items-center mb-3">
//                                 <Col md={6} className="d-flex align-items-center">
//                                     <h5 className="basicheading">Survey</h5>
//                                 </Col>
//                                 <Col md={6} className="text-end">
//                                     <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
//                                     <Button variant="primary" className="basicsavebtn me-3">Save</Button>
//                                 </Col>
//                             </Row>

//                             <Row>
//                                 <Col md={12}>
//                                     <Card className="basicinfocard">
//                                         <CardBody className="d-flex justify-content-between align-items-center">
//                                             <div className="d-flex align-items-center">
//                                                 <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
//                                                 <span className="basicstatus">Status: Draft</span>
//                                             </div>
//                                             <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
//                                         </CardBody>
//                                     </Card>
//                                 </Col>
//                             </Row>


//                             {showQuestionRow && (
//                                 <Row className="align-items-center mb-3 mt-3">
//                                     <Col>
//                                         <Form.Group className="floating-label-group">
//                                             <Form.Control
//                                                 as="textarea"
//                                                 rows={2}
//                                                 className="floating-input"
//                                                 style={{
//                                                     height: 'auto',
//                                                     borderColor: '#848484',
//                                                     backgroundColor: 'transparent'
//                                                 }}
//                                             />
//                                             <Form.Label className="floating-label">Question (Chinese)</Form.Label>
//                                         </Form.Group>
//                                     </Col>
//                                     <Col>
//                                         <Form.Group className="floating-label-group">
//                                             <Form.Control
//                                                 as="textarea"
//                                                 rows={2}
//                                                 className="floating-input"
//                                                 style={{
//                                                     height: 'auto',
//                                                     borderColor: '#848484',
//                                                     backgroundColor: 'transparent'
//                                                 }}
//                                             />
//                                             <Form.Label className="floating-label">Question (English)</Form.Label>
//                                         </Form.Group>
//                                     </Col>

//                                     <Col md={1} className="d-flex flex-column justify-content-between">
//                                         <Button
//                                             style={{
//                                                 backgroundColor: '#FF4694',
//                                                 border: 'none',
//                                                 height: '24px',
//                                                 width: '24px',
//                                                 padding: '0',
//                                                 marginBottom: '8px'
//                                             }}
//                                         >
//                                             <Icon>remove</Icon>
//                                         </Button>
//                                         <Button
//                                             style={{
//                                                 backgroundColor: '#1976D2',
//                                                 border: 'none',
//                                                 height: '24px',
//                                                 width: '24px',
//                                                 padding: '0'
//                                             }}
//                                         >
//                                             <Icon>edit</Icon>
//                                         </Button>
//                                     </Col>
//                                 </Row>
//                             )}



//                             <Row className="mt-3">
//                                 <Col md={1}>
//                                     <Button
//                                         style={{
//                                             backgroundColor: '#1976D2',
//                                             border: 'none',
//                                             height: '24px',
//                                             width: '24px',
//                                             padding: '0'
//                                         }}
//                                         onClick={handleAddClick}
//                                     >
//                                         <Add style={{ color: '#fff', fontSize: '16px' }} />
//                                     </Button>
//                                 </Col>
//                             </Row>





//                             {/* User Comments Section */}
//                             <Row className='mt-5'>
//                                 <h5 className='usercomment mt-5'> User Comments <FormControlLabel control={<Switch />} label="" /></h5>
//                                 <p className='usertext'>Enable User Comments in the Portal, and members can freely comment on the mobile app.</p>
//                             </Row>


//                         </Form>
//                     </Card.Body>
//                 </Card>
//             </Row>


//             <Modal
//                 show={showBasicModal}
//                 onHide={handlecloseBasicModal}
//                 centered
//                 className="custom-modal-width"
//             >
//                 <Modal.Body>
//                     <Form>
//                         <h4 className="assigntohead ms-2">
//                             Assign to
//                         </h4>

//                         <Form.Group controlId="formStatus" className="mt-3 ms-3">

//                             <Form.Check
//                                 type="checkbox"
//                                 label="Ling (Manager)"
//                                 name="status"
//                                 value="pre-approve"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Rainbow Lee (Campaign Organizer)"
//                                 name="status"
//                                 value="approve"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Alex Law (Manager)"
//                                 name="status"
//                                 value="waitlisted"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Chan Siu Ming (Manager)"
//                                 name="status"
//                                 value="waitlisted"
//                                 className="assigntocheck"
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     <Button
//                         variant=""
//                         onClick={handlecloseBasicModal}
//                         className="me-2 groupclear"
//                     >
//                         Cancel
//                     </Button>
//                     <Button
//                         variant=""
//                         className="groupsearch"
//                         onClick={handlecloseBasicModal}
//                     >
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </Container>
//     );
// }

// export default Survy;


//-------------------------------------------------------------------------------------------------------------------



import { Icon } from '@mui/material';
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, CardBody, Modal } from "react-bootstrap";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import './campaigncss/Survey.css';
import { IoNotificationsOutline } from 'react-icons/io5';
import { Add } from '@mui/icons-material';

function Survy() {

    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);

    const [questionRows, setQuestionRows] = useState([]); // Manage dynamic rows

    const handleAddClick = () => {
        setQuestionRows([
            ...questionRows,
            { id: Date.now(), chinese: '', english: '' } // Add new empty question row
        ]);
    };

    const handleRemoveClick = (id) => {
        setQuestionRows(questionRows.filter(row => row.id !== id)); // Remove specific question row
    };

    const handleChange = (id, field, value) => {
        setQuestionRows(
            questionRows.map(row =>
                row.id === id ? { ...row, [field]: value } : row
            )
        );
    };

    return (
        <Container>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>
                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">Survey</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
                                    <Button variant="primary" className="basicsavebtn me-3">Save</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <CardBody className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">Status: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Render dynamic question rows */}
                            {questionRows.map((row) => (
                                <Row key={row.id} className="align-items-center mb-3 mt-3">
                                    <Col>
                                        <Form.Group className="floating-label-group">
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                className="floating-input"
                                                value={row.chinese}
                                                onChange={(e) => handleChange(row.id, 'chinese', e.target.value)}
                                                style={{
                                                    height: 'auto',
                                                    borderColor: '#848484',
                                                    backgroundColor: 'transparent'
                                                }}
                                            />
                                            <Form.Label className="floating-label">Question (Chinese)</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="floating-label-group">
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                className="floating-input"
                                                value={row.english}
                                                onChange={(e) => handleChange(row.id, 'english', e.target.value)}
                                                style={{
                                                    height: 'auto',
                                                    borderColor: '#848484',
                                                    backgroundColor: 'transparent'
                                                }}
                                            />
                                            <Form.Label className="floating-label">Question (English)</Form.Label>
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} className="d-flex flex-column justify-content-between">
                                        <Button
                                            style={{
                                                backgroundColor: '#FF4694',
                                                border: 'none',
                                                height: '24px',
                                                width: '24px',
                                                padding: '0',
                                                marginBottom: '8px'
                                            }}
                                            onClick={() => handleRemoveClick(row.id)} // Remove specific row
                                        >
                                            <Icon>remove</Icon>
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: '#1976D2',
                                                border: 'none',
                                                height: '24px',
                                                width: '24px',
                                                padding: '0'
                                            }}
                                        >
                                            <Icon>edit</Icon>
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                            {/* Add button */}
                            <Row className="mt-3">
                                <Col md={1}>
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}
                                        onClick={handleAddClick} // Add new row
                                    >
                                        <Add style={{ color: '#fff', fontSize: '16px' }} />
                                    </Button>
                                </Col>
                            </Row>

                            {/* User Comments Section */}
                            <Row className='mt-5'>
                                <h5 className='usercomment mt-5'> User Comments <FormControlLabel control={<Switch />} label="" /></h5>
                                <p className='usertext'>Enable User Comments in the Portal, and members can freely comment on the mobile app.</p>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>

            {/* Modal */}
            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            Assign to
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Survy;
