
// import React, { useState, useEffect } from "react";
// import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./membercss/Member.css";
// import Breadcrumb from "app/components/Breadcrumb";
// import { IoIosSearch } from "react-icons/io";
// const Member = () => {
//   const navigate = useNavigate();
//   const [members, setMembers] = useState([]);
//   const [searchOption, setSearchOption] = useState("Name");
//   const [searchInput, setSearchInput] = useState("");
//   const [filteredMembers, setFilteredMembers] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage] = useState(10);

//   const [permissions, setPermissions] = useState({
//     add: false,
//     edit: false,
//     delete: false,
//   });

//   // Fetch permissions
//   useEffect(() => {
//     const fetchPermissions = async () => {
//       const token = localStorage.getItem("authToken");
//       if (!token) {
//         console.error("No token found in localStorage");
//         return;
//       }

//       try {
//         const response = await axios.get(
//           "https://membershipuat.maxisense.io/webapp/memportal/getadmininfo",
//           {
//             headers: {
//               Token: token,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.data.code === 1) {
//           const permissionsList = response.data.data.permission_list || [];
//           setPermissions({
//             add: permissionsList.includes("account"),
//             edit: permissionsList.includes("account_u"),
//             delete: permissionsList.includes("account_d"),
//           });
//         }
//       } catch (error) {
//         console.error("Error fetching permissions:", error);
//       }
//     };

//     fetchPermissions();
//   }, []);

//   const handleAddMember = () => {
//     navigate("/member/memberinfo");
//   };

//   const handleEditMember = (member) => {
//     navigate("/member/memberinfo", { state: { member } });
//   };

//   useEffect(() => {
//     const fetchMembers = async () => {
//       try {
//         const response = await axios.get(
//           "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/fetch-basic-info"
//         );
//         const data = JSON.parse(response.data.body);
//         setMembers(data.basicInfo);
//         setFilteredMembers(data.basicInfo);
//       } catch (error) {
//         console.error("Error fetching members:", error);
//       }
//     };
//     fetchMembers();
//   }, []);

//   const handleSearchOptionChange = (e) => {
//     setSearchOption(e.target.value);
//   };

//   const handleSearchInputChange = (e) => {
//     setSearchInput(e.target.value);
//   };

//   const handleSearch = () => {
//     if (searchInput === "") {
//       setFilteredMembers(members);
//     } else {
//       const filtered = members.filter((member) => {
//         switch (searchOption) {
//           case "Name":
//             return member.name.toLowerCase().includes(searchInput.toLowerCase());
//           case "Email":
//             return member.email.toLowerCase().includes(searchInput.toLowerCase());
//           case "Member ID":
//             return member.memberId.toLowerCase().includes(searchInput.toLowerCase());
//           case "Mobile":
//             return member.phone.toLowerCase().includes(searchInput.toLowerCase());
//           case "Card Ref":
//             return member.cardRef.toLowerCase().includes(searchInput.toLowerCase());
//           case "Account ID":
//             return member.accountId.toLowerCase().includes(searchInput.toLowerCase());
//           default:
//             return false;
//         }
//       });
//       setFilteredMembers(filtered);
//     }
//     setPage(0);
//   };

//   const handlePaginationClick = (index) => {
//     setPage(index);
//   };

//   const currentMembers = filteredMembers.slice(
//     page * rowsPerPage,
//     (page + 1) * rowsPerPage
//   );

//   const totalPages = Math.ceil(filteredMembers.length / rowsPerPage);


//   const [activeTab, setActiveTab] = useState("Search for Members");

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };
//   return (
//     <Container fluid style={{ padding: "0" }}>
//       <div className="breadcrumb ps-4 pt-4">
//         <Breadcrumb routeSegments={[{ name: "Member Management" }]} />
//       </div>





//       <Row className="ps-4 g-0 me-4 mt-5">
//         <Col xs={12} md={4} className="d-flex justify-content-between">
//           {["Search for Members", "Identity", "Advance"].map((tab, index) => (
//             <div
//               key={index}
//               className={`tab-link ${activeTab === tab ? "active" : ""}`}
//               onClick={() => handleTabClick(tab)}
//               style={{
//                 cursor: "pointer",
//                 fontWeight: activeTab === tab ? "bold" : "normal",
//                 color: activeTab === tab ? "blue" : "black",
//                 paddingBottom: "5px",
//                 borderBottom: activeTab === tab ? "2px solid blue" : "none",
//               }}
//             >
//               {tab}
//             </div>
//           ))}
//         </Col>
//       </Row>

//       <Row className="mb-3 ps-4 mt-5">
//         {/* Labels */}
//         <Col xs={12} md={3}>
//           <Form.Label>Attribute Name</Form.Label>
//         </Col>
//         <Col xs={12} md={3}>
//           <Form.Label>Condition</Form.Label>
//         </Col>
//         <Col xs={12} md={3}>
//           <Form.Label>Value</Form.Label>
//         </Col>
//       </Row>

//       <Row className="align-items-center ps-4">
//         {/* Attribute Name Input */}
//         <Col xs={12} md={3}>
//           <div style={{ position: "relative" }}>
//             <IoIosSearch
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "10px",
//                 transform: "translateY(-50%)",
//                 color: "#6c757d", // Optional: Icon color
//               }}
//               size={30}
//             />
//             <Form.Control
//               type="text"
//               className="custom-search-member"
//               style={{
//                 paddingLeft: "35px",

//               }}

//             />
//           </div>
//         </Col>

//         {/* Condition Dropdown */}
//         <Col xs={12} md={3}>
//           <Form.Select className="custom-search-member">
//             <option value=""></option>
//             <option value="equals">Equals</option>
//             <option value="notEquals">Not Equals</option>
//             <option value="contains">Contains</option>
//             <option value="startsWith">Starts With</option>
//             <option value="endsWith">Ends With</option>
//           </Form.Select>
//         </Col>

//         {/* Value Input */}
//         <Col xs={12} md={3}>
//           <Form.Control
//             type="text"
//             className="custom-search-member"
//           />
//         </Col>
//       </Row>

//       <Row className="mb-3 ps-4 mt-5">
//         {/* Labels */}
//         <Col xs={12} md={3}>
//           <Button
//             variant="success"
//             className="  me-2"
//           >
//             Add Filter
//           </Button>
//         </Col>

//       </Row>

//       <Row className="align-items-center mt-4 ps-4">
//         {/* Search Box */}
//         <Col xs={12} md={4}>
//           <div style={{ position: "relative" }}>
//             <IoIosSearch
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "10px",
//                 transform: "translateY(-50%)",
//                 color: "#6c757d", // Optional: Icon color
//               }}
//               size={30}
//             />
//             <Form.Control
//               type="text"
//               className="custom-search-member"
//               style={{
//                 paddingLeft: "35px",

//               }}

//             />
//           </div>
//         </Col>

//         {/* Checkbox */}
//         <Col xs={12} md={2} className="d-flex align-items-center">
//           <Form.Check
//             type="checkbox"
//             id="exampleCheck"
//             label="Exact Match"
//             className="ms-md-2 "
//           />
//         </Col>

//         {/* Buttons */}
//         <Col xs={12} md={3} >
//           <Button
//             variant="success"
//             className=" custom-success-btn me-2"

//           >
//             Search
//           </Button>
//           <Button
//             variant="secondary"
//             className="custom-success-btn"
//           >
//             Reset
//           </Button>
//         </Col>
//       </Row>

//       <Row className="ps-4 g-0 me-4">
//         <Col xs={12} md={{ span: 12 }}>
//           <Card
//             className="mt-4 mb-4  custom-shadow"
//             style={{ margin: "0", padding: "0", width: "100%" }}
//           >
//             <Card.Body>
//               <Row className="d-flex align-items-center">
//                 <Col md={6}>
//                   <h4 className="search-heading">Search for Members</h4>
//                 </Col>
//                 <Col md={6} className="text-end">
//                   {permissions.add && (
//                     <Button
//                       variant="success"
//                       className="custom-success-btn me-2"
//                       onClick={handleAddMember}
//                     >
//                       Add
//                     </Button>
//                   )}
//                 </Col>
//               </Row>

//               <hr />

//               <Row className="d-flex align-items-center mt-3">
//                 <Col md={4}>
//                   <Form.Select
//                     aria-label="Member ID Select"
//                     className="custom-select me-2"
//                     style={{ height: "29px", padding: "0 8px" }}
//                     value={searchOption}
//                     onChange={handleSearchOptionChange}
//                   >
//                     <option value="Name">Name</option>
//                     <option value="Email">Email</option>
//                     <option value="Member ID">Member ID</option>
//                     <option value="Mobile">Mobile</option>
//                     <option value="Card Ref">Card Ref</option>
//                     <option value="Account ID">Account ID</option>
//                   </Form.Select>
//                 </Col>
//                 <Col md={3} className="ps-1 ms-3">
//                   <Form.Control
//                     type="text"
//                     className="custom-search-member me-2"
//                     style={{ height: "29px", padding: "0 8px" }}
//                     value={searchInput}
//                     onChange={handleSearchInputChange}
//                   />
//                 </Col>
//                 <Col md={2} className="text-end">
//                   <Button
//                     variant="success"
//                     className="custom-success-btn me-5"
//                     onClick={handleSearch}
//                   >
//                     Search
//                   </Button>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       <Row className="ps-4 mt-5">
//         <Col xs={12}>
//           <h4 className="member-result">Result</h4>
//         </Col>
//       </Row>
//       <hr />

//       <Row className="ps-4 me-3">
//         <Col xs={12} md={{ span: 12 }}>
//           <Table
//             striped
//             hover
//             responsive
//             className="centered-table"
//             style={{ margin: "0", padding: "0", width: "100%" }}
//           >
//             <thead>
//               <tr>
//                 <th className="custom-th">Member Name</th>
//                 <th className="custom-th">Joined Date</th>
//                 <th className="custom-th">Mobile</th>
//                 <th className="custom-th">Member ID</th>
//                 <th className="custom-th">Card Ref.</th>
//                 <th className="custom-th">Email</th>
//                 <th className="custom-th">App 1st Login</th>
//                 <th className="custom-th">Member Identity</th>
//                 <th className="custom-th">Function(s)</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentMembers.length > 0 ? (
//                 currentMembers.map((member, index) => (
//                   <tr key={index}>
//                     <td className="custom-td">{member.name}</td>
//                     <td className="custom-td">{member.joinedDate}</td>
//                     <td className="custom-td">{member.phone}</td>
//                     <td className="custom-td">{member.memberId}</td>
//                     <td className="custom-td">{member.cardRef}</td>
//                     <td className="custom-td">{member.email}</td>
//                     <td className="custom-td">{member.firstLogin}</td>
//                     <td className="custom-td">Category</td>
//                     <td className="custom-td">
//                       {permissions.edit && (
//                         <Button
//                           variant="success"
//                           className="custom-success-btn me-2"
//                           onClick={() => handleEditMember(member)}
//                         >
//                           Edit
//                         </Button>
//                       )}
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="9" className="text-center">
//                     No members found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </Table>

//           <nav aria-label="Page navigation example" className="mt-5">
//             <ul className="pagination justify-content-end">
//               <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
//                 <a
//                   className="page-link"
//                   href="#"
//                   onClick={() => handlePaginationClick(page - 1)}
//                   aria-disabled={page === 0}
//                 >
//                   Previous
//                 </a>
//               </li>
//               {Array.from({ length: totalPages }, (_, idx) => (
//                 <li
//                   key={idx}
//                   className={`page-item ${idx === page ? "active" : ""}`}
//                 >
//                   <a
//                     className="page-link"
//                     href="#"
//                     onClick={() => handlePaginationClick(idx)}
//                   >
//                     {idx + 1}
//                   </a>
//                 </li>
//               ))}
//               <li
//                 className={`page-item ${page === totalPages - 1 ? "disabled" : ""
//                   }`}
//               >
//                 <a
//                   className="page-link"
//                   href="#"
//                   onClick={() => handlePaginationClick(page + 1)}
//                   aria-disabled={page === totalPages - 1}
//                 >
//                   Next
//                 </a>
//               </li>
//             </ul>
//           </nav>
//         </Col>
//       </Row>
//     </Container>
//   );
// };
// export default Member;



//------------------------------------------------------------------------------------------------------------------------------------



import React, { useState, useEffect } from "react";
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./membercss/Member.css";
import Breadcrumb from "app/components/Breadcrumb";
import { IoIosSearch } from "react-icons/io";
import Identity from "./Identity";
import Advance from "./Advance";
const Member = () => {
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [searchOption, setSearchOption] = useState("Name");
  const [searchInput, setSearchInput] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  // Fetch permissions
  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      try {
        const response = await axios.get(
          "https://membershipuat.maxisense.io/webapp/memportal/getadmininfo",
          {
            headers: {
              Token: token,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.code === 1) {
          const permissionsList = response.data.data.permission_list || [];
          setPermissions({
            add: permissionsList.includes("account"),
            edit: permissionsList.includes("account_u"),
            delete: permissionsList.includes("account_d"),
          });
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, []);

  const handleAddMember = () => {
    navigate("/member/memberinfo");
  };

  const handleEditMember = (member) => {
    navigate("/member/memberinfo", { state: { member } });
  };

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(
          "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/fetch-basic-info"
        );
        const data = JSON.parse(response.data.body);
        setMembers(data.basicInfo);
        setFilteredMembers(data.basicInfo);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };
    fetchMembers();
  }, []);

  const handleSearchOptionChange = (e) => {
    setSearchOption(e.target.value);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    if (searchInput === "") {
      setFilteredMembers(members);
    } else {
      const filtered = members.filter((member) => {
        switch (searchOption) {
          case "Name":
            return member.name.toLowerCase().includes(searchInput.toLowerCase());
          case "Email":
            return member.email.toLowerCase().includes(searchInput.toLowerCase());
          case "Member ID":
            return member.memberId.toLowerCase().includes(searchInput.toLowerCase());
          case "Mobile":
            return member.phone.toLowerCase().includes(searchInput.toLowerCase());
          case "Card Ref":
            return member.cardRef.toLowerCase().includes(searchInput.toLowerCase());
          case "Account ID":
            return member.accountId.toLowerCase().includes(searchInput.toLowerCase());
          default:
            return false;
        }
      });
      setFilteredMembers(filtered);
    }
    setPage(0);
  };

  const handlePaginationClick = (index) => {
    setPage(index);
  };

  const currentMembers = filteredMembers.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const totalPages = Math.ceil(filteredMembers.length / rowsPerPage);


  const [activeTab, setActiveTab] = useState("Search for Members");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="ps-4 pt-4 align-items-center">
        <Col>
          <Breadcrumb routeSegments={[{ name: "Member Management" }]} />
        </Col>
        <Col className="text-end me-4">
          {(activeTab === "Identity" || activeTab === "Advance" || activeTab === "Search for Members") && permissions.add && (
            <Button
              // variant="success"
              className="custom-success-btn me-2"
              onClick={handleAddMember}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>

      <Row className="ps-4 g-0 me-4 mt-5">
        <Col xs={12} md={4} className="d-flex justify-content-between">
          {["Search for Members", "Identity", "Advance"].map((tab, index) => (
            <div
              key={index}
              className={`tab-link ${activeTab === tab ? "active" : ""}`}
              onClick={() => handleTabClick(tab)}
              style={{
                cursor: "pointer",
                fontWeight: activeTab === tab ? "bold" : "normal",
                color: activeTab === tab ? "blue" : "black",
                paddingBottom: "5px",
                borderBottom: activeTab === tab ? "2px solid blue" : "none",
              }}
            >
              {tab}
            </div>
          ))}
        </Col>
      </Row>


      {/* Conditional Rendering of Components Based on Active Tab */}
      {activeTab === "Search for Members" && (
        <Row className="ps-4 g-0 me-4">
          <Col xs={12} md={{ span: 12 }}>
            <Card
              className="mt-4 mb-4 custom-shadow"
              style={{ margin: "0", padding: "0", width: "100%" }}
            >
              <Card.Body>
                <Row className="d-flex align-items-center">
                  <Col md={6}>
                    <h4 className="search-heading">Search for Members</h4>
                  </Col>
                  {/* <Col md={6} className="text-end">
                    {permissions.add && (
                      <Button
                        variant="success"
                        className="custom-success-btn me-2"
                        onClick={handleAddMember}
                      >
                        Add
                      </Button>
                    )}
                  </Col> */}
                </Row>


                <hr />

                <Row className="d-flex align-items-center mt-3">
                  <Col md={4}>
                    <Form.Select
                      aria-label="Member ID Select"
                      className="custom-select me-2"
                      style={{ height: "29px", padding: "0 8px" }}
                      value={searchOption}
                      onChange={handleSearchOptionChange}
                    >
                      <option value="Name">Name</option>
                      <option value="Email">Email</option>
                      <option value="Member ID">Member ID</option>
                      <option value="Mobile">Mobile</option>
                      <option value="Card Ref">Card Ref</option>
                      <option value="Account ID">Account ID</option>
                    </Form.Select>
                  </Col>
                  <Col md={3} className="ps-1 ms-3">
                    <Form.Control
                      type="text"
                      className="custom-search-member me-2"
                      style={{ height: "29px", padding: "0 8px" }}
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </Col>
                  <Col md={2} className="text-end">
                    <Button
                      variant="success"
                      className="custom-success-btn me-5"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>

          <Row className="mt-5">
            <Col xs={12}>
              <h4 className="member-result">Result</h4>
            </Col>
          </Row>
          <hr />

          <Row className=" me-3">
            <Col xs={12} md={{ span: 12 }}>
              <Table
                striped
                hover
                responsive
                className="centered-table"
                style={{ margin: "0", padding: "0", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="custom-th">Member Name</th>
                    <th className="custom-th">Joined Date</th>
                    <th className="custom-th">Mobile</th>
                    <th className="custom-th">Member ID</th>
                    <th className="custom-th">Card Ref.</th>
                    <th className="custom-th">Email</th>
                    <th className="custom-th">App 1st Login</th>
                    <th className="custom-th">Member Identity</th>
                    <th className="custom-th">Function(s)</th>
                  </tr>
                </thead>
                <tbody>
                  {currentMembers.length > 0 ? (
                    currentMembers.map((member, index) => (
                      <tr key={index}>
                        <td className="custom-td">{member.name}</td>
                        <td className="custom-td">{member.joinedDate}</td>
                        <td className="custom-td">{member.phone}</td>
                        <td className="custom-td">{member.memberId}</td>
                        <td className="custom-td">{member.cardRef}</td>
                        <td className="custom-td">{member.email}</td>
                        <td className="custom-td">{member.firstLogin}</td>
                        <td className="custom-td">Category</td>
                        <td className="custom-td">
                          {permissions.edit && (
                            <Button
                              variant="success"
                              className="custom-success-btn me-2"
                              onClick={() => handleEditMember(member)}
                            >
                              Edit
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No members found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

           

              <nav aria-label="Page navigation example" className="mt-5">
                <ul className="pagination justify-content-end">
                  {/* Previous Button */}
                  <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
                    <a
                      className={`page-link ${page !== 0 ? "text-success" : ""}`}
                      href="#"
                      onClick={() => handlePaginationClick(page - 1)}
                      aria-disabled={page === 0}
                    >
                      Previous
                    </a>
                  </li>

                  {/* Page Numbers */}
                  {Array.from({ length: totalPages }, (_, idx) => (
                    <li
                      key={idx}
                      className={`page-item ${idx === page ? "active" : ""}`}
                    >
                      <a
                        className={`page-link ${idx === page ? "bg-success text-white" : "text-success"}`}
                        href="#"
                        onClick={() => handlePaginationClick(idx)}
                      >
                        {idx + 1}
                      </a>
                    </li>
                  ))}

                  {/* Next Button */}
                  <li className={`page-item ${page === totalPages - 1 ? "disabled" : ""}`}>
                    <a
                      className={`page-link ${page !== totalPages - 1 ? "text-success" : ""}`}
                      href="#"
                      onClick={() => handlePaginationClick(page + 1)}
                      aria-disabled={page === totalPages - 1}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>


            </Col>
          </Row>

        </Row>



      )}
      {activeTab === "Identity" && <Identity />}
      {activeTab === "Advance" && <Advance />}
    </Container>
  );
};
export default Member;
