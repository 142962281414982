

// import React, { useState, useEffect } from 'react';
// import { Modal, Button, Form, Alert } from 'react-bootstrap';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaEye, FaEyeSlash } from "react-icons/fa";

// const UserManagementNew = ({ show, handleClose, modalTitle, userData, fetchUsers }) => {
//   const [adminName, setAdminName] = useState('');
//   const [adminEmail, setAdminEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [roleId, setRoleId] = useState(''); // Store the role_id here
//   const [roles, setRoles] = useState([]);
//   const [available, setAvailable] = useState(true); // Default availability
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [showPassword, setShowPassword] = useState(false);

//   // Populate form fields if editing an existing user
//   useEffect(() => {
//     if (userData) {
//       setAdminName(userData.admin_name || '');
//       setAdminEmail(userData.admin_email || '');
//       setPassword(userData.password || '');
//       setRoleId(userData.role_id || ''); // Ensure role_id is populated
//       setAvailable(userData.available || false);
//     } else {
//       setAdminName('');
//       setAdminEmail('');
//       setPassword('');
//       setRoleId('');
//       setAvailable(true);
//     }
//   }, [userData]);

//   // Fetch roles from API
//   const fetchRoles = async () => {
//     const token = localStorage.getItem("authToken");
//     try {
//       const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getrolelist", {
//         headers: {
//           Token: token,
//           "Content-Type": "application/json",
//         },
//       });
//       const rolesData = Array.isArray(response.data.data) ? response.data.data : [];
//       setRoles(rolesData);
//       console.log("Roles fetched from API:", rolesData); // Debugging log
//     } catch (error) {
//       console.error("Error fetching roles:", error);
//       setRoles([]);
//     }
//   };

//   useEffect(() => {
//     fetchRoles();
//   }, []);

//   // Validate email format
//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   // Handle save operation
//   const handleSave = async () => {
//     console.log("Form Data Before Validation:", {
//       adminName,
//       adminEmail,
//       password,
//       roleId, // role_id (UUID) should be passed now
//       available,
//     });

//     if (!validateEmail(adminEmail)) {
//       setErrorMessage('Please enter a valid email address.');
//       return;
//     }

//     const validationMessage = validatePassword();
//     if (validationMessage) {
//       setErrorMessage(validationMessage);
//       return;
//     }

//     if (!roleId) {
//       setErrorMessage('Please select a role.');
//       return;
//     }

//     const newUserData = {
//       role_id: roleId, // Send the role_id (UUID) here
//       admin_name: adminName,
//       admin_email: adminEmail,
//       password,
//       available,
//     };

//     console.log("Validated Form Data Being Submitted:", newUserData);

//     const token = localStorage.getItem("authToken");
//     try {
//       const response = await axios.post('https://membershipuat.maxisense.io/webapp/memportal/addadmin', newUserData, {
//         headers: {
//           Token: token,
//           "Content-Type": "application/json",
//         },
//       });

//       console.log("Response from API:", response.data);

//       if (response.data.code === 1) {
//         setSuccessMessage('User added successfully!');
//         fetchUsers && fetchUsers();
//         setTimeout(() => setSuccessMessage(''), 3000);
//         handleClose();
//       } else {
//         // setErrorMessage(response.data.message_en || 'Failed to add user. Please try again.');


//         const errorMsg = response.data?.message_en || "Failed to add user. Please try again. ";
//         setErrorMessage(errorMsg);
//         setTimeout(() => setErrorMessage(""), 3000);


//       }
//     } catch (error) {
//       console.error('Error saving user:', error);
//       setErrorMessage('Failed to add user. Please try again.');
//     }
//   };

//   // Validate password format
//   const validatePassword = () => {
//     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
//     if (!passwordRegex.test(password)) {
//       return 'Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.';
//     }
//     return '';
//   };

//   return (
//     <Modal show={show} onHide={handleClose} centered size="md" className="custom-modal">
//       <Modal.Body>
//         <Form>
//           <h4 className="maintitle">{modalTitle}</h4>

//           {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
//           {successMessage && <Alert variant="success">{successMessage}</Alert>}

//           {/* Admin Name */}
//           <Form.Group controlId="formAdminName" className="floating-label-group">
//             <Form.Control
//               type="text"
//               value={adminName}
//               onChange={(e) => setAdminName(e.target.value)}
//               className="floating-input"
//               required
//             />
//             <Form.Label className="floating-label">User Name</Form.Label>
//           </Form.Group>

//           {/* Admin Email */}
//           <Form.Group controlId="formAdminEmail" className="floating-label-group">
//             <Form.Control
//               type="email"
//               value={adminEmail}
//               onChange={(e) => setAdminEmail(e.target.value)}
//               className="floating-input"
//               required
//             />
//             <Form.Label className="floating-label">User Email</Form.Label>
//           </Form.Group>

//           {/* Password */}
//           <Form.Group controlId="formPassword" className="floating-label-group" style={{ position: "relative" }}>
//             <Form.Control
//               type={showPassword ? "text" : "password"}
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               className="floating-input"
//               required
//             />
//             <Form.Label className="floating-label">Password</Form.Label>
//             <div
//               onClick={() => setShowPassword(!showPassword)}
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 right: "10px",
//                 transform: "translateY(-50%)",
//                 cursor: "pointer",
//                 color: "#6c757d",
//               }}
//             >
//               {showPassword ? <FaEyeSlash /> : <FaEye />}
//             </div>
//           </Form.Group>

//           {/* Role Dropdown */}
//           <Form.Group controlId="formRoleId" className="floating-label-group">
//             <Form.Select
//               value={roleId}
//               onChange={(e) => setRoleId(e.target.value)} // Directly set the role_id
//               className="floating-input"
//               required
//             >
//               <option value="">Select Role</option>
//               {roles.map((role) => (
//                 <option key={role.role_id} value={role.role_id}> {/* Store role_id as value */}
//                   {role.role_name} {/* Display role_name */}
//                 </option>
//               ))}
//             </Form.Select>
//             <Form.Label className="floating-label">Role</Form.Label>
//           </Form.Group>

//           {/* Available Checkbox */}
//           <Form.Group controlId="formAvailable" className="floating-label-group">
//             <Form.Check
//               type="checkbox"
//               label="Available"
//               checked={available}
//               onChange={(e) => setAvailable(e.target.checked)}
//             />
//           </Form.Group>
//         </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Cancel
//         </Button>
//         <Button variant="primary" onClick={handleSave}>
//           Save
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default UserManagementNew;




import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLanguage } from 'app/contexts/LanguageContext';
const UserManagementNew = ({
  show,
  handleClose,
  modalTitle,
  userData,
  fetchUsers,
}) => {
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roles, setRoles] = useState([]);
  const [available, setAvailable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Populate form fields if editing an existing user
  useEffect(() => {
    if (userData) {
      setAdminName(userData.admin_name || "");
      setAdminEmail(userData.admin_email || "");
      setPassword(""); // Don't populate the password field for editing
      setRoleId(userData.role_id || "");
      setAvailable(userData.available || false);
    } else {
      setAdminName("");
      setAdminEmail("");
      setPassword("");
      setRoleId("");
      setAvailable(true);
    }
  }, [userData]);

  // Fetch roles from API
  const fetchRoles = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        "https://membershipuat.maxisense.io/webapp/memportal/getrolelist",
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );
      const rolesData = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      setRoles(rolesData);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      return "Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.";
    }
    return "";
  };

  const handleSave = async () => {
    if (!validateEmail(adminEmail)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (!roleId) {
      setErrorMessage("Please select a role.");
      return;
    }

    if (!userData && !password) {
      setErrorMessage("Please enter a password.");
      return;
    }

    if (!userData) {
      const validationMessage = validatePassword();
      if (validationMessage) {
        setErrorMessage(validationMessage);
        setTimeout(() => setErrorMessage(""), 3000);
        return;
      }
    }

    const token = localStorage.getItem("authToken");
    const apiEndpoint = userData
      ? "https://membershipuat.maxisense.io/webapp/memportal/updateadmin"
      : "https://membershipuat.maxisense.io/webapp/memportal/addadmin";

    const requestData = {
      role_id: roleId,
      admin_name: adminName,
      admin_email: adminEmail,
      available,
      ...(userData
        ? { admin_id: userData.admin_id, admin_profile: "" } // For editing
        : { password }), // Include password only for adding
    };

    try {
      const response = await axios.post(apiEndpoint, requestData, {
        headers: {
          Token: token,
          "Content-Type": "application/json",
        },
      });

      if (response.data.code === 1) {
        setSuccessMessage(
          userData ? "User updated successfully!" : "User added successfully!"
        );
        fetchUsers && fetchUsers();
        setTimeout(() => setSuccessMessage(""), 5000);
        handleClose();
      } else {
        const errorMsg =
          response.data?.message_en ||
          "Failed to save user. Please try again.";
        setErrorMessage(errorMsg);
        setTimeout(() => setErrorMessage(""), 3000);
      }
    } catch (error) {
      console.error("Error saving user:", error);
      setErrorMessage("Failed to save user. Please try again.");
    }
  };
  const { selectedLanguage } = useLanguage();
  const getRoleName = (role) => {
    return selectedLanguage === 'zh' ? role.role_name : role.role_name_en; // Assuming role_name_zh and role_name_en exist in the role data
  };







  return (
    // <Modal show={show} onHide={handleClose} centered size="md">
    //   <Modal.Body>
    //     <Form>
    //       <h4 className="maintitle">{modalTitle}</h4>

    //       {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
    //       {successMessage && <Alert variant="success">{successMessage}</Alert>}

    //       {/* Admin Name */}
    //       <Form.Group controlId="formAdminName" className="floating-label-group">
    //         <Form.Control
    //           type="text"
    //           value={adminName}
    //           onChange={(e) => setAdminName(e.target.value)}
    //           className="floating-input"
    //           required
    //         />
    //         <Form.Label className="floating-label">User Name</Form.Label>
    //       </Form.Group>

    //       {/* Admin Email */}
    //       <Form.Group
    //         controlId="formAdminEmail"
    //         className="floating-label-group"
    //       >
    //         <Form.Control
    //           type="email"
    //           value={adminEmail}
    //           onChange={(e) => setAdminEmail(e.target.value)}
    //           className="floating-input"
    //           required
    //         />
    //         <Form.Label className="floating-label">User Email</Form.Label>
    //       </Form.Group>

    //       {/* Password - Only visible during Add */}
    //       {!userData && (
    //         <Form.Group
    //           controlId="formPassword"
    //           className="floating-label-group"
    //           style={{ position: "relative" }}
    //         >
    //           <Form.Control
    //             type={showPassword ? "text" : "password"}
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //             className="floating-input"
    //             required
    //           />
    //           <Form.Label className="floating-label">Password</Form.Label>
    //           <div
    //             onClick={() => setShowPassword(!showPassword)}
    //             style={{
    //               position: "absolute",
    //               top: "50%",
    //               right: "10px",
    //               transform: "translateY(-50%)",
    //               cursor: "pointer",
    //               color: "#6c757d",
    //             }}
    //           >
    //             {showPassword ? <FaEyeSlash /> : <FaEye />}
    //           </div>
    //         </Form.Group>
    //       )}

    //       {/* Role Dropdown */}
    //       <Form.Group controlId="formRoleId" className="floating-label-group">
    //         <Form.Select
    //           value={roleId}
    //           onChange={(e) => setRoleId(e.target.value)}
    //           className="floating-input"
    //           required
    //         >
    //           <option value="">Select Role</option>
    //           {roles.map((role) => (
    //             <option key={role.role_id} value={role.role_id}>
    //               {role.role_name}
    //             </option>
    //           ))}
    //         </Form.Select>
    //         <Form.Label className="floating-label">Role</Form.Label>
    //       </Form.Group>

    //       {/* Available Checkbox */}
    //       <Form.Group
    //         controlId="formAvailable"
    //         className="floating-label-group"
    //       >
    //         <Form.Check
    //           type="checkbox"
    //           label="Available"
    //           checked={available}
    //           onChange={(e) => setAvailable(e.target.checked)}
    //         />
    //       </Form.Group>
    //     </Form>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={handleClose} className="rolecancelbtn">
    //       Cancel
    //     </Button>
    //     <Button variant="primary" onClick={handleSave} className="rolesavebtn">
    //       Save
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Body>
        <Form>
          <h4 className="maintitle">User Management</h4>

          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          {/* Admin Name */}
          <Form.Group controlId="formAdminName" className="floating-label-group">
            <Form.Control
              type="text"
              value={adminName}
              onChange={(e) => setAdminName(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">User Name</Form.Label>
          </Form.Group>

          {/* Admin Email */}
          <Form.Group controlId="formAdminEmail" className="floating-label-group">
            <Form.Control
              type="email"
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
              className="floating-input"
              required
            />
            <Form.Label className="floating-label">User Email</Form.Label>
          </Form.Group>

          {/* Password */}
          {!userData && (
            <Form.Group controlId="formPassword" className="floating-label-group" style={{ position: 'relative' }}>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="floating-input"
                required
              />
              <Form.Label className="floating-label">Password</Form.Label>
              <div
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  color: '#6c757d',
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </Form.Group>
          )}

          {/* Role Dropdown */}
          <Form.Group controlId="formRoleId" className="floating-label-group">
            <Form.Select value={roleId} onChange={(e) => setRoleId(e.target.value)} className="floating-input" required>
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.role_id} value={role.role_id}>
                  {getRoleName(role)} {/* Display role name based on selected language */}
                </option>
              ))}
            </Form.Select>
            <Form.Label className="floating-label">Role</Form.Label>
          </Form.Group>

          {/* Available Checkbox */}
          <Form.Group controlId="formAvailable" className="floating-label-group">
            <Form.Check
              type="checkbox"
              label="Available"
              checked={available}
              onChange={(e) => setAvailable(e.target.checked)}
            />
          </Form.Group>

          {/* Save Button */}
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>

  );
};

export default UserManagementNew;
