


import React, { useState, Fragment,useEffect } from "react";
import {
    Box,
    Card,
    Grid,
    Drawer,
    Button,
    useTheme,
    IconButton,
    useMediaQuery
} from "@mui/material";
import { H5 } from "app/components/Typography";
import { FlexBox } from "app/components/FlexBox";
import Apps from "app/components/icons/Apps";
import { useLocation } from "react-router-dom";


// Styled button component
import { styled } from "@mui/system";
import BasicInfo from "./BasicInfo";
import Datepage from "./Datepage";
import Instructor from "./Instructor";
import Lesson from "./Lesson";
import FileUpload from "./FileUpload";
import Survy from "./Survy";
import Enrollnment from "./Enrollnment";
import Attendencedetail from "./Attendencedetail";
import Consent from "./Consent";
const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    overflow: "hidden",
    position: "relative",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: "0.6rem 1.5rem",
    justifyContent: "flex-start",
    color: theme.palette.text.primary
}));

// const Mainpage = () => {
//     const theme = useTheme();
//     const [openDrawer, setOpenDrawer] = useState(false);
//     const [active, setActive] = useState("Basic Information & Prise");
//     const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

//     const style = {
//         color: theme.palette.primary.main,
//         backgroundColor: theme.palette.grey[100],
//         "&::before": {
//             left: 0,
//             width: 4,
//             content: '""',
//             height: "100%",
//             position: "absolute",
//             transition: "all 0.3s",
//             backgroundColor: theme.palette.primary.main
//         }
//     };

//     function TabListContent() {
//         return (
//             <FlexBox flexDirection="column">
//                 {tabList.map(({ id, name, Icon }) => (
//                     <StyledButton
//                         key={id}
//                         startIcon={Icon ? <Icon sx={{ color: "text.disabled" }} /> : null}
//                         sx={active === name ? style : { "&:hover": style }}
//                         onClick={() => {
//                             setActive(name);
//                             setOpenDrawer(false);
//                         }}
//                     >
//                         {name}
//                     </StyledButton>
//                 ))}
//             </FlexBox>
//         );
//     }

//     return (
//         <Box p={4}>
//             <Grid container spacing={3}>
//                 <Grid item md={3} xs={12}>
//                     {downMd ? (
//                         <Fragment>
//                             <FlexBox alignItems="center" gap={1}>
//                                 <IconButton sx={{ padding: 0 }} onClick={() => setOpenDrawer(true)}>
//                                     <Apps sx={{ color: "primary.main" }} />
//                                 </IconButton>
//                                 <H5>Show More</H5>
//                             </FlexBox>

//                             <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
//                                 <Box padding={1}>
//                                     <TabListContent />
//                                 </Box>
//                             </Drawer>
//                         </Fragment>
//                     ) : (
//                         <Card sx={{ padding: "1rem 0", marginTop: '25px' }}>
//                             <TabListContent />
//                         </Card>
//                     )}
//                 </Grid>

//                 <Grid item md={9} xs={12}  >
//                     {active === tabList[0].name && <BasicInfo />}
//                     {active === tabList[1].name && <Datepage />}
//                     {active === tabList[2].name && <Instructor />}
//                     {active === tabList[3].name && <Lesson />}

//                     {active === tabList[4].name && <FileUpload />}
//                     {active === tabList[5].name && <Survy />}
//                     {active === tabList[6].name && <Consent />}
//                     {active === tabList[7].name && <Enrollnment />}
//                     {active === tabList[8].name && <Attendencedetail />}

//                 </Grid>
//             </Grid>
//         </Box>
//     );
// };




// const Mainpage = () => {
//     const theme = useTheme();
//     const [openDrawer, setOpenDrawer] = useState(false);
//     const [active, setActive] = useState("Basic Information & Fee"); 
//     const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

//     const style = {
//         color: theme.palette.primary.main,
//         backgroundColor: theme.palette.grey[100],
//         "&::before": {
//             left: 0,
//             width: 4,
//             content: '""',
//             height: "100%",
//             position: "absolute",
//             transition: "all 0.3s",
//             backgroundColor: theme.palette.primary.main
//         }
//     };

//     function TabListContent() {
//         return (
//             <FlexBox flexDirection="column">
//                 {tabList.map(({ id, name, Icon }) => (
//                     <StyledButton
//                         key={id}
//                         startIcon={Icon ? <Icon sx={{ color: "text.disabled" }} /> : null}
//                         sx={active === name ? style : { "&:hover": style }}
//                         onClick={() => {
//                             setActive(name);
//                             setOpenDrawer(false);
//                         }}
//                     >
//                         {name}
//                     </StyledButton>
//                 ))}
//             </FlexBox>
//         );
//     }

//     return (
//         <Box p={4}>
//             <Grid container spacing={3}>
//                 <Grid item md={3} xs={12}>
//                     {downMd ? (
//                         <Fragment>
//                             <FlexBox alignItems="center" gap={1}>
//                                 <IconButton sx={{ padding: 0 }} onClick={() => setOpenDrawer(true)}>
//                                     <Apps sx={{ color: "primary.main" }} />
//                                 </IconButton>
//                                 <H5>Show More</H5>
//                             </FlexBox>

//                             <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
//                                 <Box padding={1}>
//                                     <TabListContent />
//                                 </Box>
//                             </Drawer>
//                         </Fragment>
//                     ) : (
//                         <Card sx={{ padding: "1rem 0", marginTop: '25px' }}>
//                             <TabListContent />
//                         </Card>
//                     )}
//                 </Grid>

//                 <Grid item md={9} xs={12}>
//                     {active === tabList[0].name && <BasicInfo />}
//                     {active === tabList[1].name && <Datepage />}
//                     {active === tabList[2].name && <Instructor />}
//                     {active === tabList[3].name && <Lesson />}
//                     {active === tabList[4].name && <FileUpload />}
//                     {active === tabList[5].name && <Survy />}
//                     {active === tabList[6].name && <Consent />}
//                     {active === tabList[7].name && <Enrollnment />}
//                     {active === tabList[8].name && <Attendencedetail />}
//                 </Grid>
//             </Grid>
//         </Box>
//     );
// };




// const tabList = [
//     { id: 1, name: "Basic Information & Fee", Icon: null },
//     { id: 2, name: "Date", Icon: null },
//     { id: 3, name: "Instructor ", Icon: null },
//     { id: 4, name: "Lesson(s) Details", Icon: null },
//     { id: 5, name: "Upload Campaign Photo/Video", Icon: null },
//     { id: 6, name: "Survey", Icon: null },
//     { id: 7, name: "Consent Form", Icon: null },
//     { id: 8, name: "Enrollment Status", Icon: null },
//     { id: 9, name: "Attendence Details", Icon: null },
// ];

// export default Mainpage;





const Mainpage = ({ selectedCampaign }) => {
    const theme = useTheme();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [active, setActive] = useState("Basic Information & Fee");
    const [isBasicInfoSaved, setIsBasicInfoSaved] = useState(false); // Track save status
    const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const location = useLocation();
    const style = {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.grey[100],
        "&::before": {
            left: 0,
            width: 4,
            content: '""',
            height: "100%",
            position: "absolute",
            transition: "all 0.3s",
            backgroundColor: theme.palette.primary.main,
        },
    };
    const [campaignDetails, setCampaignDetails] = useState(location.state?.campaignDetails || {});
    useEffect(() => {
        if (location.state?.campaignDetails) {
            setCampaignDetails(location.state.campaignDetails);  // If campaign data exists, update state
        }
    }, [location.state]);

    function TabListContent() {

        return (
            <FlexBox flexDirection="column">
                {tabList.map(({ id, name, Icon }) => (
                    <StyledButton
                        key={id}
                        startIcon={Icon ? <Icon sx={{ color: "text.disabled" }} /> : null}
                        sx={active === name ? style : { "&:hover": isBasicInfoSaved || name === "Basic Information & Fee" ? style : {} }}
                        onClick={() => {
                            if (isBasicInfoSaved || name === "Basic Information & Fee") {
                                setActive(name);
                                setOpenDrawer(false);
                            } else {
                                alert("Please save the Basic Information & Fee data first!");
                            }
                        }}
                        disabled={!isBasicInfoSaved && name !== "Basic Information & Fee"} // Disable other tabs
                    >
                        {name}
                    </StyledButton>
                ))}
            </FlexBox>
        );
    }

    return (
        <Box p={4}>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    {downMd ? (
                        <Fragment>
                            <FlexBox alignItems="center" gap={1}>
                                <IconButton sx={{ padding: 0 }} onClick={() => setOpenDrawer(true)}>
                                    <Apps sx={{ color: "primary.main" }} />
                                </IconButton>
                                <H5>Show More</H5>
                            </FlexBox>

                            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                                <Box padding={1}>
                                    <TabListContent />
                                </Box>
                            </Drawer>
                        </Fragment>
                    ) : (
                        <Card sx={{ padding: "1rem 0", marginTop: "25px" }}>
                            <TabListContent />
                        </Card>
                    )}
                </Grid>

                <Grid item md={9} xs={12}>
                    {active === "Basic Information & Fee" && (
                        <BasicInfo
                            onSave={() => setIsBasicInfoSaved(true)}

                            campaignDetails={campaignDetails}
                        />
                    )}
                    {active === "Date" && <Datepage />}
                    {active === "Instructor " && <Instructor />}
                    {active === "Lesson(s) Details" && <Lesson />}
                    {active === "Upload Campaign Photo/Video" && <FileUpload />}
                    {active === "Survey" && <Survy />}
                    {active === "Consent Form" && <Consent />}
                    {active === "Enrollment Status" && <Enrollnment />}
                    {active === "Attendence Details" && <Attendencedetail />}
                </Grid>
            </Grid>
        </Box>
    );
};

const tabList = [
    { id: 1, name: "Basic Information & Fee", Icon: null },
    { id: 2, name: "Date", Icon: null },
    { id: 3, name: "Instructor ", Icon: null },
    { id: 4, name: "Lesson(s) Details", Icon: null },
    { id: 5, name: "Upload Campaign Photo/Video", Icon: null },
    { id: 6, name: "Survey", Icon: null },
    { id: 7, name: "Consent Form", Icon: null },
    { id: 8, name: "Enrollment Status", Icon: null },
    { id: 9, name: "Attendence Details", Icon: null },
];

export default Mainpage;