

import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from 'axios';
import "./membercss/MemberInfo.css";
import './membercss/BasicInformation.css';
import Avatars from './Avatars';

function BasicInformation() {
    const [inputValues, setInputValues] = useState({
        input1: "", // Name
        input2: "", // Date of Birth
        input3: "", // Gender
        input4: "", // Phone
        input5: "", // Email
        input6: "", // Language
        input7: "", // Member ID
        input8: "", // Card Ref
        input9: ""  // Joined Date
    });
    const [age, setAge] = useState(""); // Age display in years and months
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });

        // Calculate age if date of birth is changed
        if (name === "input2") {
            calculateAge(value);
        }
    };

    const calculateAge = (dob) => {
        if (!dob) return;

        const birthDate = new Date(dob);
        const today = new Date();

        let years = today.getFullYear() - birthDate.getFullYear();

        // Adjust if the current date is before the birth date in the current year
        const isBeforeBirthdayThisYear =
            today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

        if (isBeforeBirthdayThisYear) {
            years -= 1;
        }

        setAge(`${years} ${years !== 1 ? '' : ''}`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        if (inputValues.input4.length > 11) {
            setError("Phone number should not exceed 11 digits.");
            return;
        }

        // Email validation: simple regex for basic validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputValues.input5)) {
            setError("Please enter a valid email address.");
            return;
        }

        // Joined Date validation: must not be a future date
        const today = new Date().toISOString().split("T")[0];
        if (inputValues.input9 > today) {
            setError("Joined Date cannot be a future date.");
            return;
        }

        try {
            const response = await axios.post("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-basic-info", {
                ...inputValues,
                age
            });
            setSuccessMessage("Basic information saved successfully!");
            setError(null);
            setInputValues({
                input1: "", input2: "", input3: "", input4: "",
                input5: "", input6: "", input7: "", input8: "", input9: ""
            });
            setAge(""); // Reset age field
        } catch (error) {
            setError("Failed to save basic information. Please try again.");
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0">
                        <Card.Body>
                            <Avatars />
                            <br />
                            <br />
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <h5 className="basicinfo">Basic Information</h5>
                                    

                                    <Col md={6}>
                                        <Form.Group controlId="formInput1" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                name="input1"
                                                value={inputValues.input1}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                                required
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Member Name</Form.Label>
                                        </Form.Group>
                                        <Form.Group controlId="formInput3" className="floating-label-group">
                                            <Form.Select
                                                name="input3"
                                                value={inputValues.input3}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label basicinfolabel">Gender</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput4" className="floating-label-group">
                                            <Form.Control
                                                type="tel"
                                                name="input4"
                                                value={inputValues.input4}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                                maxLength="11"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Mobile</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput7" className="floating-label-group">
                                            <Form.Control
                                                type="number"
                                                name="input7"
                                                value={inputValues.input7}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                                maxLength="8"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Member ID</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput3" className="floating-label-group">
                                            <Form.Select
                                                name="input3"
                                                value={inputValues.input3}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value="">Select Language</option>
                                                <option value="English">English</option>
                                                <option value="Chinese">Chinese</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label basicinfolabel">Preferred Language</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput3" className="floating-label-group">
                                            <Form.Select
                                                name="input3"
                                                value={inputValues.input3}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value="">Select District</option>
                                                <option value="English">A</option>
                                                <option value="Chinese">B</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label basicinfolabel">Living District</Form.Label>
                                        </Form.Group>

                                    </Col>





                                    <Col md={6}>


                                        <Form.Group controlId="formInput2" className="floating-label-group">
                                            <Form.Control
                                                type="date"
                                                name="input2"
                                                value={inputValues.input2}
                                                onChange={handleChange}
                                                className="floating-input"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Date of Birth</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInputAge" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                value={age}
                                                disabled
                                                className="floating-input basicinfoinput"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Age</Form.Label>
                                        </Form.Group>


                                        <Form.Group controlId="formInput9" className="floating-label-group">
                                            <Form.Control
                                                type="date"
                                                name="input9"
                                                value={inputValues.input9}
                                                onChange={handleChange}
                                                className="floating-input"
                                                max={new Date().toISOString().split("T")[0]} // Prevent future dates
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Joined Date</Form.Label>
                                        </Form.Group>


                                        <Form.Group controlId="formInput5" className="floating-label-group">
                                            <Form.Control
                                                type="email"
                                                name="input5"
                                                value={inputValues.input5}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Email</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput3" className="floating-label-group">
                                            <Form.Select
                                                name="input3"
                                                value={inputValues.input3}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value="">Select Zone</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Kowloon">Kowloon</option>
                                                <option value="New Territories">New Territories</option>
                                                <option value="Island">Island</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label basicinfolabel">Living Zone</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput1" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                name="input1"
                                                value={inputValues.input1}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                                required
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Address</Form.Label>
                                        </Form.Group>


                                    </Col>




                                </Row>

                                <Row className="mt-5">
                                    <Col>
                                        <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                            Save Changes
                                        </Button>
                                        <Button variant="outline-primary" onClick={() => setInputValues({})} className="canslebtn">
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>

                                {error && <p className="text-danger mt-3">{error}</p>}
                                {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}

export default BasicInformation;



