
import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Container, Row, Col } from 'react-bootstrap';
import './CampaignsPage.css';
import { useNavigate } from "react-router-dom";
import { useLanguage } from 'app/contexts/LanguageContext';
import axios from 'axios';


const statusMap = {
  1: "Normal",
  2: "Delayed",
  3: "Cancelled",
};
const CampaignsPage = () => {
  const navigate = useNavigate();

  const handleAddMember = () => {
    navigate("/campaigns/mainpage");
  };

  // State for pagination
  const [page, setPage] = useState(0);
  const rowsPerPage = 5; // Default rows per page

  // Sample data for campaigns

  // Calculate total pages


  const [data, setData] = useState([]);
  const sortedData = [...data].sort((a, b) => a.campaign_type_code.localeCompare(b.campaign_type_code));


  const fetchCampaignTypes = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getcampaigntypelist", {
        headers: {
          "Content-Type": "application/json",
          Token: token,
        },
      });

      if (response.data.code === 1) {
        const campaignTypes = response.data.data || [];
        // Sort campaign types alphabetically based on campaign_type_code
        campaignTypes.sort((a, b) => {
          return a.campaign_type_code.localeCompare(b.campaign_type_code);
        });
        setData(campaignTypes);
        console.log("my data", response.data)
      } else {
        console.error("API responded with an error:", response.data.message_en);
      }
    } catch (error) {
      console.error("Error fetching campaign types:", error);
    }
  };

  // Fetch campaign types initially and refresh every 3 seconds
  useEffect(() => {
    fetchCampaignTypes();
    const intervalId = setInterval(fetchCampaignTypes, 3000);
    return () => clearInterval(intervalId);
  }, []);

  const [users, setUsers] = useState([]);
  const fetchUsers = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No token found in localStorage");

      return;
    }

    try {
      const response = await axios.get(
        "https://membershipuat.maxisense.io/webapp/memportal/getadminlist",
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.code === 1 && Array.isArray(response.data.data)) {
        setUsers(response.data.data);
      } else {
        console.warn("Unexpected API response format:", response.data);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching admin list:", error);
      setUsers([]);
    }

  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [campaign, setCampaign] = useState([]);


  // Calculate total pages
  const totalCampaigns = campaign.length;
  const totalPages = Math.ceil(totalCampaigns / rowsPerPage);

  // Get the current campaigns for the current page
  const currentCampaigns = campaign.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  // Handle page change for pagination
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // const fetchCampaign = async () => {
  //   const token = localStorage.getItem("authToken");
  //   if (!token) {
  //     console.error("No token found in localStorage");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       "https://membershipuat.maxisense.io/webapp/memportal/getcampaignlist",
  //       {
  //         headers: {
  //           Token: token,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.data.code === 1 && Array.isArray(response.data.data)) {
  //       setCampaign(response.data.data);
  //     } else {
  //       console.warn("Unexpected API response format:", response.data);
  //       setCampaign([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching campaign list:", error);
  //     setCampaign([]);
  //   }
  // };



  const fetchCampaign = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      const response = await axios.post(
        "https://membershipuat.maxisense.io/webapp/memportal/getcampaignlist",
        {},
        {
          headers: {
            Token: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.code === 1 && Array.isArray(response.data.data)) {
        // Success: set campaign data
        setCampaign(response.data.data);
        console.log(response.data)
      } else if (response.data.code === -5) {
        // Token has expired
        console.warn("Session expired, please log in again.");
        localStorage.removeItem("authToken"); // Clear expired token
        // Redirect the user to login page or show a session expired message
        // window.location.href = "/login"; // Uncomment if you need to redirect
        setCampaign([]); // Optionally, clear campaigns
      } else {
        // Unexpected response
        console.warn("Unexpected API response format:", response.data);
        setCampaign([]);
      }
    } catch (error) {
      console.error("Error fetching campaign list:", error);
      setCampaign([]);
    }
  };


  useEffect(() => {
    fetchCampaign();
  }, []);

  // Convert Unix timestamp to a human-readable date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US");
  };







  const { selectedLanguage } = useLanguage();


  const getRoleName = (item) => {
    return selectedLanguage === 'zh'
      ? item.campaign_type_name.zh
      : item.campaign_type_name.en;
  };

  const getcampaigntypename = (campaign) => {
    return selectedLanguage === 'zh'
      ? campaign.campaign_name.zh
      : campaign.campaign_name.en;
  }



  const handleDetailsClick = async (campaignId) => {
    try {
      // Fetch the campaign details based on campaign ID
      const response = await axios.post(
        "https://membershipuat.maxisense.io/webapp/memportal/getcampaigndetail",
        { campaign_id: campaignId },
        { headers: { Token: localStorage.getItem("authToken") } }
      );

      // Navigate to MainPage and pass the detailed data via state
      navigate("/campaigns/mainpage", {
        state: { campaignDetails: response.data.data },  // Pass the campaign details to MainPage
      });
    } catch (error) {
      console.error("Error fetching campaign details", error);
    }
  };


  return (
    <Container fluid>

      <Form className="mb-3 p-3 border rounded shadow-custom" style={{ marginTop: '30px' }}>
        <Row className="g-3"> {/* Added spacing between elements */}
          <Col md={3} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Campaign Name:</Form.Label>
            <Form.Control type="text" placeholder="" />
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Code:</Form.Label>
            <Form.Control type="number" placeholder="" />
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Type:</Form.Label>
            <Form.Select>


              {sortedData.map((item) => (
                <option key={item.campaign_type_code} value={item.campaign_type_code}>
                  {item.campaign_type_code} ({getRoleName(item)})
                </option>))}
            </Form.Select>
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Status:</Form.Label>
            <Form.Select>
              <option value=""></option>
              <option>Draft</option>
              <option>Pending Approval</option>
              <option>Approved</option>
              <option>Promotion</option>
              <option>Pre-registration</option>
              <option>Enrollment</option>
              <option>Start</option>
              <option>End</option>
              <option>Survey</option>

            </Form.Select>
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>Start Date:</Form.Label>
            <Form.Control type="date" />
          </Col>
          <Col md={3} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>PIC:</Form.Label>
            <Form.Select>
              {users.map((item) => (
                <option key={item.admin_id} >
                  {item.admin_name}
                </option>
              ))}
            </Form.Select>
          </Col>


          <Col md={2} className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0 notificationinputname" style={{ whiteSpace: 'nowrap' }}>SIS :-</Form.Label>
            <Form.Select>
              <option value=""></option>
              <option>Y</option>
              <option>N</option>
            </Form.Select>
          </Col>
        </Row>

        <Row className="mt-3">


          <Col className="d-flex align-items-center">
            <Button variant="success" className="custom-success-btn me-2">Search</Button>
            <Button variant="success" className="custom-success-btn me-2">Reset</Button>
            <Form.Check
              type="checkbox"
              id="exampleCheck"
              label="Exact Match"
              className="ms-2 exactsearch"
            />
          </Col>



          <Col className='text-end'>
            <Button onClick={handleAddMember} variant="success" className="custom-success-btn me-2" style={{ width: '178px' }}>Create New Campaign</Button>
          </Col>
        </Row>
      </Form>



      <hr />
      <div className="table-responsive">
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th className="cmptablehead">Campaign Name</th>
              <th className="cmptablehead">Type</th>
              <th className="cmptablehead">Code</th>
              <th className="cmptablehead">Status</th>
              <th className="cmptablehead">SIS</th>
              <th className="cmptablehead">Start Date</th>
              <th className="cmptablehead">PIC</th>
              <th className="cmptablehead">Approved By</th>
              <th className="cmptablehead">Function(s)</th>
            </tr>
          </thead>
          <tbody>
            {currentCampaigns.map((campaign, index) => (
              <tr key={index} className="cmptablehead">
                <td>{getcampaigntypename(campaign)}</td>
                <td>{campaign.campaign_type_code}</td>
                <td>{campaign.campaign_code}</td>
                <td>{statusMap[campaign.campaign_status] || "Unknown"}</td>
                <td>{campaign.sis}</td>
                <td>{formatDate(campaign.campaign_start_date)}</td>
                <td>{campaign.pic}</td>
                <td>{campaign.approvedBy}</td>
                <td>
                  <div className="button-group d-flex justify-content-end align-items-end">
                    <Button variant="success" className="custom-success-btn me-2" onClick={() => handleDetailsClick(campaign.campaign_id)}

                    >Details</Button>
                    <Button variant="success" className="custom-success-btn me-2">Copy</Button>
                    <Button variant="danger" className="custom-danger-btn">Delete</Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <nav aria-label="Page navigation example" className="mt-5">
        <ul className="pagination justify-content-end">
          {/* Previous Button */}
          <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
            <a
              className={`page-link ${page !== 0 ? 'text-success' : ''}`}
              href="#"
              onClick={() => handlePageChange(page - 1)}
              tabIndex="-1"
              aria-disabled={page === 0}
            >
              Previous
            </a>
          </li>

          {/* Page Numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={`page-item ${page === index ? 'active' : ''}`}>
              <a
                className={`page-link ${page === index ? 'bg-success text-white' : 'text-success'}`}
                href="#"
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </a>
            </li>
          ))}

          {/* Next Button */}
          <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
            <a
              className={`page-link ${page !== totalPages - 1 ? 'text-success' : ''}`}
              href="#"
              onClick={() => handlePageChange(page + 1)}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>

    </Container>
  );
};

export default CampaignsPage;
