import React, { useState } from 'react';
import { Container, Row, Button, Card, Modal, Form, Col, CardBody } from 'react-bootstrap';
import { XCircle } from 'react-bootstrap-icons';
import pdfimg from './pdfimg.png'
import './campaigncss/FileUpload.css'
import { IoNotificationsOutline } from 'react-icons/io5';
function FileUpload() {
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null); // To preview the selected image or PDF
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    // Handle opening and closing modals
    const handleOpenPhotoModal = () => {
        setSelectedFile(null); // Reset the file when opening modal
        setPreviewUrl(null);
        setShowPhotoModal(true);
    };
    // const handleClosePhotoModal = () => setShowPhotoModal(false);
    const handleClosePhotoModal = () => {
        setShowPhotoModal(false);
        setSelectedFiles([]);
        setPreviewUrls([]);
    };

    const handleOpenDocumentModal = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
        setShowDocumentModal(true);
    };
    const handleCloseDocumentModal = () => setShowDocumentModal(false);

    // Handle file selection
    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setSelectedFile(file);

    //     // Preview image or PDF
    //     const fileUrl = URL.createObjectURL(file);
    //     setPreviewUrl(fileUrl);
    // };

    // Handle saving
    const handleSave = () => {
        if (selectedFile) {
            console.log("Selected file:", selectedFile);
        }
        // Close the modal after saving
        handleClosePhotoModal();
        handleCloseDocumentModal();
    };

    // Handle removing the selected file
    // const handleRemoveFile = () => {
    //     setSelectedFile(null);
    //     setPreviewUrl(null);
    // };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        // Check if the number of selected files exceeds the limit of 3
        if (selectedFiles.length + files.length > 3) {
            alert("You can only select up to 3 images/videos.");
            return;
        }

        // Add new files to the list
        const updatedFiles = [...selectedFiles, ...files];
        setSelectedFiles(updatedFiles);

        // Preview the images/videos
        const newPreviewUrls = files.map((file) => URL.createObjectURL(file));
        setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
    };

    // Remove a selected file
    const handleRemoveFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);

        const updatedPreviewUrls = previewUrls.filter((_, i) => i !== index);
        setPreviewUrls(updatedPreviewUrls);
    };




    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);
    return (
        <Container >
            <Row>
                <Card className="mt-0 border-0" style={{ position: 'relative' }}>
                    <Card.Body>

                        <Row className="align-items-center mb-3">
                            <Col md={6} className="d-flex align-items-center">
                                <h5 className="basicheading">Upload Campaign Photo/Video</h5>
                            </Col>
                            <Col md={6} className="text-end">
                                <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
                                <Button variant="primary" className="basicsavebtn me-3">Save</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Card className="basicinfocard">
                                    <CardBody className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                            <span className="basicstatus">Status: Draft</span>
                                        </div>
                                        <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Button style={{ width: '200px', fontSize: '13px' }} onClick={handleOpenPhotoModal} className='filebtn ms-2'>
                                Add Activity Photo/Video
                            </Button>
                        </Row>
                        <br />
                        <Row>
                            <Button style={{ width: '200px', fontSize: '13px' }} onClick={handleOpenDocumentModal} className='filebtn ms-2'>
                                Add Activity Icon
                            </Button>
                        </Row>


                    </Card.Body>
                </Card>
            </Row>

            {/* Photo/Video Upload Modal */}
            {/* <Modal show={showPhotoModal} onHide={handleClosePhotoModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">Add Activity Photo/Video</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>Ratio limit 4:3, e.g. 375(W) * 221(H). File size: within 500MB</p>
                        <Form.Group controlId="formFile">
                            <input
                                type="file"
                                id="file-input"
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button onClick={() => document.getElementById('file-input').click()}>Select</Button>
                        </Form.Group>
                    </div>

                   
                    {previewUrl && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            {selectedFile.type.startsWith('image/') ? (
                                <img src={previewUrl} alt="Preview" style={{ width: '375px', height: '221px' }} />
                            ) : (
                                <video width="375" height="221" controls>
                                    <source src={previewUrl} />
                                </video>
                            )}
                            <XCircle
                                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                                size={24}
                                onClick={handleRemoveFile}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePhotoModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={!selectedFile}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}


            <Modal show={showPhotoModal} onHide={handleClosePhotoModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">Add Activity Photo/Video</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>Ratio limit 4:3, e.g. 323(W) * 180(H). File size: within 500MB</p>
                        <Form.Group controlId="formFile">
                            <input
                                type="file"
                                id="file-input"
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                multiple // Allow multiple files
                            />
                            <Button
                                onClick={() => document.getElementById('file-input').click()}
                                disabled={selectedFiles.length >= 3} // Disable if 3 files are selected
                            >
                                Select
                            </Button>
                        </Form.Group>
                    </div>

                    {/* Preview selected images or videos */}
                    {previewUrls.length > 0 && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            {previewUrls.map((url, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    {selectedFiles[index].type.startsWith('image/') ? (
                                        <img src={url} alt={`Preview ${index}`} style={{ width: '175px', height: '121px' }} />
                                    ) : (
                                        <video width="375" height="221" controls>
                                            <source src={url} />
                                        </video>
                                    )}
                                    <XCircle
                                        style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                                        size={24}
                                        onClick={() => handleRemoveFile(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePhotoModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={selectedFiles.length === 0}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Document Upload Modal */}
            <Modal show={showDocumentModal} onHide={handleCloseDocumentModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">Add Activity Icon</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>Ratio limit 4:3, e.g. 79(W) * 92(H). File size: within 10MB</p>
                        <Form.Group controlId="formFileDocument">
                            <input
                                type="file"
                                id="file-input-doc"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button onClick={() => document.getElementById('file-input-doc').click()}>Select</Button>
                        </Form.Group>
                    </div>

                    {/* Preview PDF or Document */}
                    {previewUrl && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            {selectedFile.type === 'application/pdf' ? (
                                <img src={pdfimg} alt="PDF Preview" style={{ width: '66px', height: '73px' }} />

                            ) : (
                                <img src="pdfimg.png" alt="PDF Preview" style={{ width: '66px', height: '73px' }} />
                            )}
                            <XCircle
                                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                                size={24}
                                onClick={handleRemoveFile}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDocumentModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={!selectedFile}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            Assign to
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default FileUpload;

