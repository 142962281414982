















// import React, { useState } from "react";
// import { Container, Row, Col, Form, Card, Button, CardBody, Modal } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Switch } from "@mui/material";
// import './campaigncss/Instructor.css'
// import { IoNotificationsOutline } from 'react-icons/io5';
// import { Add } from '@mui/icons-material';

// function Instructor() {
//     const [showBasicModal, setShowBasicModal] = useState(false);
//     const handleshowBasicModal = () => setShowBasicModal(true);
//     const handlecloseBasicModal = () => setShowBasicModal(false);

//     const [showInstructor, setShowInstructor] = useState(false)
//     const handleShowInstructor = () => setShowInstructor(true)
//     const handleCloseInstructor = () => setShowInstructor(false)

//     return (
//         <Container fluid>
//             <Row>

//                 <Card className="mt-0 border-0" style={{ position: "relative" }}>
//                     <Card.Body>
//                         <Form>

//                             <Row className="align-items-center mb-3">
//                                 <Col md={6} className="d-flex align-items-center">
//                                     <h5 className="basicheading">Instructor(s)</h5>
//                                 </Col>
//                                 <Col md={6} className="text-end">
//                                     <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
//                                     <Button variant="primary" className="basicsavebtn me-3">Save</Button>
//                                 </Col>
//                             </Row>

// <Row>
//     <Col md={12}>
//         <Card className="basicinfocard">
//             <CardBody className="d-flex justify-content-between align-items-center">
//                 <div className="d-flex align-items-center">
//                     <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
//                     <span className="basicstatus">Status: Draft</span>
//                 </div>
//                 <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
//             </CardBody>
//         </Card>
//     </Col>
// </Row>

//                             <Row className="mt-3">
//                                 <Col md={1} >
//                                     <Button
//                                         style={{
//                                             backgroundColor: '#1976D2',
//                                             border: 'none',
//                                             height: '24px',
//                                             width: '24px',
//                                             padding: '0'
//                                         }}
//                                         onClick={handleShowInstructor}
//                                     >
//                                         <Add style={{ color: '#fff', fontSize: '16px' }} />
//                                     </Button>
//                                 </Col>
//                             </Row>

//                             <Row className="mt-3">

//                                 <Col md={4}>
//                                     <Form.Group controlId="inquiry" className="floating-label-group">
//                                         <Form.Select name="inquiry" className="floating-input">
//                                             <option value="Ling"> Lily Leung</option>
//                                         </Form.Select>
//                                         <Form.Label className="floating-label">Name</Form.Label>
//                                     </Form.Group>
//                                 </Col>

//                                 <Col md={1} className="d-flex justify-content-center align-items-center">
//                                     <Button
//                                         style={{
//                                             backgroundColor: '#FF4694',
//                                             border: 'none',
//                                             height: '24px',
//                                             width: '24px',
//                                             display: 'flex',
//                                             justifyContent: 'center',
//                                             alignItems: 'center',
//                                             padding: '0', // Ensures no extra space around the text
//                                         }}
//                                     >
//                                         --
//                                     </Button>
//                                 </Col>

//                             </Row>









//                         </Form>
//                     </Card.Body>
//                 </Card>
//             </Row>

// <Modal
//     show={showBasicModal}
//     onHide={handlecloseBasicModal}
//     centered
//     className="custom-modal-width"
// >
//     <Modal.Body>
//         <Form>
//             <h4 className="assigntohead ms-2">
//                 Assign to
//             </h4>

//             <Form.Group controlId="formStatus" className="mt-3 ms-3">

//                 <Form.Check
//                     type="checkbox"
//                     label="Ling (Manager)"
//                     name="status"
//                     value="pre-approve"
//                     className="assigntocheck mb-3"
//                 />
//                 <Form.Check
//                     type="checkbox"
//                     label="Rainbow Lee (Campaign Organizer)"
//                     name="status"
//                     value="approve"
//                     className="assigntocheck mb-3"
//                 />
//                 <Form.Check
//                     type="checkbox"
//                     label="Alex Law (Manager)"
//                     name="status"
//                     value="waitlisted"
//                     className="assigntocheck mb-3"
//                 />
//                 <Form.Check
//                     type="checkbox"
//                     label="Chan Siu Ming (Manager)"
//                     name="status"
//                     value="waitlisted"
//                     className="assigntocheck"
//                 />
//             </Form.Group>
//         </Form>
//     </Modal.Body>

//     <Modal.Footer>
//         <Button
//             variant=""
//             onClick={handlecloseBasicModal}
//             className="me-2 groupclear"
//         >
//             Cancel
//         </Button>
//         <Button
//             variant=""
//             className="groupsearch"
//             onClick={handlecloseBasicModal}
//         >
//             Save
//         </Button>
//     </Modal.Footer>
// </Modal>


//             <Modal
//                 show={showInstructor}
//                 onHide={handleCloseInstructor}
//                 centered
//                 className="custom-modal-width"
//             >
//                 <Modal.Body>
//                     <Form>
//                         <h4 className="assigntohead ms-2">
//                             Add Instructor(s)
//                         </h4>

//                         <Form.Group controlId="formStatus" className="mt-3 ms-3">

//                             <Form.Check
//                                 type="checkbox"
//                                 label="Mei Lee"
//                                 name="status"
//                                 value="Mei Lee"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Ken Chan"
//                                 name="status"
//                                 value="Ken Chan"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Chau Siu Ming"
//                                 name="status"
//                                 value="Chau Siu Ming"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Lily Leung"
//                                 name="status"
//                                 value="Lily Leung"
//                                 className="assigntocheck"
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     <Button
//                         variant=""
//                         onClick={handleCloseInstructor}
//                         className="me-2 groupclear"
//                     >
//                         Cancel
//                     </Button>
//                     <Button
//                         variant=""
//                         className="groupsearch"
//                         onClick={handleCloseInstructor}
//                     >
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>

//         </Container>
//     )
// }

// export default Instructor



// import React, { useState } from "react";
// import { Container, Row, Col, Form, Card, Button, CardBody, Modal } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Add } from '@mui/icons-material';
// import { IoNotificationsOutline } from 'react-icons/io5';
// import './campaigncss/Instructor.css';

// function Instructor() {
//     const [showInstructor, setShowInstructor] = useState(false);
//     const [instructors, setInstructors] = useState([]); // Start with an empty list
//     const [newInstructor, setNewInstructor] = useState('');

//     // Open modal
//     const handleShowInstructor = () => setShowInstructor(true);

//     // Close modal
//     const handleCloseInstructor = () => {
//         setShowInstructor(false);
//         setNewInstructor(''); // Clear input after closing the modal
//     };

//     // Handle Save Instructor (to add the instructor to the list)
//     const handleSaveInstructor = () => {
//         if (newInstructor) {
//             setInstructors((prevInstructors) => [...prevInstructors, { name: newInstructor }]);
//             setNewInstructor('');
//             handleCloseInstructor();
//         }
//     };

//     const [showBasicModal, setShowBasicModal] = useState(false);
//     const handleshowBasicModal = () => setShowBasicModal(true);
//     const handlecloseBasicModal = () => setShowBasicModal(false);


//     return (
//         <Container fluid>
//             <Row>
//                 <Card className="mt-0 border-0" style={{ position: "relative" }}>
//                     <Card.Body>
//                         <Form>
//                             <Row className="align-items-center mb-3">
//                                 <Col md={6} className="d-flex align-items-center">
//                                     <h5 className="basicheading">Instructor(s)</h5>
//                                 </Col>
//                                 <Col md={6} className="text-end">
//                                     <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
//                                     <Button variant="primary" className="basicsavebtn me-3">Save</Button>
//                                 </Col>
//                             </Row>

//                             <Row>
//                                 <Col md={12}>
//                                     <Card className="basicinfocard">
//                                         <CardBody className="d-flex justify-content-between align-items-center">
//                                             <div className="d-flex align-items-center">
//                                                 <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
//                                                 <span className="basicstatus">Status: Draft</span>
//                                             </div>
//                                             <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
//                                         </CardBody>
//                                     </Card>
//                                 </Col>
//                             </Row>


//                             {/* Render the instructors dynamically only when added */}
//                             {instructors.map((instructor, index) => (
//                                 <Row key={index} className="mt-3">
//                                     <Col md={4}>
//                                         <Form.Group controlId="inquiry" className="floating-label-group">
//                                             <Form.Select name="inquiry" className="floating-input">
//                                                 <option value={instructor.name}>{instructor.name}</option>
//                                             </Form.Select>
//                                             <Form.Label className="floating-label">Name</Form.Label>
//                                         </Form.Group>
//                                     </Col>

//                                     <Col md={1} className="d-flex justify-content-center align-items-center">
//                                         <Button
//                                             style={{
//                                                 backgroundColor: '#FF4694',
//                                                 border: 'none',
//                                                 height: '24px',
//                                                 width: '24px',
//                                                 display: 'flex',
//                                                 justifyContent: 'center',
//                                                 alignItems: 'center',
//                                                 padding: '0', // Ensures no extra space around the text
//                                             }}
//                                         >
//                                             --
//                                         </Button>
//                                     </Col>
//                                 </Row>
//                             ))}

//                             {/* Add Button */}
//                             <Row className="mt-3">
//                                 <Col md={1}>
//                                     <Button
//                                         style={{
//                                             backgroundColor: '#1976D2',
//                                             border: 'none',
//                                             height: '24px',
//                                             width: '24px',
//                                             padding: '0'
//                                         }}
//                                         onClick={handleShowInstructor}
//                                     >
//                                         <Add style={{ color: '#fff', fontSize: '16px' }} />
//                                     </Button>
//                                 </Col>
//                             </Row>
//                         </Form>
//                     </Card.Body>
//                 </Card>
//             </Row>

//             <Modal
//                 show={showBasicModal}
//                 onHide={handlecloseBasicModal}
//                 centered
//                 className="custom-modal-width"
//             >
//                 <Modal.Body>
//                     <Form>
//                         <h4 className="assigntohead ms-2">
//                             Assign to
//                         </h4>

//                         <Form.Group controlId="formStatus" className="mt-3 ms-3">

//                             <Form.Check
//                                 type="checkbox"
//                                 label="Ling (Manager)"
//                                 name="status"
//                                 value="pre-approve"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Rainbow Lee (Campaign Organizer)"
//                                 name="status"
//                                 value="approve"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Alex Law (Manager)"
//                                 name="status"
//                                 value="waitlisted"
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Chan Siu Ming (Manager)"
//                                 name="status"
//                                 value="waitlisted"
//                                 className="assigntocheck"
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     <Button
//                         variant=""
//                         onClick={handlecloseBasicModal}
//                         className="me-2 groupclear"
//                     >
//                         Cancel
//                     </Button>
//                     <Button
//                         variant=""
//                         className="groupsearch"
//                         onClick={handlecloseBasicModal}
//                     >
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>


//             {/* Modal for Adding Instructor */}
//             <Modal show={showInstructor} onHide={handleCloseInstructor} centered className="custom-modal-width">
//                 <Modal.Body>
//                     <Form>
//                         <h4 className="assigntohead ms-2">Add Instructor(s)</h4>

//                         <Form.Group controlId="formStatus" className="mt-3 ms-3">
//                             {/* Checkbox options for available instructors */}
//                             <Form.Check
//                                 type="radio"
//                                 label="Mei Lee"
//                                 name="status"
//                                 value="Mei Lee"
//                                 onChange={(e) => setNewInstructor(e.target.value)}
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="radio"
//                                 label="Ken Chan"
//                                 name="status"
//                                 value="Ken Chan"
//                                 onChange={(e) => setNewInstructor(e.target.value)}
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="radio"
//                                 label="Chau Siu Ming"
//                                 name="status"
//                                 value="Chau Siu Ming"
//                                 onChange={(e) => setNewInstructor(e.target.value)}
//                                 className="assigntocheck mb-3"
//                             />
//                             <Form.Check
//                                 type="radio"
//                                 label="Lily Leung"
//                                 name="status"
//                                 value="Lily Leung"
//                                 onChange={(e) => setNewInstructor(e.target.value)}
//                                 className="assigntocheck"
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     <Button variant="" onClick={handleCloseInstructor} className="me-2 groupclear">Cancel</Button>
//                     <Button variant="" className="groupsearch" onClick={handleSaveInstructor}>Save</Button>
//                 </Modal.Footer>
//             </Modal>

//         </Container>
//     );
// }

// export default Instructor;



import React, { useState } from "react";
import { Container, Row, Col, Form, Card, Button, CardBody, Modal, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Add } from '@mui/icons-material';
import { IoNotificationsOutline } from 'react-icons/io5';
import './campaigncss/Instructor.css';

function Instructor() {
    const [showInstructor, setShowInstructor] = useState(false);
    const [instructors, setInstructors] = useState([]); // Start with an empty list
    const [newInstructor, setNewInstructor] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Open modal
    const handleShowInstructor = () => setShowInstructor(true);

    // Close modal
    const handleCloseInstructor = () => {
        setShowInstructor(false);
        setNewInstructor(''); // Clear input after closing the modal
        setErrorMessage(''); // Clear any error message when closing the modal
    };

    // Handle Save Instructor (to add the instructor to the list)
    const handleSaveInstructor = () => {
        if (newInstructor) {
            // Check if the instructor is already added
            const existingInstructor = instructors.find(instructor => instructor.name === newInstructor);
            if (existingInstructor) {
                setErrorMessage('Instructor already present.');
            } else {
                setInstructors((prevInstructors) => [...prevInstructors, { name: newInstructor }]);
                setNewInstructor('');
                handleCloseInstructor();
            }
        }
    };

    const [showBasicModal, setShowBasicModal] = useState(false);
    const handleshowBasicModal = () => setShowBasicModal(true);
    const handlecloseBasicModal = () => setShowBasicModal(false);

    // Remove instructor from the list
    const handleRemoveInstructor = (instructorName) => {
        setInstructors(instructors.filter(instructor => instructor.name !== instructorName));
    };

    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>
                            <Row className="align-items-center mb-3">
                                <Col md={6} className="d-flex align-items-center">
                                    <h5 className="basicheading">Instructor(s)</h5>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button variant="secondary" className="basiccancelbtnn me-2">Cancel</Button>
                                    <Button variant="primary" className="basicsavebtn me-3">Save</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Card className="basicinfocard">
                                        <CardBody className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <IoNotificationsOutline className="me-2" size={25} color="#1A73E8" />
                                                <span className="basicstatus">Status: Draft</span>
                                            </div>
                                            <Button className="basicsavebtn " style={{ width: '90px' }} onClick={handleshowBasicModal}>Assign to</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Error message */}
                        

                            {/* Render the instructors dynamically only when added */}
                            {instructors.map((instructor, index) => (
                                <Row key={index} className="mt-3">
                                    <Col md={4}>
                                        <Form.Group controlId="inquiry" className="floating-label-group">
                                            <Form.Select name="inquiry" className="floating-input">
                                                <option value={instructor.name}>{instructor.name}</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label">Name</Form.Label>
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} className="d-flex justify-content-center align-items-center">
                                        <Button
                                            style={{
                                                backgroundColor: '#FF4694',
                                                border: 'none',
                                                height: '24px',
                                                width: '24px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0', // Ensures no extra space around the text
                                            }}
                                            onClick={() => handleRemoveInstructor(instructor.name)} // Handle removal
                                        >
                                            --
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                            {/* Add Button */}
                            <Row className="mt-3">
                                <Col md={1}>
                                    <Button
                                        style={{
                                            backgroundColor: '#1976D2',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            padding: '0'
                                        }}
                                        onClick={handleShowInstructor}
                                    >
                                        <Add style={{ color: '#fff', fontSize: '16px' }} />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>

            <Modal
                show={showBasicModal}
                onHide={handlecloseBasicModal}
                centered
                className="custom-modal-width"
            >
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">
                            Assign to
                        </h4>

                        <Form.Group controlId="formStatus" className="mt-3 ms-3">

                            <Form.Check
                                type="checkbox"
                                label="Ling (Manager)"
                                name="status"
                                value="pre-approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Rainbow Lee (Campaign Organizer)"
                                name="status"
                                value="approve"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Alex Law (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Chan Siu Ming (Manager)"
                                name="status"
                                value="waitlisted"
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant=""
                        onClick={handlecloseBasicModal}
                        className="me-2 groupclear"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="groupsearch"
                        onClick={handlecloseBasicModal}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Adding Instructor */}
            <Modal show={showInstructor} onHide={handleCloseInstructor} centered className="custom-modal-width">
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">Add Instructor(s)</h4>
                        {errorMessage && (
                                <Alert variant="danger">
                                    {errorMessage}
                                </Alert>
                            )}
                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            {/* Checkbox options for available instructors */}
                            <Form.Check
                                type="radio"
                                label="Mei Lee"
                                name="status"
                                value="Mei Lee"
                                onChange={(e) => setNewInstructor(e.target.value)}
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="radio"
                                label="Ken Chan"
                                name="status"
                                value="Ken Chan"
                                onChange={(e) => setNewInstructor(e.target.value)}
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="radio"
                                label="Chau Siu Ming"
                                name="status"
                                value="Chau Siu Ming"
                                onChange={(e) => setNewInstructor(e.target.value)}
                                className="assigntocheck mb-3"
                            />
                            <Form.Check
                                type="radio"
                                label="Lily Leung"
                                name="status"
                                value="Lily Leung"
                                onChange={(e) => setNewInstructor(e.target.value)}
                                className="assigntocheck"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="" onClick={handleCloseInstructor} className="me-2 groupclear">Cancel</Button>
                    <Button variant="" className="groupsearch" onClick={handleSaveInstructor}>Save</Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default Instructor;
